<div class="add-project">
  <div class="dialog-title">
    <div>
      <h1>Add Project</h1>
    </div>
    <div>
      <button class="lfx-button clear" [mat-dialog-close]><i class="fas fa-times"></i></button>
    </div>
  </div>
  <div mat-dialog-content>
    <app-loading *ngIf="addProjectLoading"></app-loading>
    <form [formGroup]="addProjectForm" *ngIf="!addProjectLoading">
      <div class="parent-readonly-section" fxLayout="row wrap" *ngIf="data && data.Name">
        <div>
          <p class="label">Parent Project</p>
          <p class="value">{{ data.Name }}</p>
        </div>
      </div>
      <div class="name-section" *ngIf="apFormControl.type.valid">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
          <div>
            <div *ngIf="apFormControl.type.value === 'project'">
              <app-input control="name" [parent]="addProjectForm" label="Common Name" [required]="true"
                [validMessage]="getValidMessage()" [errorMessage]="getErrorMessage()" [loading]="searchLoading"
                tooltip="This is the official name of the project entity (not its legal/formal name). If unsure, pick a close or descriptive name."
                data-cy="add-project-common-name-input">
              </app-input>
            </div>
          </div>
          <div class="search-progress" *ngIf="apFormControl.name.valid && apFormControl.type.value === 'project'">
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start center" class="slug-container">
          <div *ngIf="apFormControl.type.value === 'project'">
            <app-input control="slug" [parent]="addProjectForm" label="Slug" [required]="true"
              [errorMessage]="!addProjectProcessing && slugErrorMessage || invalidSlug && invalidSlug"
              (inputFocus)="handleSlugFocus($event)"
              tooltip='The "slug" (URL fragment) is a unique project identifier suitable for using in a URL, and is used to reference projects in a recognizable way by services within and outside the LFX platform. It is typically NOT changed once the project has been added'
              data-cy="add-project-slug-input">
            </app-input>
          </div>
          <div>
            <a class="slug-reset" [class.error]="invalidSlug" (click)="handleSlugReset()"
              *ngIf="!searchLoading && isSlugValueUpdating && isSlugFocused">Reset</a>
          </div>
        </div>
      </div>
      <div class="parent-section" *ngIf="!data || !data.ID">
        <h3>Parent Project <span class="error">*</span></h3>
        <div class="search-parent" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
          <div>
            <input type="text" placeholder="Search for a project" formControlName="parent" [matAutocomplete]="auto"
              (keyup)="onKeyUp($event)">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setParentName($event)">
              <mat-option [class]="'level-'+project.level?.toString()" *ngFor="let project of projects"
                [value]="project">
                {{project.Name}}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="spinner">
            <i class="fas fa-circle-notch fa-spin" *ngIf="searchParentLoading && !parentSet"></i>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" class="or-text">OR</div>
          <div>
            <mat-checkbox formControlName="noParent" color="primary">No Parent</mat-checkbox>
            <i class="fad fa-info-circle"
              ngxTippy='"No Parent" should only be used for incorporatd projects; otherwise select "The Linux Foundation" as the parent, if not part of an existing project group.'
              [tippyProps]="tippyProps" tippyClassName="lfx-tooltip"></i>
          </div>
        </div>
      </div>
      <div class="name-section" *ngIf="apFormControl.type.valid">
        <div class="auto-complete-input" fxFlex="0 1 33.3%" fxLayout="column wrap">
          <h3>Category <i class="fad fa-info-circle"
              ngxTippy="Optionally organize projects within a parent project into categories for grouping and navigation. This is distinct from setting a technology or industry sector."
              [tippyProps]="tippyProps" tippyClassName="lfx-tooltip"></i></h3>
          <input type="text" placeholder="Search existing, or enter a new category name" aria-label="Category" matInput
            formControlName="category" [matAutocomplete]="projectCategories"
            [attr.disabled]="disableCategory ? disableCategory : null" (keyup)="onCategoryKey($event)"
            data-cy="add-project-category-input" />
          <mat-autocomplete #projectCategories="matAutocomplete" (optionSelected)="setCategoryName($event)">
            <ng-container *ngFor="let name of filteredName | async">
              <mat-divider *ngIf="name.indexOf('+ Add:') >= 0"></mat-divider>
              <mat-option [value]="name">
                <span>{{ name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="add-project-actions" align="end">
    <button mat-button class="lfx-button grey" [mat-dialog-close]>Cancel</button>
    <button mat-button class="lfx-button blue" [disabled]="addProjectDisabled()" (click)="addProject()"
      data-cy="add-project-add-project-button">
      Add Project <i class="fas fa-circle-notch fa-spin" *ngIf="addProjectProcessing"></i>
    </button>
  </div>
</div>
