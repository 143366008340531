import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { tippyProperties } from '@app/shared/utils/constants';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss']
})
export class OrganizationsListComponent {
  @ViewChild('organizationTable', { static: true }) public organizationTable: DatatableComponent;

  @Input() public rows: any[] = [];
  @Input() public prefix: string = 'github.com/';
  @Input() public prop: string = 'gitlab_organization_name';
  @Input() public emptyMessage: string = 'No organizations have been added';
  @Input() public showPrefix: boolean = true;
  @Input() public currentRow: any;

  @Output() public readonly activate: EventEmitter<{ type: string; row: any }> = new EventEmitter<{ type: string; row: any }>();

  public SelectionType = SelectionType;
  public tippyProps: NgxTippyProps = {
    ...tippyProperties,
    placement: 'top',
    interactive: false,
    onTrigger: (instance) => {
      const val = instance.reference.attributes.getNamedItem('data-tooltip')?.value;
      return val ? instance.setProps({ content: val }) : instance.disable();
    }
  };

  public constructor() {}

  public getCurrentSelected(): any {
    if (this.organizationTable.rows && this.organizationTable.rows.length > 0) {
      if (!this.currentRow) {
        return [this.organizationTable.rows[0]];
      }

      return [this.organizationTable.rows.find((row: any) => row[this.prop] === this.currentRow[this.prop])];
    }

    return undefined;
  }

  public onActivate(event: { type: string; row: any }): void {
    this.activate.emit(event);
  }

  public getOrgStatusTooltip(status?: string): string | undefined {
    switch (status) {
      case 'connected':
        return 'Connected';
      case 'partial_connection':
        return 'Partial Connection';
      case 'no_connection':
        return 'No Connection';
      case 'connection_failure':
        return 'Connection Failure';
      default:
        return undefined;
    }
  }

  public getName(value: string): string {
    return this.showPrefix ? `${this.prefix}${value}` : value.replace('https://', '') && value.replace('/groups', '');
  }
}
