<ngx-datatable #dataTable [reorderable]="reorderable" [loadingIndicator]="loading" [rowHeight]="rowHeight"
  [columnMode]="columnMode" [rows]="dataRows" [messages]="{ emptyMessage: emptyMessage }" [groupRowsBy]="groupRowsBy"
  [groupExpansionDefault]="groupExpansionDefault" [externalSorting]="externalSorting"
  [headerHeight]="headerHeight ? headerHeight : (!dataRows || dataRows.length === 0 && hideHeaderOnEmpty ? 0 : 1)"
  [footerHeight]="footerHeight ? footerHeight : (!dataRows || dataRows.length === 0 || !showFooter ? 0 : 50)"
  [scrollbarH]="scrollbarH" [externalPaging]="externalPaging" [rowClass]="rowClass" (activate)="onActivate($event)"
  (sort)="onSort($event)">
  <ng-content></ng-content>
  <ngx-datatable-footer *ngIf="showFooter">
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-selectedCount="selectedCount"
      let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count" *ngIf="rowCount > 0" fxFlex="30" fxLayoutAlign="start center">
        Showing Results
        {{ (curPage - 1) * pageSize + 1 }}
        &ndash;
        {{(pageSize * curPage) > rowCount ? rowCount : (pageSize * curPage) }} of {{ rowCount.toLocaleString() }}
      </div>
      <div class="page-selector" *ngIf="rowCount > 0" fxFlex="40" fxLayoutAlign="center center">
        <label class="page-selector__label">Page</label>
        <input type="number" (keyup.enter)="goToPage(input.value)" #input [value]="ngxDatatablePager.page"
          [disabled]="(ngxDatatablePager.page <= 1 && ngxDatatablePager.totalPages === 1)" [min]="1"
          [max]="ngxDatatablePager.totalPages" oninput="validity.valid||(value='');">
        <span>of {{ngxDatatablePager.totalPages}}</span>
        <button [disabled]="loading || ngxDatatablePager.page <= 1" (click)="onPreviousHandle()">
          <i class="fas fa-caret-left"></i>
        </button>
        <button
          [disabled]="loading || ngxDatatablePager.page === ngxDatatablePager.totalPages || dataTable.rows.length < pageSize"
          (click)="onNextHandle()">
          <i class="fas fa-caret-right"></i>
        </button>
      </div>
      <div class="blank" fxFlex="30" fxLayoutAlign="end center">
        <div class="page-size" *ngIf="showPageSize && rowCount > 0">
          <label class="page-size__label">Rows per page</label>
          <select class="page-size__select" (change)="onPageSizeChange($event)" [value]="pageSize">
            <option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</option>
          </select>
        </div>
        <datatable-pager #ngxDatatablePager [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'" [page]="currentPage !== 0 ? currentPage : curPage" [size]="pageSize"
          [count]="rowCount" [hidden]="true" (change)="dataTable.onFooterPage($event)">
        </datatable-pager>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
