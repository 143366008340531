import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public constructor(private spinner: NgxSpinnerService) {}

  public ngOnInit(): void {
    this.spinner.show('loading', {
      fullScreen: false,
      type: 'ball-clip-rotate',
      size: 'medium',
      color: '#d9e0e7',
      bdColor: 'transparent'
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }
}
