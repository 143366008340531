import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProjectService } from '@services/project.service';
import { flattenSummaryData, moveCategoriesToProjects } from '@utils/helper';
import { Navigation, Project, ProjectPages, ProjectSummary } from 'lfx-pcc';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() public projectID: string;
  @Input() public project: Project;

  public navItems: Navigation[] = [];
  public projectsRaw: ProjectSummary[] = [];
  public projects: ProjectSummary[] = [];
  public form: FormGroup;
  public sidebarSearch: FormControl = new FormControl();
  public sidebarSearching: boolean = false;
  public showLinks: boolean = false;
  public currentPath: string = '';
  public currentProjToggle: boolean = true;
  public parentProject: Project | undefined = undefined;
  public currentDiv: any;
  public newSiteURL: string = environment.siteURL;

  public constructor(
    private router: Router,
    public authService: AuthService,
    private projectService: ProjectService,
    private location: Location,
    private fb: FormBuilder,
    private elem: ElementRef
  ) {
    this.form = this.fb.group({
      sidebarSearch: this.sidebarSearch
    });

    this.currentPath = this.location.path();
  }

  public ngOnInit(): void {
    this.authService.user$.pipe(untilDestroyed(this)).subscribe((user: User | null | undefined) => {
      const lfxHeader = document.getElementById('lfx-header') as any;
      lfxHeader.authuser = user;
    });

    this.sidebarSearch.valueChanges
      .pipe(
        debounceTime(500),
        map((searchTextValue) => searchTextValue.trim())
      )
      .subscribe((searchTextValue) => {
        const regex = /^.{3,}$/;
        if (regex.test(searchTextValue)) {
          this.sidebarSearching = true;
          if (this.projectsRaw.length === 0) {
            this.fetchProjectsFromSummary().subscribe((projects: ProjectSummary[]) => {
              projects.forEach((p: ProjectSummary) => {
                if (p.Categories && p.Categories.length > 0) {
                  p.Projects = moveCategoriesToProjects(p);
                }
              });
              this.projectsRaw = projects;
              this.handleSearch(searchTextValue);
              this.sidebarSearching = false;
            });
          } else {
            this.handleSearch(searchTextValue);
            this.sidebarSearching = false;
          }
        } else {
          this.projects = [];
        }
      });
    this.setNavItemsActiveState();
  }

  public ngOnChanges(): void {
    this.setNavItemsActiveState();
  }

  public toggleDropdown(nav: Navigation): void {
    this.navItems.forEach((item: Navigation) => {
      item.active = item.name === nav.name ? !item.active : false;
    });
  }

  public toggleProjectTab(): void {
    this.currentProjToggle = !this.currentProjToggle;
  }

  public goToProject(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      this.sidebarSearch.setValue(event.option.value.Name);
      this.projects = [];
      this.router.navigate([`/project/${event.option.value.ID}`]);
    }
  }

  public goToSubLink(item: ProjectPages): void {
    if (!item.disabled) {
      this.router.navigate([item.slug]);
    }
  }

  public setNavItems(): void {
    this.projectService
      .getProjectPages(this.projectID, this.project, false)
      .then((navItems: Navigation[]) => {
        this.navItems = navItems.map((item: Navigation) => {
          if (item.name === 'Operations') {
            const operationsOrder = ['Project Definition', 'Membership', 'Legal', 'Domains', 'Cloud Providers'];
            item.items = _.orderBy(item.items, (i) => operationsOrder.indexOf(i.name));
          }

          if (item.name === 'Collaboration') {
            const collaborationsOrder = ['Committees', 'Meetings', 'Mailing Lists', 'Wiki', 'Issue Tracker'];
            item.items = _.orderBy(item.items, (i) => collaborationsOrder.indexOf(i.name));
          }

          if (item.name === 'Development') {
            const developmentOrder = ['Source Control', 'Continuous Integration', 'Distribution'];
            item.items = _.orderBy(item.items, (i) => developmentOrder.indexOf(i.name));
          }

          if (item.name === 'LFX Tools') {
            item.hide = this.project?.Status.toLowerCase() !== 'active' && this.project?.Status.toLowerCase() !== 'formation - engaged';
            const toolsOrder = ['Security', 'EasyCLA', 'Mentorship', 'Crowdfunding'];
            item.items = _.orderBy(item.items, (i) => toolsOrder.indexOf(i.name));
          }

          if (item.name === 'Manage Teams') {
            const toolsOrder = ['Teams', 'Users'];
            item.items = _.orderBy(item.items, (i) => toolsOrder.indexOf(i.name));
          }

          return item;
        });
        this.navItems = navItems.filter((item: Navigation) => !item.hide);
        this.toggleCurrentService();
      })
      .catch(() => {});
  }

  public get myProfileURL(): string {
    return environment.myProfileURL;
  }

  public get showNoData(): boolean {
    return this.projects.length === 0 && this.sidebarSearch.value && this.sidebarSearch.value.trim().length >= 3 && !this.sidebarSearching;
  }

  public get showParent(): boolean {
    return this.project && this.project.ParentHierarchy && this.project.ParentHierarchy.Name !== 'The Linux Foundation';
  }

  public get orderedProjects(): Project[] {
    return _.orderBy(this.project && this.project.Projects, (p: Project) => p.Name);
  }

  public getGroupMaxHeight(groupLength: ProjectPages[], groupCollapsed: boolean | undefined): string {
    if (groupCollapsed) {
      return '0';
    }

    return (groupLength.length + 1) * 32 + 45 + 'px';
  }

  public setNavItemsActiveState(): void {
    if (this.projectID) {
      this.newSiteURL = `${environment.siteURL}/project/${this.projectID}`;
      this.showLinks = true;
      this.currentProjToggle = true;
      this.setNavItems();
      this.getParentProject();
    } else {
      this.navItems = [{ name: '', active: false, items: [] }];
      this.projectID = '';
      this.sidebarSearch.setValue('');
    }
  }

  public onNavigateNewSite(): void {
    localStorage.removeItem('pcc-v1-only');
  }

  private getParentProject(): void {
    if (this.project && this.project.Foundation) {
      this.projectService
        .getProject(this.project.Foundation.ID)
        .pipe(untilDestroyed(this))
        .subscribe((p: Project) => {
          const projects = p.Projects ? p.Projects : [];
          const currentProjects = { ...p, Projects: _.uniqBy([...projects, this.project], 'ID') };
          this.parentProject = currentProjects;
        });
    }
  }

  private handleSearch(searchValue: string) {
    this.projects = flattenSummaryData(this.filterProjects(_.cloneDeep(this.projectsRaw), searchValue));
  }

  private toggleCurrentService(): void {
    this.deactivateLinks();
    // regex to get all url path parameters
    const currentService = this.currentPath.split('/').splice(2);
    if (currentService && currentService[1]) {
      switch (currentService[1]) {
        case 'operations':
          this.activateCurrentPage('Operations', currentService);
          break;
        case 'collaboration':
          this.activateCurrentPage('Collaboration', currentService);
          break;
        case 'development':
          this.activateCurrentPage('Development', currentService);
          break;
        case 'tools':
          this.activateCurrentPage('LFX Tools', currentService);
          break;
        case 'manage-teams':
          this.activateCurrentPage('Manage Teams', currentService);
          break;
      }
    }
  }

  private activateCurrentPage(indexName: string, currentService: string[]) {
    const index = this.navItems.findIndex((navItem: Navigation) => navItem.name === indexName);
    this.navItems[index].active = true;
    this.navItems.forEach((navItem: Navigation) => {
      navItem.items = navItem.items.map((n: ProjectPages) => {
        const item = n;
        item.active = currentService[2].includes(item.slug.split('/')[4]);

        return item;
      });
    });
  }

  private deactivateLinks(): void {
    this.navItems.forEach((nav: Navigation) => {
      nav.active = false;
      nav.items.forEach((page: ProjectPages) => {
        page.active = false;
      });
    });
  }

  private fetchProjectsFromSummary(): Observable<ProjectSummary[]> {
    const params = new HttpParams({ fromObject: { pageSize: '9999' } });
    this.sidebarSearching = true;
    return this.projectService.getProjectSummary(params).pipe(untilDestroyed(this));
  }

  /**
   * filterProjects - filter projects and it's sub projects based on the search string
   * This is different from the filtering on the admin dashboard as this should not include the child projects
   * of parents that matches the search string
   *
   * @param projects
   * @param regex
   */
  private filterProjects = (projects: ProjectSummary[], search: string): ProjectSummary[] =>
    _.orderBy(
      _.compact(
        _.map(projects, (p) => {
          if (p.Name.toLocaleLowerCase().includes(search.toLowerCase()) || (p.Slug && p.Slug.toLocaleLowerCase().includes(search.toLowerCase()))) {
            // loop through this parent's projects and filter that as well
            if (p.Projects) {
              p.Projects = this.filterProjects(p.Projects, search);
            }
            return p;
          } else if (p.Projects) {
            const filteredList: ProjectSummary[] = this.filterProjects(p.Projects, search);
            if (_.isEmpty(filteredList)) {
              return null;
            }
            p.Projects = filteredList;
            return p;
          }
          return null;
        })
      ),
      'Name'
    );
}
