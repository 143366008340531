<div class="select-input-container" [class.full-width]="fullWidth" [formGroup]="parent">
  <mat-label *ngIf="showLabel" for="{{control}}" [class.error]="inputHasErrors() || errorMessage">{{ label }} <span
      *ngIf="required" class="required">*</span><span class="optional">{{ labelSubtext }}</span><i
      *ngIf="infoIconText !== ''" [ngClass]="infoIconText !== '' ? tooltipIcon : ''" [ngxTippy]="infoIconText"
      [tippyProps]="tippyProps" tippyClassName="lfx-tooltip"></i></mat-label>
  <div class="multi-select-input-container" [class.full-width]="fullWidth" [formGroup]="parent">
    <mat-select [class.no-label]="!showLabel" id="{{control}}" [formControlName]="control" disableOptionCentering
      [attr.disabled]="disabled ? '' : null" multiple (selectionChange)="setSelectionValue($event)"
      panelClass="multi-panel" [placeholder]="placeholder" [ngClass]="{'error': inputHasErrors() || errorMessage}"
      [disabled]="disabled">
      <mat-select-trigger *ngIf="useChips">
        <span class="custom-placeholder">{{placeholder}}</span>
      </mat-select-trigger>
      <mat-option *ngIf="!hideDefaultOpt" value="" disabled [selected]="!hideDefaultOpt" [hidden]="hideDefaultOpt">
        {{ placeholder }}
      </mat-option>
      <mat-option *ngFor="let option of options" [value]="option.value">
        <i [class]="option.icon" *ngIf="option.icon"></i>{{ option.label }}
      </mat-option>
    </mat-select>
    <i class="fa fa-exclamation-circle" aria-hidden="true" *ngIf="errorMessage || inputHasErrors()"></i>
  </div>
  <mat-chip-list *ngIf="multipleDefaultSelection && multipleDefaultSelection.length > 0 && useChips">
    <mat-chip *ngFor="let selectedOption of multipleDefaultSelection" (removed)="removeChip(selectedOption)">
      {{selectedOption}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <div class="select-input-container" [class.full-width]="fullWidth" [formGroup]="parent">
    <p class="chips-nothing-selected"
      *ngIf="!multipleDefaultSelection || multipleDefaultSelection.length === 0 && useChips">Nothing selected</p>
    <div [class.nothing-selected]="!multipleDefaultSelection">
      <mat-error *ngIf="inputHasErrors() || errorMessage">
        {{errorMessage || getErrorMessage(inputControl)}}
      </mat-error>
    </div>

  </div>
</div>
