import { environment } from '@environments/environment';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

export const DEFAULT_ERROR_MSG = 'It looks like something went wrong. Please contact IT Support for further assistance.';
export const DELETE_CONFIRMATION_MSG = {
  DEFAULT: 'Are you sure you would like to delete this? This action is not reversible.',
  ZOOM: 'Deleting this account will remove all scheduled meetings and cloud recordings for this account.',
  ZOOM_MEETING: 'Are you sure you would like to delete this meeting? This action is not reversible',
  FORWARD: 'Are you sure you would like to delete this? This action is not reversible.',
  DOMAIN_RECORD: 'Are you sure you would like to delete this record?',
  DOMAIN_SERVICE: 'Are you sure you would like to delete this service?',
  COMMITTEE_MEMBER: 'Are you sure you would like to delete this member?',
  MEMBERSHIP_TIER: 'Are you sure you would like to delete this tier?'
};
export const EASYCLA_MSGS = {
  FOUNDATION_LEVEL_PROJECT: 'Please select this box if you want to create a CLA Group at Project Group Level',
  CLA_ALREADY_PRESENT: 'CLA group already created for this project.',
  ERROR_FOUNDATION_TO_GRAND_CHILD: 'CLA group can not be create at grand child level when foundation is selected.',
  ERROR_ON_SIBLING: 'Cla group can not be created at sibling level.',
  ERROR_ON_OTHER_GRAND_CHILD_SELECTION: 'Cla group can not be created to the different grand child.',
  ERROR_ONLY_GRAND_CHILD_LEVEL_SUPPORT: 'Currently we are supporting only three level hierarchy.',
  ERROR_GENERIC: 'Due to CLA restriction you can not enroll/unenroll to this project.'
};
export const ENABLE_EMAIL_FORWARDING = `Are you sure you want to enable email forwarding? This might have a financial impact on the project's budget.`;
export const DELETE_GROUP_JIRA_LINK = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/143';
export const DEFAULT_PROJECT_NOT_FOUND = 'Project does not exist or you do not have sufficient permissions to view this project';
export const SECURITY_BOT_INSTALLATION_LINK = 'https://github.com/apps/lfx-security-github-app/installations/new';
export const SLACK_BOT_INSTALLATION_LINK =
  'WORKSPACE/oauth?client_id=2499876134129.2522674167347&scope=channels%3Ahistory%2Cchannels%3Ajoin%2Cchannels%3Aread%2Cgroups%3Ahistory%2Cgroups%3Aread%2Cgroups%3Awrite%2Cim%3Ahistory%2Cmpim%3Ahistory%2Creactions%3Aread%2Cteam%3Aread%2Cusers%3Aread%2Cusers%3Aread.email%2Cchannels%3Amanage&user_scope=&redirect_uri=https://api-gw.dev.platform.linuxfoundation.org/insights-service/v1/project/SFDCID/authorize/slack&state=&granular_bot_scope=1&single_channel=0&install_redirect=&tracked=1&team=';
export const SLACK_WORKSPACE_DIRECT_LINK = 'https://workspace/archives/';
export type FUNDING_TYPES = ['Funded', 'Unfunded', 'Supported by Parent Project'];
export type INDUSTRY_TYPES = [
  'Automotive',
  'Consumer Electronics',
  'Cross-Industry',
  'Energy',
  'Financials',
  'Health Care',
  'Information Technology',
  'Manufacturing',
  'Materials',
  'Motion Pictures',
  'Non-profit Organizations',
  'Pharmaceuticals',
  'Professional Services',
  'Public Health & Disaster Response',
  'Telecommunciations'
];
export type ENTITY_TYPES = ['Subproject', 'Incorporated Entity', 'Series LLC', 'Unofficial Subproject', 'Internal Allocation'];
export type TECHNICAL_ACTIVITY_TYPES = ['Open Source Software', 'Open Standard / Specification', 'Open Hardware', 'Open Data', 'Open Governance Network'];
export type TECHNOLOGY_SECTOR_TYPES = [
  'AI, ML, Data & Analytics',
  'Blockchain',
  'Cloud, Containers & Virtualization',
  'Cross-Technology',
  'DevOps, CI/CD & Site Reliability',
  'IoT & Embedded',
  'Linux Kernel',
  'Networking & Edge',
  'Open Hardware',
  'Open Source Best Practices',
  'Safety-Critical Systems',
  'Security',
  'System Administration',
  'System Engineering',
  'Web & Application Development'
];
export const APPOINTEDBY = [
  'Community',
  'Membership Entitlement',
  'Vote of End User Member Class',
  'Vote of TSC Committee',
  'Vote of TAC Committee',
  'Vote of Academic Member Class',
  'Vote of Lab Member Class',
  'Vote of Marketing Committee',
  'Vote of Governing Board',
  'Vote of General Member Class',
  'Vote of End User Committee',
  'Vote of TOC Committee',
  'Vote of Gold Member Class',
  'Vote of Silver Member Class'
];
export const ROLE = ['None', 'Chair', 'Counsel', 'Developer Seat', 'Lead', 'Technical Lead', 'LF Staff', 'Secretary', 'Treasurer', 'Vice Chair'];
export const VOTING_STATUS = ['Alternate Voting Rep', 'Observer', 'Voting Rep'];
export const VOTING_STATUS_MAILING = [
  { label: 'Alternate Voting Rep', value: 'alt_voting_rep' },
  { label: 'Observer', value: 'observer' },
  { label: 'Voting Rep', value: 'voting_rep' }
];

export type STATUS = ['Formation - Exploratory', 'Formation - Engaged', 'Active', 'Archived', 'Formation - On Hold', 'Formation - Disengaged', 'Prospect'];
export const COMMITTEE_TEMPLATES = [
  {
    Type: 'Governing Board',
    MailingList: '[Project_name]-gb',
    Visibility: 'Private',
    Category: 'Board',
    PostingPermission: 'announcement'
  },
  {
    Type: 'Technical',
    MailingList: '[Project_name]-toc',
    Visibility: 'Public',
    Category: 'Technical Oversight Committee/Technical Advisory Committee',
    PostingPermission: 'announcement'
  },
  {
    Type: 'Marketing',
    MailingList: '[Project_name]-moc',
    Visibility: 'Private',
    Category: 'Marketing Oversight Committee/Marketing Advisory Committee',
    PostingPermission: 'discussion_moderated'
  },
  {
    Type: 'Working Group',
    MailingList: '[Project_name]-wg',
    Visibility: 'Public',
    Category: 'Working Group',
    PostingPermission: 'announcement'
  },
  {
    Type: 'Legal',
    MailingList: '[Project_name]-legal',
    Visibility: 'Private',
    Category: 'Legal Committee',
    PostingPermission: 'discussion_moderated'
  },
  {
    Type: 'Finance',
    MailingList: '[Project_name]-finance',
    Visibility: 'Private',
    Category: 'Finance Committee',
    PostingPermission: 'announcement'
  },
  {
    Type: 'SIG',
    MailingList: '[Project_name]-sig',
    Visibility: 'Public',
    Category: 'Special Interest Group',
    PostingPermission: 'discussion_moderated'
  }
];

export const COMMITTEE_TYPES = [
  'Legal Committee',
  'Finance Committee',
  'Special Interest Group',
  'Board',
  'Technical Oversight Committee/Technical Advisory Committee',
  'Technical Steering Committee',
  'Marketing Oversight Committee/Marketing Advisory Committee',
  'Marketing Committee/Sub Committee',
  'Code of Conduct',
  'Product Security',
  'Committers/Maintainers',
  'Technical Mailing List',
  'Marketing Mailing List',
  'Working Group',
  'Other'
];

export const COMMITTEE_TYPE = [
  {
    label: 'Announcement',
    value: 'announcement'
  },
  {
    label: 'Custom',
    value: 'custom'
  },
  {
    label: 'Moderated Discussion',
    value: 'discussion_moderated'
  },
  {
    label: 'Open Discussion',
    value: 'discussion_open'
  }
];
export const COMMITTEE_CATEGORY = [
  {
    label: 'Legal Committee',
    value: 'Legal Committee'
  },
  {
    label: 'Finance Committee',
    value: 'Finance Committee'
  },
  {
    label: 'Special Interest Group',
    value: 'Special Interest Group'
  },
  {
    label: 'Board',
    value: 'Board'
  },
  {
    label: 'Technical Oversight Committee/Technical Advisory Committee',
    value: 'Technical Oversight Committee/Technical Advisory Committee'
  },
  {
    label: 'Technical Steering Committee',
    value: 'Technical Steering Committee'
  },
  {
    label: 'Marketing Oversight Committee/Marketing Advisory Committee',
    value: 'Marketing Oversight Committee/Marketing Advisory Committee'
  },
  {
    label: 'Marketing Committee/Sub Committee',
    value: 'Marketing Committee/Sub Committee'
  },
  {
    label: 'Code of Conduct',
    value: 'Code of Conduct'
  },
  {
    label: 'Product Security',
    value: 'Product Security'
  },
  {
    label: 'Committers/Maintainers',
    value: 'Committers/Maintainers'
  },
  {
    label: 'Technical Mailing List',
    value: 'Technical Mailing List'
  },
  {
    label: 'Marketing Mailing List',
    value: 'Marketing Mailing List'
  },
  {
    label: 'Working Group',
    value: 'Working Group'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];
export const YES_NO = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
];
export const VISIBILITY = [
  {
    value: 'private',
    label: 'Private'
  },
  {
    value: 'public',
    label: 'Public'
  }
];
export const COMMITTEE_TEMPLATE = [
  {
    label: 'Governing Board',
    value: 'Governing Board'
  },
  {
    label: 'Technical',
    value: 'Technical'
  },
  {
    label: 'Marketing',
    value: 'Marketing'
  },
  {
    label: 'Working Group',
    value: 'Working Group'
  },
  {
    label: 'Legal',
    value: 'Legal'
  },
  {
    label: 'Finance',
    value: 'Finance'
  },
  {
    label: 'SIG',
    value: 'SIG'
  }
];
export const ROLE_DROPDOWN_VALUES = [
  {
    label: 'Chair',
    value: 'Chair'
  },
  {
    label: 'Counsel',
    value: 'Counsel'
  },
  {
    label: 'Developer Seat',
    value: 'Developer Seat'
  },
  {
    label: 'Lead',
    value: 'Lead'
  },
  {
    label: 'Technical Lead',
    value: 'Technical Lead'
  },
  {
    label: 'Secretary',
    value: 'Secretary'
  },
  {
    label: 'Treasurer',
    value: 'Treasurer'
  },
  {
    label: 'Vice Chair',
    value: 'Vice Chair'
  }
];
export const VOTING_STATUS_DROPDOWN_VALUES = [
  {
    label: 'Alternate Voting Rep',
    value: 'alt_voting_rep'
  },
  {
    label: 'Observer',
    value: 'observer'
  },
  {
    label: 'Voting Rep',
    value: 'voting_rep'
  }
];
export const APPOINTED_BY_DROPDOWN_VALUES = [
  {
    label: 'None',
    value: 'None'
  },
  {
    label: 'Community',
    value: 'Community'
  },
  {
    label: 'Membership Entitlement',
    value: 'Membership Entitlement'
  },
  {
    label: 'Vote of End User Member Class',
    value: 'Vote of End User Member Class'
  },
  {
    label: 'Vote of TSC Committee',
    value: 'Vote of TSC Committee'
  },
  {
    label: 'Vote of TAC Committee',
    value: 'Vote of TAC Committee'
  },
  {
    label: 'Vote of Academic Member Class',
    value: 'Vote of Academic Member Class'
  },
  {
    label: 'Vote of Lab Member Class',
    value: 'Vote of Lab Member Class'
  },
  {
    label: 'Vote of Marketing Committee',
    value: 'Vote of Marketing Committee'
  },
  {
    label: 'Vote of Governing Board',
    value: 'Vote of Governing Board'
  },
  {
    label: 'Vote of General Member Class',
    value: 'Vote of General Member Class'
  },
  {
    label: 'Vote of End User Committee',
    value: 'Vote of End User Committee'
  },
  {
    label: 'Vote of TOC Committee',
    value: 'Vote of TOC Committee'
  },
  {
    label: 'Vote of Gold Member Class',
    value: 'Vote of Gold Member Class'
  },
  {
    label: 'Vote of Silver Member Class',
    value: 'Vote of Silver Member Class'
  },
  {
    label: 'Vote of Strategic Member Class',
    value: 'Vote of Strategic Member Class'
  }
];

export const EMAIL_DELIVERY_MODES = [
  {
    label: 'Single',
    value: 'email_delivery_single'
  },
  {
    label: 'Digest',
    value: 'email_delivery_digest'
  },
  {
    label: 'Special',
    value: 'email_delivery_special'
  },
  {
    label: 'None',
    value: 'email_delivery_none'
  },
  {
    label: 'HTML Digest',
    value: 'email_delivery_html_digest'
  },
  {
    label: 'Summary',
    value: 'email_delivery_summary'
  }
];

export const MODERATOR_STATUS = [
  {
    label: 'Moderator',
    value: 'moderator'
  },
  {
    label: 'None',
    value: 'none'
  },
  {
    label: 'Owner',
    value: 'owner'
  }
];

export const EXISTING_MAILING_LIST = [
  {
    title: 'Cloud Native Computing Foundation',
    list: [
      {
        label: 'Governing Board (Private)',
        value: 'Governing Board (Private)'
      },
      {
        label: 'Governing Board (Public)',
        value: 'Governing Board (Public)'
      }
    ]
  },
  {
    title: 'LF Networking',
    list: [
      {
        label: 'Governing Board (Private)',
        value: 'Governing Board (Private)'
      },
      {
        label: 'Marketing Committee (Public)',
        value: 'Marketing Committee (Public)'
      }
    ]
  }
];

export const SECURITY_SECRET_TYPES = [
  { value: 'password_assignment', label: 'Password Assignment' },
  { value: 'secret_assignment', label: 'Secret Assignment' },
  { value: 'aws_access_key_id', label: 'AWS Access Key ID' },
  { value: 'password_in_url', label: 'Password in URL' },
  { value: 'private_key', label: 'Private Key' },
  { value: 'aws_secret_key', label: 'AWS Secret Key' },
  { value: 'github_token|github_access_token|github_oauth_client_secret', label: 'GitHub Token/Secrets' },
  { value: 'gitlab_access_token', label: 'GitLab Access Token' },
  { value: 'credential_assignment', label: 'Credential Assignment' },
  { value: 'aws_account_id', label: 'AWS Account ID' },
  { value: 'terraform_state_file', label: 'Terraform State File' },
  { value: 'github_oauth_client_id', label: 'GitHub OAuth Client ID' },
  { value: 'jwt_token', label: 'JWT Token' }
];

export const RECURRING_TYPES = {
  DAILY: '1',
  WEEKLY: '2',
  MONTHLY: '3',
  CUSTOM: '5'
};

export const RECURRING_MONTHLY_TYPES = {
  dayOfMonth: '1',
  ordinalDayOfMonth: '2'
};

export const toastProperties = {
  closeButton: true,
  timeOut: 30000,
  enableHtml: true,
  progressBar: false,
  messageClass: 'toast-message'
};

export const tippyProperties: NgxTippyProps = {
  allowHTML: true,
  arrow: false,
  theme: 'lfx-tooltip',
  interactive: true
};

export const contactSupportEmail = 'formation@linuxfoundation.org';
export const contactSupportURL = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/358';
export const contactITSupportURL = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/2/create/37';
export const contactSupport = `Please <a href="https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/143" target="_blank">contact support </a> for additional help.`;

export const errorIcon = '<i class="fa fa-exclamation-circle"></i>';
export const SOURCE_CONTROL_ORGANIZATION_TOOLTIP =
  'https://docs.linuxfoundation.org/lfx/project-control-center/development/source-control#invite-users-to-organization';
export const GITLAB_SOURCE_CONTROL_DOCS_LINK =
  'https://docs.linuxfoundation.org/lfx/project-control-center/it-services-for-a-project/source-control#setting-up-gitlab';
export const HIDE_HOSTS_CONTROLS_SUBTITLE =
  'You can choose to record and transcribe your meeting using Zoom Cloud Recordings, protected with a simple access code. Select whether ' +
  'information to access the recording and transcription will be shared in Individual Dashboard with all meeting attendees. If the meeting is public, ' +
  'you can set the visibility to public to also share in Organization Dashboard and (in the future) your broader project community.';
export const SHOW_HOSTS_CONTROLS_SUBTITLE =
  'You can choose to record and transcribe your meeting using Zoom Cloud Recordings, protected with a simple access code. Select whether ' +
  'information to access the recording and transcription will be shared in Individual Dashboard with only meeting hosts, or with all meeting attendees. ' +
  'If the meeting is public, you can set the visibility to public to also share in Organization Dashboard and (in the future) your broader project community.';
export const INSIGHTS_CONNECTORS = [
  {
    key: 'bugzilla',
    title: 'Bugzilla',
    image: 'assets/connectors/bugzilla.jpeg',
    path: 'bugzilla',
    description: 'Monitor the bugs and request pipeline across all products and components.',
    connected: false,
    available: false
  },
  {
    key: 'circleci',
    title: 'CircleCI',
    image: 'assets/connectors/circleci.png',
    path: 'circleci',
    description: 'Monitor build duration, build statuses and mean time to recover across all your workflows.',
    connected: false,
    available: false
  },
  {
    key: 'discord',
    title: 'Discord',
    image: 'assets/connectors/discord-icon-svgrepo-com.svg',
    path: 'discord',
    description: 'See all your collaborations happening over messages and replies in public channels.',
    connected: false,
    available: false
  },
  {
    key: 'dockerhub',
    title: 'Docker Hub',
    image: 'assets/connectors/dockerhub.png',
    path: 'dockerhub',
    description: 'Monitor build duration, build statuses and mean time to recover across all your workflows.',
    connected: false,
    available: false
  },
  {
    key: 'earnedmedia',
    title: 'Earned Media',
    icon: 'fas fa-chart-pie',
    path: 'earned-media',
    description: 'See all your earned media mentions from union metrics matching a specific search criteria.',
    connected: false,
    available: false
  },
  {
    key: 'git',
    title: 'Git',
    image: 'assets/connectors/git.png',
    path: 'git',
    description: 'See top commit contributors, organizations and metrics like LOC across all repositories.',
    connected: false,
    available: false
  },
  {
    key: 'github',
    title: 'GitHub',
    image: 'assets/connectors/github.svg',
    path: 'github',
    description: 'Monitor all the pull requests and issue activities across all repositories and orgs.',
    connected: false,
    available: true
  },
  {
    key: 'gitlab',
    title: 'Gitlab',
    image: 'assets/connectors/gitlab-svgrepo-com.svg',
    path: 'gitlab',
    description: 'Monitor all the pull requests and issue activities and builds across all repositories and orgs.',
    connected: false,
    available: false
  },
  {
    key: 'gerrit',
    title: 'Gerrit',
    image: 'assets/connectors/gerrit.png',
    path: 'gerrit',
    description: 'See activities around changesets and patchsets across all the repositories.',
    connected: false,
    available: true
  },
  {
    key: 'googlegroups',
    title: 'Google Groups',
    image: 'assets/connectors/google-groups.png',
    path: 'google-groups',
    description: 'See activities and individuals who are engaged across all monitored public mailing lists.',
    connected: false,
    available: false
  },
  {
    key: 'groupsio',
    title: 'Groups.io',
    image: 'assets/connectors/groupsio.jpeg',
    path: 'groupsio',
    description: 'See activities and individuals who are engaged across all monitored public mailing lists.',
    connected: false,
    available: false
  },
  {
    key: 'jira',
    title: 'Jira',
    image: 'assets/connectors/jira.svg',
    path: 'jira',
    description: 'Monitor your request backlog and issue resolution efficiency across all your projects.',
    connected: false,
    available: false
  },
  {
    key: 'jenkins',
    title: 'Jenkins',
    image: 'assets/connectors/jenkins.png',
    path: 'jenkins',
    description: 'Monitor build duration, build statuses and mean time to recover across all your pipelines.',
    connected: false,
    available: false
  },
  {
    key: 'linkedin',
    title: 'LinkedIn',
    image: 'assets/connectors/linkedin-svgrepo-com.svg',
    path: 'linkedin',
    description: 'Track posts, comments and reactions for a specific account and matching a search criteria.',
    connected: false,
    available: false
  },
  {
    key: 'pipermail',
    title: 'Pipermail',
    icon: 'fas fa-envelope',
    path: 'pipermail',
    description: 'See activities and individuals who are engaged across all monitored public mailing lists.',
    connected: false,
    available: false
  },
  {
    key: 'rocketchat',
    title: 'Rocket.Chat',
    image: 'assets/connectors/rocket-chat-icon-svgrepo-com.svg',
    path: 'rocketchat',
    description: 'See all your activities and participants engaged across all monitored public channels.',
    connected: false,
    available: false
  },
  {
    key: 'slack',
    title: 'Slack',
    image: 'assets/connectors/slack.png',
    path: 'slack',
    description: 'See all your chat activities and participants engaged across all monitored public channels.',
    connected: false,
    available: false
  },
  {
    key: 'twitter',
    title: 'Twitter',
    image: 'assets/connectors/twitter.svg',
    path: 'twitter',
    description: 'See activities like tweets, hashtags and retweets for a specific account and matching a search criteria.',
    connected: false,
    available: false
  },
  {
    key: 'confluence',
    title: 'Wiki',
    image: 'assets/connectors/wiki.png',
    path: 'wiki',
    description: 'See activities like new pages created and pages edited across all spaces and pages.',
    connected: false,
    available: environment.features.tools.insights.wiki
  }
];
export const INSIGHTS_V2_LFX_URL = 'https://insights-v2.lfx.linuxfoundation.org';
export const INSIGHTS_V2_VIEW_RELEASE_SCHEDULE = 'https://docs.linuxfoundation.org/lfx/insights/v2-current/supported-data-source';
