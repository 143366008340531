import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';
import * as FullStory from '@fullstory/browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserPreference } from 'lfx-pcc';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';

import { ChangeLogDialogComponent } from './shared/components/change-log-dialog/change-log-dialog.component';
import { CanDeactivateState } from './shared/guards/pending-changes.guard';
import { FeatureFlagService } from './shared/services/feature-flag.service';
import { UserService } from './shared/services/user.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private featureService: FeatureFlagService
  ) {
    this.activatedRoute.queryParams.subscribe((param: Params) => {
      if (param.v1 === 'true') {
        localStorage.setItem('pcc-v1-only', 'true');
      }

      // Redirect to the url mentioned in returnTo param
      if (param.returnTo) {
        const el = document.createElement('a');
        el.href = param.returnTo;
        this.router.navigate([el.pathname]);
      }
    });

    this.setScript();
    FullStory.init({ orgId: 'MDAHR', devMode: !environment.production });
    this.authService.user$.pipe(take(1)).subscribe((user: User | undefined | null) => {
      datadogRum.init({
        applicationId: '2171b650-b0c8-4236-9249-0b91170b4df9',
        clientToken: 'pub4dcd440df14efd4c777e6ca817056c42',
        site: 'datadoghq.com',
        service: 'lfx-pcc-spa',
        env: environment.datadogEnv,
        sampleRate: environment.datadogEnv ? 100 : 0,
        premiumSampleRate: environment.datadogEnv ? 100 : 0,
        trackInteractions: true,
        trackFrustrations: true,
        allowedTracingOrigins: environment.traceOrigins,
        defaultPrivacyLevel: 'allow'
      });

      if (environment.datadogEnv) {
        datadogRum.startSessionReplayRecording();
      }

      if (user) {
        this.featureService.initialize(user as User);

        FullStory.identify(user.nickname as string, {
          displayName: user.name,
          email: user.email
        });

        datadogRum.setUser({
          id: user['https://sso.linuxfoundation.org/claims/username']
        });
      }
    });

    // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
    window.onpopstate = () => (CanDeactivateState.defendAgainstBrowserBackButton = true);

    this.router.events.subscribe((e) => {
      // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
      CanDeactivateState.defendAgainstBrowserBackButton = false;

      if (e instanceof NavigationStart) {
        if (!localStorage.getItem('pcc-v1-only') && !e.url.includes('v1=true')) {
          const path = e.url.split('/');
          this.redirectToV2(path, e.url);
        }

        const collaborationRegex = new RegExp('(?:setup|services)/(committees|issue-tracking|mailing-lists|meeting-management|meetings|wiki)(.*)');
        if (collaborationRegex.test(e.url)) {
          const newUrl = e.url.replace(collaborationRegex, 'collaboration/$1$2');
          this.router.navigateByUrl(newUrl);
        }

        const developmentRegex = new RegExp('(?:services)/(ci|source-control|distribution)(.*)');
        if (developmentRegex.test(e.url)) {
          const newUrl = e.url.replace(developmentRegex, 'development/$1$2');
          this.router.navigateByUrl(newUrl);
        }

        const operationsRegex = new RegExp('(?:setup|services)/(basic|cloud-providers|domains|email-forwarding|legal|membership)(.*)');
        if (operationsRegex.test(e.url)) {
          const newUrl = e.url.replace(operationsRegex, 'operations/$1$2');
          this.router.navigateByUrl(newUrl);
        }
      }

      if (e instanceof NavigationEnd) {
        if (!localStorage.getItem('pcc-v1-only') && !e.urlAfterRedirects.includes('v1=true')) {
          const path = e.urlAfterRedirects.split('/');
          this.redirectToV2(path, e.urlAfterRedirects);
        }
      }
    });
  }

  public ngOnInit(): void {
    this.userService
      .getCurrentUserPCCPreferences('PCC Changelog')
      .pipe(untilDestroyed(this))
      .subscribe((userPreferences) => {
        if (!userPreferences || userPreferences.Metadata.TotalSize === 0) {
          // Create a new user PCC Changelog preferences record
          this.userService
            .createUserPreference(userPreferences.UserID as string, {
              AppName: 'PCC',
              Name: 'PCC Changelog',
              Type: 'integer',
              Value: DateTime.now().minus({ years: 99 }).toMillis().toString()
            })
            .subscribe((userPreference) => {
              const changeLogDate = DateTime.fromISO(environment.changeLogDate).startOf('day');
              if (changeLogDate > DateTime.now().minus({ months: 6 })) {
                this.openChangeLogDialog(userPreferences.UserID as string, userPreference);
              }
            });
        } else {
          // Check last time the changelog was viewed
          const lastViewed = userPreferences.Data[0].Value as string;
          const lastViewedDate = DateTime.fromMillis(parseInt(lastViewed, 10));
          const changeLogDate = DateTime.fromISO(environment.changeLogDate).startOf('day');

          // If the changelog was updated within the last 6 months and the user has not viewed it yet, open the dialog
          if (changeLogDate > lastViewedDate && changeLogDate > DateTime.now().minus({ months: 6 })) {
            this.openChangeLogDialog(userPreferences.UserID as string, userPreferences.Data[0]);
          }
        }
      });
  }

  private openChangeLogDialog(userID: string, userPreference: UserPreference): void {
    this.dialog
      .open(ChangeLogDialogComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userID
        },
        panelClass: 'modal-v2',
        disableClose: true,
        closeOnNavigation: false
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.userService
            .setUserPreference(userID, {
              ID: userPreference.ID,
              AppName: 'PCC',
              Name: 'PCC Changelog',
              Type: 'integer',
              Value: DateTime.now().toMillis().toString()
            })
            .subscribe();
        }
      });
  }

  private setScript() {
    const script = document.createElement('script');
    script.setAttribute('src', environment.lfxHeader + '/lfx-header-v2.js');
    script.setAttribute('async', 'true');
    document.head.appendChild(script);
  }

  private redirectToV2(path: string[], url: string): void {
    if (path.length === 2) {
      if (path[1] === '') {
        window.location.href = environment.siteURL;
      }
    }

    if (path.length === 3) {
      if (path[1] === 'project' && path[2] !== '') {
        window.location.href = `${environment.siteURL}/project/${path[2]}`;
      }
    }

    if (path.length > 3 && path[1] === 'project' && path[2] !== '') {
      const projectRegex = new RegExp(
        '/(?:project)/(.*?)/(operations|collaboration)/(project-definition|membership|legal|cloud-providers|meetings|issue-tracking|wiki|committees)(.*)'
      );
      // Navigate to the new URL using window.location.href
      const match = url.match(projectRegex);
      if (match) {
        window.location.href = `${environment.siteURL}/project/${match[1]}/${match[2]}/${match[3]}${match[4] ?? ''}`;
      }
    }
  }
}
