import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/shared/services/user.service';
import { EMAIL_REGEX } from '@app/shared/utils/regex';
import { PaginatedResponseUser, User, UserEmailPreference, UserSearch } from 'lfx-pcc';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent {
  @Output() public readonly selectUser: EventEmitter<UserSearch> = new EventEmitter<UserSearch>();
  @Output() public readonly addUser: EventEmitter<string> = new EventEmitter<string>();

  public form: FormGroup;
  public memberSearchLoading: boolean = false;
  public memberName: string = '';
  public users: User[] = [];

  public constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) {
    this.form = this.fb.group({ name: ['', [Validators.minLength(2)]] });
    this.nameSearchSubscription();
  }

  public getFullName(user: User | null): string {
    return user ? `${user.FirstName} ${user.LastName}` : '';
  }

  public validateEmail(emailAddress: string): boolean {
    const EMAIL_REGEXP = new RegExp(EMAIL_REGEX);
    return EMAIL_REGEXP.test(emailAddress.toLowerCase());
  }

  public get noResultError(): boolean {
    return this.memberName ? this.memberName.length > 1 && !this.memberSearchLoading && (!this.users || this.users.length === 0) : false;
  }

  public get resultWithAddNewUser(): boolean {
    return this.memberName ? this.memberName.length > 1 && !this.memberSearchLoading && this.users && this.users.length > 0 : false;
  }

  public hasMeetingPreference(user: User, email: string): boolean {
    return user.UserEmailPreference?.some((preference: UserEmailPreference) => preference.Email === email && preference.Type === 'Meeting') || false;
  }

  public onSelectUser(user: User, email: string): void {
    this.selectUser.emit({ user, email });
  }

  public onAddNewUser(): void {
    this.addUser.emit(this.memberName);
  }

  private nameSearchSubscription(): void {
    this.form.controls.name.valueChanges.pipe(debounceTime(1000)).subscribe((value: string) => {
      this.memberName = value ? value : '';
      this.users = [];
      this.memberSearchLoading = true;
      if (value && value.trim() !== '' && value.length >= 2) {
        const searchValue = value.trim();
        if (this.validateEmail(searchValue)) {
          const params = new HttpParams({ fromObject: { email: searchValue, pageSize: '6' } });
          this.userService.searchUser(params).subscribe((paginatedResponseUser: PaginatedResponseUser) => {
            this.memberSearchLoading = false;
            this.users = paginatedResponseUser.Data.filter((u) => u.Emails && u.Emails.length > 0);
          });
        } else {
          const params = new HttpParams({ fromObject: { username: encodeURI(searchValue), pageSize: '6' } });
          this.userService.searchAuth0User(params).subscribe((userResponse: User[]) => {
            this.memberSearchLoading = false;
            this.users = userResponse.filter((u) => u.Emails && u.Emails.length > 0);
          });
        }
      } else {
        this.memberSearchLoading = false;
      }
    });
  }
}
