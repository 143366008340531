import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { ColumnDefinitionsDirective } from '@app/shared/directives/column-definitions/column-definitions.directive';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-cascading-table',
  templateUrl: './cascading-table.component.html',
  styleUrls: ['./cascading-table.component.scss']
})
export class CascadingTableComponent implements OnInit, AfterContentInit {
  @ViewChild('table', { static: false }) public table: DatatableComponent;
  @ViewChild('nameDefaultColumn', { static: true }) public nameDefaultColumn!: TemplateRef<any>;
  @ViewChild('totalProjDefaultColumn', { static: true }) public totalProjDefaultColumn!: TemplateRef<any>;
  @ViewChild('statusDefaultColumn', { static: true }) public statusDefaultColumn!: TemplateRef<any>;

  @Input() public data!: any[];
  @Input() public dataLoading: boolean = true;
  @Input() public limit: number = 10;
  @Input() public hidePagination: boolean = false;
  @Input() public emptyMessage: string = '';

  @Output() public readonly selectedValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly dataChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ContentChildren(ColumnDefinitionsDirective, { read: TemplateRef }) public columnTemplates: QueryList<TemplateRef<ColumnDefinitionsDirective>>;
  @ContentChildren(ColumnDefinitionsDirective) public columnDefinitions!: QueryList<ColumnDefinitionsDirective>;

  public columns: TableColumn[] = [];

  public constructor() {}

  public ngOnInit(): void {}

  public ngAfterContentInit(): void {
    if (this.columnTemplates.length > 0) {
      this.columns = this.columnTemplates.map((ct: TemplateRef<ColumnDefinitionsDirective>, idx: number) => {
        const colDef = this.columnDefinitions.toArray()[idx];
        return {
          ...colDef,
          cellTemplate: ct
        };
      });
    } else {
      this.columns = [
        {
          prop: 'Name',
          name: 'Name',
          isTreeColumn: true,
          flexGrow: 6,
          cellTemplate: this.nameDefaultColumn
        },
        {
          prop: 'TotalProjects',
          name: '# Projects',
          sortable: false,
          flexGrow: 1.5,
          cellTemplate: this.totalProjDefaultColumn
        },
        {
          prop: 'Status',
          name: 'Status',
          sortable: false,
          flexGrow: 1.5,
          cellTemplate: this.statusDefaultColumn
        }
      ];
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onTreeAction(event: any): void {
    const row = event.row;
    if (row.treeStatus === 'collapsed') {
      this.limit = this.limit + event.row.TotalProjects;
      row.treeStatus = 'expanded';
      this.data = [...this.data];
    } else {
      this.limit = this.limit - event.row.TotalProjects;
      row.treeStatus = 'collapsed';
      this.data = [...this.data];
    }
    this.dataChange.emit(this.data);
  }

  public onSelect(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedValue.emit(target.value);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getRowClass(row: any): string {
    let className = `level-${row && row.level ? row.level : 0} `;
    className += row.level > 2 || row.disabledState ? 'disabled-row' : '';
    return className;
  }

  public recalculateTableSize(): void {
    this.table.recalculate();
  }
}
