import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { tippyProperties } from '@utils/constants';
import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent implements OnInit, AfterViewInit {
  @Input() public options: { label: string; value: any; icon?: string; img?: string; iconTitle?: string; imgTitle?: string }[] | null;
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string = 'Select';
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';
  @Input() public validMessage: string = '';
  @Input() public fullWidth: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public multiple: boolean = false;
  @Input() public labelSubtext: string = '';
  @Input() public infoIconText: string = '';
  @Input() public infoIconClass: string = 'fas fa-info-circle';
  @Input() public useMaterial: boolean = true;
  @Input() public tabIndex: number = 1;
  @Input() public hideDefaultOpt: boolean = true;
  @Input() public showNoneOption: boolean = false;
  @Input() public groupOption: boolean = false;
  @Input() public groupOptions: { title: string; list: { label: string; value: string }[] }[];
  @Input() public disableDefaultOpt: boolean = true;
  @Input() public defaultValue: string = '';
  @Input() public showValidationIcon: boolean = true;
  @Input() public loading: boolean = false;
  @Input() public componentLoading: boolean = false;
  @Input() public autofocus: boolean = false;
  @Input() public showTooltipAfterNCharacter: number = 0;
  @Input() public cyLabel: string = '';
  @Input() public tippyProps = tippyProperties;
  @Input() public customOptions: boolean = false;

  @ViewChild('matSelectRef') public matSelectRef: MatSelect;

  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  public inputControl: AbstractControl;
  public skeletonTheme: NgxSkeletonLoaderConfigTheme = {
    height: '35px',
    marginBottom: '0',
    marginTop: '5px'
  };

  public constructor() {}

  public ngOnInit(): void {
    if (this.multiple) {
      this.parent.controls[this.control] = new FormControl([this.parent.controls[this.control].value]);
    } else {
      if (!this.control) {
        throw new Error(`Attribute 'control' is required`);
      }
      this.inputControl = this.parent.get(this.control) as AbstractControl;
    }
  }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      this.matSelectRef.focus();
    }
  }

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return (
        (this.inputControl.touched || this.inputControl.dirty) &&
        (this.inputControl.errors || (this.inputControl.hasError('required') && this.inputControl.value === ''))
      );
    }

    return false;
  }

  public getMatSelectToolTipText(control: string): string {
    const label = (!!this.parent && this.options?.find((opt) => opt.value === this.parent.controls[control].value)?.label) || '';
    return this.showTooltipAfterNCharacter !== 0 && label && label.length > this.showTooltipAfterNCharacter ? label : '';
  }

  public getMatOptionToolTipText(label: string): string {
    return this.showTooltipAfterNCharacter !== 0 && label && label.length > this.showTooltipAfterNCharacter ? label : '';
  }

  public setSelectionValue(event: MatSelectChange): void {
    this.formGroupChange.emit(event.value);
  }
  public getErrorMessage(control: AbstractControl): string {
    return control.dirty && control.hasError('required')
      ? 'This field is required.'
      : control.hasError('maxlength')
      ? 'Value cannot exceed 150 characters'
      : control.hasError('file')
      ? 'Please upload a valid file'
      : control.hasError('url')
      ? 'Please enter a valid URL including https/http protocol'
      : control.hasError('email')
      ? 'Please enter a valid email'
      : control.hasError('exists')
      ? 'Value already exists'
      : control.hasError('numeric')
      ? 'Value needs to be numeric'
      : 'Please enter a valid input';
  }
}
