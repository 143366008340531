<div class="breadcrumbs" data-cy="breadcrumb-component">
  <div *ngIf="!loading">
    <a routerLink="/">Home</a> /
    <span *ngFor="let item of hierarchy">
      <a routerLink="{{ item.Slug === 'dashboard' ? '/' : '/project/' + item.ID }}">
        {{ item.Name }}
      </a>
      /
    </span>
    <a class="a" routerLink="/project/{{ currentProject.ID }}">{{ currentProject.Name }}</a>
    <span *ngIf="currentService && currentService[1]"> /
      {{ cleanUpText(currentService[1].replace('services', 'it services')) }}</span>
    <span *ngIf="currentService && currentService[2] && isNotCustomService">
      / {{ cleanUpText(currentService[2]) | titleCase }}
    </span>
    <span *ngIf="domainName && currentService && currentService[2] && currentService[2] === 'domains'">
      / <a routerLink="/project/{{ currentProject.ID }}/{{ currentService[1] }}/{{ currentService[2] }}">{{
        currentService[2] }}</a>
    </span>
    <!-- EasyCLA Breadcrumbs -->
    <span *ngIf="currentService && currentService[2] && currentService[2] === 'easycla'">
      /
      <a *ngIf="currentService[3] && currentService[3] !== 'overview'"
        routerLink="/project/{{ currentProject.ID }}/{{ currentService[1] }}/{{ currentService[2] }}">
        <span *ngIf="group"> {{ currentService[2] | titleCase }} - {{ group.cla_group_name }} </span>
        <span *ngIf="currentGroup"> {{ currentService[2] | titleCase }} - {{ currentGroup.cla_group_name }} </span>
      </a>
      <a *ngIf="currentService[3] && currentService[3] === 'overview' && currentGroup"
        routerLink="/project/{{ currentProject.ID }}/{{ currentService[1] }}/{{ currentService[2] }}">
        <span *ngIf="currentGroup"> {{ currentService[2] | titleCase }} - {{ currentGroup.cla_group_name }} /</span>
      </a>
      <ng-container *ngIf="currentService[3] && currentService[3] === 'overview' && !currentGroup">
        {{ currentService[2] | titleCase }}
      </ng-container>
      <ng-container *ngIf="(currentService[3] && currentService[3] === 'create' && !currentGroup)">
        <a routerLink="/project/{{ currentProject.ID }}/{{ currentService[1] }}/{{ currentService[2] }}">
          {{ currentService[2] | titleCase }}
        </a>
      </ng-container>
    </span>
    <span *ngIf="currentService && currentService[2] && currentService[2] === 'meetings'"> /
      <a *ngIf="currentService[3] && currentService[3] !== ''" class="a"
        routerLink="/project/{{ currentProject.ID }}/collaboration/meetings">meetings</a>
      <span *ngIf="currentService.length <= 3">meetings</span>
    </span>

    <span *ngIf="currentService && currentService[2] && currentService[2] === 'mailing-lists'"> / <a class="a"
        routerLink="/project/{{ currentProject.ID }}/collaboration/mailing-lists"
        *ngIf="currentService && currentService[3]">mailing lists</a>
      <ng-container *ngIf="currentService && !currentService[3]">mailing lists</ng-container>
    </span>

    <!-- Insights Breadcrumbs -->
    <span *ngIf="currentService && currentService[2] && currentService[2] === 'insights'">
      / <a routerLink="/project/{{ currentProject.ID }}/{{ currentService[1] }}/{{ currentService[2] }}">
        {{ currentService[2] }}
      </a>
    </span>
    <!-- Committees Breadcrumbs -->
    <span *ngIf="currentService && currentService[2] && currentService[2] === 'committees' && !committee"> /
      committees </span>
    <span
      *ngIf="currentService && currentService[2] && currentService[2] === 'committees' && committee && committee.Name">
      /
      <a routerLink="/project/{{ currentProject.ID }}/{{ currentService[1] }}/{{ currentService[2] }}">
        {{ currentService[2] }}
      </a>
    </span>
    <span *ngIf="currentService && currentService[3] && !currentGroup && !committee"> / {{ currentService[3] ===
      'repositories' ?
      'REPOSITORIES SETTINGS' : cleanUpText(currentService[3]) }}
    </span>

    <!-- <span *ngIf="currentService && currentService[4]"> / {{cleanUpText(currentService[4])}} </span> -->

    <span *ngIf="currentGroup"> SIGNATURES</span>
    <span *ngIf="committee && committee.Name"> / {{ committee.Name }} </span>
  </div>
</div>
