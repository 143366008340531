<div class="page-count" *ngIf="rowCount > 0">
  Showing Results {{(curPage - 1) * pageSize + 1}} &ndash; {{(pageSize * curPage) > rowCount ? rowCount :
  (pageSize * curPage) }} of {{rowCount.toLocaleString()}}
</div>
<datatable-pager #ngxDatatablePager [pagerLeftArrowIcon]="'datatable-icon-left'"
  [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
  [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="true"
  (change)="table.onFooterPage($event)">
</datatable-pager>

<div class="page-selector" *ngIf="rowCount > 0">
  <label class="page-selector__label">Page</label>
  <input type="number" (keyup.enter)="goToPage(input.value)" #input [value]="ngxDatatablePager.page"
    [disabled]="(ngxDatatablePager.page <= 1 && ngxDatatablePager.totalPages === 1)" [min]="1"
    [max]="ngxDatatablePager.totalPages" oninput="validity.valid||(value='');">
  <span>of {{ngxDatatablePager.totalPages}}</span>
  <button [disabled]="(ngxDatatablePager.page <= 1)" (click)="onPreviousHandle()">
    <i class="fas fa-caret-left"></i>
  </button>
  <button [disabled]="(ngxDatatablePager.page === ngxDatatablePager.totalPages)" (click)="onNextHandle()">
    <i class="fas fa-caret-right"></i>
  </button>
</div>
