<div class="sidebar" data-cy="sidebar-component-search-project">
  <div class="search-projects">
    <div class="search-wrapper" [formGroup]="form">
      <input
        type="text"
        placeholder="Search for a project"
        formControlName="sidebarSearch"
        [matAutocomplete]="auto"
        data-cy="sidebar-component-search-project-input"
      />
      <i class="icon" class="fas" [class]="sidebarSearching ? 'fas fa-circle-notch fa-spin' : 'fa-search'"></i>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="goToProject($event)">
      <mat-option [class]="'level-' + project.level?.toString()" *ngFor="let project of projects" [value]="project">
        <span [title]="project.Name" data-cy="sidebar-component-search-project-result-span">{{ project.Name }} </span>
      </mat-option>

      <mat-option class="no-data" [disabled]="true" *ngIf="showNoData" [value]="null">
        <i class="fas fa-ban"></i>
        PROJECT NOT FOUND
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="project-logo" [class.no-logo]="project && !project.ProjectLogo">
    <ngx-skeleton-loader *ngIf="projectID && !project" [theme]="{ height: '43.5px', width: '200px', margin: '0' }"></ngx-skeleton-loader>
    <img *ngIf="project && project.ProjectLogo" [src]="project.ProjectLogo" [alt]="project.Name" />
    <span *ngIf="project && !project.ProjectLogo" class="project-name">{{ project.Name }}</span>
  </div>
  <div class="navigation">
    <ng-container *ngIf="showLinks">
      <div class="navigation-group">
        <div class="navigation-item home" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="current">
          <a [routerLink]="['/project', projectID]">
            <i class="far fa-home" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="fas"></i>
            <span>Home</span>
          </a>
        </div>
      </div>
      <div class="navigation-group" *ngFor="let nav of navItems; index as idx" data-cy="sidenav-group" [ngClass]="nav.active ? 'active' : ''">
        <a class="navigation-group-title" [class.active]="nav.active" (click)="toggleDropdown(nav)" data-cy="sidenav-group-title">
          <span>{{ nav.name | uppercase }}</span>
          <i class="fas" [ngClass]="nav.active ? 'fa-caret-down' : 'fa-caret-right'"></i>
        </a>
        <ng-container>
          <div class="wrapper" [ngStyle]="{ maxHeight: getGroupMaxHeight(nav.items, !nav.active) }">
            <div
              class="navigation-item"
              *ngFor="let item of nav.items"
              [class.active]="item.active"
              [routerLinkActive]="item.slug"
              data-cy="sidenav-group-items"
            >
              <div *ngIf="item.disabled" class="disabled">
                <i [class]="item.icon"></i>
                <span>{{ item.name }}</span>
              </div>
              <a *ngIf="!item.disabled" [routerLink]="item.slug" [routerLinkActive]="item.slug" [class.active]="item.active" [class.disabled]="item.disabled">
                <i [class]="item.icon" [class.fas]="item.active"></i>
                <span>{{ item.name }}</span>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
      <hr />
      <div class="navigation-group">
        <div class="navigation-item home">
          <a [routerLink]="['/']">
            <i class="far fa-home"></i>
            <span>Back To All Projects</span>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <a class="navigate-to-v2" [href]="newSiteURL" (click)="onNavigateNewSite()">PCC V2 (Beta)</a>
</div>
<div class="sidebar-round"></div>
<div class="sidebar-round white"></div>
