import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
interface Option {
  value: string;
  label: string;
}

@Component({
  selector: 'app-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss']
})
export class ButtonToggleGroupComponent implements OnInit {
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';
  @Input() public fullWidth: boolean = false;

  @Input() public options: Option[];
  public inputControl: AbstractControl;

  public constructor() {}

  public ngOnInit(): void {}
  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return this.inputControl.errors || (this.inputControl.touched && this.inputControl.hasError('required') && this.inputControl.value === '');
    }

    return false;
  }
}
