import { DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  public jsdUrl: string = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/358';
  public tippyProps: NgxTippyProps = {
    trigger: 'click',
    arrow: false
  };

  public currentTimestamp = new Date().toUTCString();
  public navigator = navigator;

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { stackTrace: string; currentURL?: string },
    @Inject(LOCALE_ID) private locale: string,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private ngxTippyService: NgxTippyService
  ) {}

  public ngOnInit(): void {}

  public hideTooltip(): void {
    setTimeout(() => {
      this.ngxTippyService.hideAll();
    }, 3000);
  }

  public get getClipboardData(): string {
    const datePipe = new DatePipe(this.locale);
    return `URL: ${this.data.currentURL}\n\nTime: ${datePipe.transform(this.currentTimestamp, 'long')}\n\nError: ${
      this.data.stackTrace
    } \n\nAdditional Information: ${this.navigator.userAgent}`;
  }

  public get currentURL(): string {
    return this.data.currentURL ?? window.location.href;
  }
}
