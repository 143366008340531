import { FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Project, ProjectSummary, ProjectSummaryCategory } from 'lfx-pcc';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import * as momentTimezone from 'moment-timezone';

// helper function to derive parent hierarchy from project search
// Returns an array of Projects but sub projects are listed as object keys instead of arrays
export const deriveParent = (data: Project[]): any => {
  // converting them to objects so it's easier to check and add children
  const rootParents = _.keyBy(
    _.filter(data, (d: Project) => d.ParentHierarchy === undefined || d.ParentHierarchy === null),
    (kd) => kd.ID
  );

  // looping through all of the data and assigning them to their parent
  // we need to define this as 'any' because we'll be using the ID as the key property
  const dataWithParent: any[] = data.filter((d) => d.ParentHierarchy);

  dataWithParent.forEach((chd) => {
    let parent: any = chd.ParentHierarchy;
    // first level parent
    if (parent) {
      // adding the child (clone of chd) to this new parent
      const cloneChild = _.cloneDeep(chd);
      delete cloneChild.ParentHierarchy; // remove the hierarchies
      parent.Projects = { [chd.ID]: cloneChild };
    }
    // loop until we get to the root parent
    while (parent.ParentHierarchy) {
      // creating detached objects so it's not affected by the property deletion below
      const child = _.cloneDeep(parent);
      delete child.ParentHierarchy;

      parent = _.assign(_.cloneDeep(parent.ParentHierarchy), {
        Projects: { [child.ID]: child }
      });
    }

    if (rootParents[parent.ID]) {
      _.merge(rootParents[parent.ID], parent);
      // merge them
    } else {
      // create new
      rootParents[parent.ID] = parent;
    }

    rootParents[parent.ID].TotalProjects = _.keys(rootParents[parent.ID].Projects).length;
  });

  return rootParents;
};

export const arrToKeys = (data: Project[]): any =>
  // convert array of objects to keys for easy merging
  _.keyBy(
    _.map(data, (d: any) => {
      if (d.Projects) {
        d.Projects = arrToKeys(d.Projects);
      }
      return d;
    }),
    (kd) => kd.ID
  );

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const keysToArr = (data: any): Project[] =>
  // convert the keys back to array
  _.map(data, (d: any) => {
    if (d.Projects) {
      d.Projects = keysToArr(d.Projects);
    }
    return d;
  });

export const getChildren = (childProjects: Project, currentLevel: number, parentId: string, parents: string[]): Project[] => {
  if (childProjects.Projects) {
    return childProjects.Projects.map((arrayItem: Project) => {
      const itemCp: Project = _.cloneDeep(arrayItem);
      itemCp.level = currentLevel + 1;
      itemCp.ProjectParent = parentId;

      if (arrayItem.Projects) {
        itemCp.treeStatus = 'collapsed';
        delete itemCp.Projects;
        const tmpParent: string[] = _.concat(parents, [arrayItem.ID]);

        return [itemCp, getChildren(arrayItem, currentLevel + 1, arrayItem.Name, tmpParent)];
      }
      itemCp.treeStatus = 'disabled';
      return itemCp;
    }) as Project[];
  }
  return [];
};

export const flattenData = (data: Project[]): Project[] => {
  const tmpData: Project[] = _.cloneDeep(data);

  return _.flattenDeep(
    tmpData.map((arrayItem: Project) => {
      const itemCp: Project = _.cloneDeep(arrayItem);
      itemCp.level = 0;
      if (arrayItem.Projects) {
        itemCp.treeStatus = 'collapsed';
        delete itemCp.Projects;

        return [itemCp, getChildren(arrayItem, 0, arrayItem.Name, [arrayItem.ID])];
      }
      itemCp.treeStatus = 'disabled';
      return itemCp;
    })
  );
};

export const getSummaryChildren = (
  childProjects: ProjectSummary,
  currentLevel: number,
  parentId: string,
  parents: string[],
  skipTreeStatus: boolean
): ProjectSummary[] => {
  if (childProjects.Projects) {
    return childProjects.Projects.map((arrayItem: ProjectSummary) => {
      const itemCp: ProjectSummary = _.cloneDeep(arrayItem);
      itemCp.level = currentLevel + 1;
      itemCp.ProjectParent = parentId;
      itemCp.parents = parents; // will hold parent and grandparent IDs

      if (arrayItem.Projects && arrayItem.Projects.length > 0) {
        if (!skipTreeStatus) {
          itemCp.treeStatus = 'collapsed';
        }
        delete itemCp.Projects;
        const tmpParent: string[] = _.concat(parents, [arrayItem.ID]);

        return [itemCp, getSummaryChildren(arrayItem, currentLevel + 1, arrayItem.Name, tmpParent, skipTreeStatus)];
      }
      itemCp.treeStatus = 'disabled';
      return itemCp;
    }) as ProjectSummary[];
  }
  return [];
};

export const flattenSummaryData = (data: ProjectSummary[], skipTreeStatus: boolean | undefined = false): ProjectSummary[] => {
  const tmpData: ProjectSummary[] = _.cloneDeep(data);

  return _.flattenDeep(
    tmpData.map((arrayItem: ProjectSummary) => {
      const itemCp: ProjectSummary = _.cloneDeep(arrayItem);
      itemCp.level = 0;
      if (arrayItem.Projects && arrayItem.Projects.length > 0) {
        if (!skipTreeStatus) {
          itemCp.treeStatus = 'collapsed';
        }
        delete itemCp.Projects;

        return [itemCp, getSummaryChildren(arrayItem, 0, arrayItem.Name, [arrayItem.ID], skipTreeStatus)];
      }
      itemCp.treeStatus = 'disabled';
      return itemCp;
    })
  );
};

// eslint-disable-next-line max-len
export const prepareFilterQueryString = (filterParams: { [key: string]: string | null }): string =>
  Object.keys(filterParams)
    .map((key) => filterParams[key])
    .filter((value) => value)
    .join(' and ');

export const getTz = (): string => {
  const zoneName = momentTimezone.tz.guess();
  return momentTimezone.tz(zoneName).zoneAbbr();
};

export const moveCategoriesToProjects = (data: ProjectSummary): ProjectSummary[] => {
  let prjSummaries: ProjectSummary[] = data.Projects || [];
  if (data.Categories && data.Categories.length > 0) {
    prjSummaries = _.concat(
      data.Projects || [],
      _.flatMap(data.Categories, (ct: ProjectSummaryCategory): ProjectSummary[] =>
        ct.Projects.map((p: ProjectSummary) => {
          if (p.Categories && p.Categories.length > 0) {
            p.Projects = moveCategoriesToProjects(p);
          }
          return p;
        })
      )
    );
  }
  return prjSummaries;
};

export const downloadFile = (blob: Blob, filename: string): void => {
  saveAs(blob, filename);
};

export const covertDateToISO = (dateStr: string, extraDay: number = 0, format: string = 'yyyy-MM-dd'): string => {
  let dateObj = DateTime.fromISO(new Date(dateStr).toISOString(), { zone: 'utc' }).toFormat(format);
  if (extraDay > 0) {
    dateObj = DateTime.fromISO(new Date(dateStr).toISOString(), { zone: 'utc' }).plus({ days: extraDay }).toFormat(format);
  }
  return dateObj;
};

export const getErrorMessage = (parent: FormGroup, control: string): string => {
  const inputControl = parent.get(control);

  if (inputControl && inputControl.errors) {
    return inputControl.hasError('required')
      ? 'This field is required.'
      : inputControl.hasError('maxlength')
      ? 'Max character length exceeded'
      : inputControl.hasError('file')
      ? 'Please upload a valid file'
      : inputControl.hasError('url')
      ? 'Please enter a valid URL including http/https protocol'
      : inputControl.hasError('email')
      ? 'Please enter a valid email'
      : inputControl.hasError('exists')
      ? 'Value already exists'
      : inputControl.hasError('numeric')
      ? 'Value needs to be numeric'
      : inputControl.hasError(control)
      ? inputControl.getError(control)
      : 'Please enter a valid input';
  }

  return '';
};

export const getBaseUrl = (url: string): string => {
  const completeUrl = new URL(url);
  return completeUrl.origin;
};

/**
 * companyDisplay - Handles the displaying of company/organization name. This function replaces 'Individual - No Account' with '-'
 * Added this as a helper in case we need to change this rule later
 *
 * @param name
 * @returns string
 */
export const companyDisplay = (name: string, replace: string = '-'): string => (name.toLowerCase() === 'individual - no account' ? replace : name ? name : '-');
