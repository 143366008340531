import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-input-color-picker',
  templateUrl: './input-color-picker.component.html',
  styleUrls: ['./input-color-picker.component.scss']
})
export class InputColorPickerComponent implements OnInit {
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';
  @Input() public color: string;

  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public inputControl: AbstractControl;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }
    this.inputControl = this.parent.get(this.control) as AbstractControl;
  }

  public onInputCheck(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.changeColor(inputElement.value);
  }

  public changeColor(color: string): void {
    this.color = color;
    this.inputControl.setValue(this.color);
  }

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return (
        (this.inputControl.touched || this.inputControl.dirty) &&
        (this.inputControl.errors || (this.inputControl.hasError('required') && this.inputControl.value === ''))
      );
    }

    return false;
  }

  public getErrorMessage(control: AbstractControl): string {
    return control.hasError('required')
      ? 'This field is required.'
      : control.hasError('maxlength')
      ? 'Value cannot exceed 7 characters'
      : 'Please enter a valid input';
  }
}
