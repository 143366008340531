import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { DataTableColumnDirective, ColumnChangesService } from '@swimlane/ngx-datatable';

@Directive({
  selector: '[appColumnDefinition]'
})
export class ColumnDefinitionsDirective extends DataTableColumnDirective {
  public constructor(private colChangeService: ColumnChangesService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    super(colChangeService);
  }
}
