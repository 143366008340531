import { Component, ContentChildren, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild } from '@angular/core';
import { ColumnMode, DataTableColumnDirective, DatatableComponent, SortPropDir } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-ngx-datatable',
  templateUrl: './ngx-datatable.component.html',
  styleUrls: ['./ngx-datatable.component.scss']
})
export class NgxDatatableComponent implements OnInit, OnChanges {
  @ViewChild(DatatableComponent) public dataTable: DatatableComponent;
  @ContentChildren(DataTableColumnDirective) public val: QueryList<DataTableColumnDirective>;

  @Input() public reorderable: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public scrollbarH: boolean = false;
  @Input() public showFooter: boolean = false;
  @Input() public dataRows: any[] = [];
  @Input() public emptyMessage: string = '';
  @Input() public columnMode: ColumnMode = ColumnMode.flex;
  @Input() public rowHeight: number | 'auto' | ((row?: any) => number) = 'auto';
  @Input() public headerHeight: number;
  @Input() public footerHeight: number;
  @Input() public limit: number = 9999;
  @Input() public externalPaging: boolean = false;
  @Input() public count: number = 0;
  @Input() public pageSize: number = 10;
  @Input() public offset: number = 0;
  @Input() public currentPage: number = 0;
  @Input() public rowClass: any;
  @Input() public groupRowsBy: string = '';
  @Input() public groupExpansionDefault: boolean = false;
  @Input() public hideHeaderOnEmpty: boolean = true;
  @Input() public showPageSize: boolean = false;
  @Input() public pageSizeOptions: number[] = [10, 20, 30, 50, 100];
  @Input() public externalSorting: boolean = false;

  @Output() public readonly navigate: EventEmitter<'next' | 'prev'> = new EventEmitter();
  @Output() public readonly navigateToPage: EventEmitter<number> = new EventEmitter();
  @Output() public readonly activate: EventEmitter<{ type: string; row: any }> = new EventEmitter<{ type: string; row: any }>();
  @Output() public readonly pageSizeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly sort: EventEmitter<{ sorts: SortPropDir[] }> = new EventEmitter<{ sorts: SortPropDir[] }>();

  public constructor() {}

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    if (this.dataTable) {
      this.dataTable.recalculate();
    }
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.dataTable.columnTemplates = this.val;
      if (this.externalPaging) {
        this.dataTable.pageSize = this.pageSize;
        this.dataTable.count = this.count;
        this.dataTable.offset = this.offset;
      } else {
        this.pageSize = this.limit;
        this.dataTable.limit = this.limit;
        this.dataTable.pageSize = this.limit;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataRows && !changes.dataRows.firstChange) {
      this.dataTable.rows = this.dataRows;
      if (this.externalPaging) {
        this.dataTable.pageSize = this.pageSize;
        this.dataTable.count = this.count;
      } else {
        this.pageSize = this.limit;
        this.dataTable.limit = this.limit;
        this.dataTable.pageSize = this.limit;
      }
    }

    if (changes.offset && !changes.offset.firstChange) {
      if (this.offset === 0) {
        this.dataTable.offset = 0;
      }
    }

    if (changes.currentPage && !changes.currentPage.firstChange && changes.currentPage.currentValue !== 0) {
      this.dataTable.offset = changes.currentPage.currentValue - 1;
    }

    if (this.dataTable) {
      this.dataTable.recalculate();
    }
  }

  public goToPage(page: string): void {
    this.currentPage = 0;
    if (this.externalPaging && page) {
      this.navigateToPage.emit((parseInt(page, 10) - 1) * this.pageSize);
      this.dataTable.offset = parseInt(page, 10) - 1;
    } else if (page) {
      this.dataTable.offset = parseInt(page, 10) - 1;
    }
  }

  public onNextHandle(): void {
    this.currentPage = 0;
    this.dataTable.offset++;
    this.navigate.emit('next');
  }

  public onPreviousHandle(): void {
    this.currentPage = 0;
    this.dataTable.offset--;
    this.navigate.emit('prev');
  }

  public onActivate(event: { type: string; row: any }): void {
    this.activate.emit(event);
  }

  public onPageSizeChange(event: Event): void {
    this.pageSize = parseInt((event.target as HTMLInputElement).value, 10);
    this.dataTable.pageSize = this.pageSize;
    this.dataTable.limit = this.pageSize;
    this.pageSizeChange.emit(this.pageSize);
    this.goToPage('1');
  }

  public onSort(event: { sorts: SortPropDir[] }): void {
    this.sort.emit(event);
  }
}
