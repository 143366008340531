<div class="changelog-dialog" data-cy="change-log-dialog-component">
  <div class="dialog-title">
    <div>
      <h1>Notice</h1>
    </div>
    <div>
      <button class="lfx-button clear" [mat-dialog-close]><i class="fas fa-times"></i></button>
    </div>
  </div>
  <div mat-dialog-content>
    <p><b>Looking for Email Forwarding?</b> You can find it on the drop-down for your managed domains.</p>
    <p>Have feedback? Please leave a comment at <a href="https://community.lfx.dev/t/updates-to-pcc-navigation/1546"
        target="_blank">https://community.lfx.dev/t/updates-to-pcc-navigation/1546</a></p>
  </div>
  <div class="actions" fxLayout="row nowrap" fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <div class="action-checkbox">
      <mat-checkbox [disableRipple]="true" color="accent" [(ngModel)]="doNotShow"> Do not show again
      </mat-checkbox>
    </div>
    <div class="action-close">
      <button mat-button class="lfx-button blue" (click)="onClose()">OK</button>
    </div>
  </div>
</div>
