<ng-container>
  <div *ngIf="!noNamedButtonsPagination && !hide" class="pagination" fxLayout="row" fxLayoutAlign="center center"
    fxLayoutGap="10px">
    <a (click)="onPrevious()" [ngClass]="{'disabled': disablePrev}" [innerHTML]="'Previous <<<'"></a>
    <span class="disabled">|</span>
    <a (click)="onNext()" [innerHTML]="'Next >>>'"></a>
  </div>
  <div *ngIf="noNamedButtonsPagination && !hide" class="no-named-button-pagination" fxLayout="row"
    fxLayoutAlign="end center">
    <div class="page-selector" fxLayout="row" fxLayoutAlign="center center" fxFlex="40">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3px">
        <button data-cy="pagination-prev-btn" [disabled]="disablePrev" (click)="onPrevious()">
          <i class="fas fa-caret-left"></i>
        </button>
        <button data-cy="pagination-next-btn" [disabled]="disableNext" (click)="onNext()">
          <i class="fas fa-caret-right"></i>
        </button>
      </div>
    </div>
    <div class="page-size" *ngIf="showPageSize" fxLayout="row" fxLayoutAlign="end center" fxFlex="30">
      <label class="page-size__label">Rows per page</label>
      <select data-cy="pagination-page-size-dropdown" class="page-size__select" (change)="onPageSizeChange($event)"
        [value]="pageSize">
        <option *ngFor="let size of pageSizeOptions" [selected]="size === pageSize" [value]="size">
          {{ sizeDisplayValue(size) }}
        </option>
      </select>
    </div>
  </div>
</ng-container>
