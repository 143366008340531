<div class="delete-dialog" *ngIf="data" data-cy="delete-dialog-component">
  <div class="dialog-title" *ngIf="data.title">
    <div>
      <h1>{{ data.title }}</h1>
    </div>
    <div>
      <button class="lfx-button clear" [mat-dialog-close] *ngIf="!data.hideCloseButton"><i
          class="fas fa-times"></i></button>
    </div>
  </div>
  <div mat-dialog-content>
    <p *ngIf="data.record">{{ data.record }}</p>
    <p *ngIf="data.secondRecord">{{ data.secondRecord}}</p>
    <span *ngIf="data.message" [innerHTML]="data.message"></span>

    <ng-container *ngIf="data.template" [ngTemplateOutlet]="data.template"></ng-container>

  </div>
  <div *ngIf="!data.customActions" class="actions delete-record-actions" fxLayout="row nowrap" fxLayoutGap="15px"
    [fxLayoutAlign]="data.buttonAlignment">
    <button *ngIf="data.tertiaryButtonLabel" mat-button class="lfx-button grey" [ngClass]="tertiaryButtonColor"
      (click)="exit()">{{ data.tertiaryButtonLabel
      }}</button>
    <button mat-button *ngIf="!data.hideSecondaryButton" class="lfx-button" [ngClass]="secondaryButtonColor"
      (click)="cancel()" data-cy="delete-dialog-cancel-button">
      {{ secondaryButtonLabel }}
    </button>
    <button mat-button [ngClass]="buttonCustomColor" (click)="confirmDelete()" data-cy="delete-dialog-confirm-button">{{
      data.buttonLabel || 'Delete'
      }}</button>
  </div>
  <div *ngIf="data.customActions" class="actions delete-record-actions" fxLayout="row nowrap" fxLayoutGap="15px"
    [fxLayoutAlign]="data.buttonAlignment">
    <ng-container [ngTemplateOutlet]="data.customActions"></ng-container>
  </div>
</div>
