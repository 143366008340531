import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [^\s]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }
}
