import { Component, Input, OnInit } from '@angular/core';
import { Committee, ITXProgressAndStatus, ProgressAndStatus, Project } from 'lfx-pcc';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() public project!: Project;
  @Input() public childProject!: Project;
  @Input() public committee!: Committee;
  @Input() public hideProgress: boolean = false;
  @Input() public easyCLA: boolean = false;
  @Input() public progressAndStatus: ProgressAndStatus;
  @Input() public itxProgressAndStatus: ITXProgressAndStatus;
  @Input() public type: 'itx' | 'setup' = 'setup';
  @Input() public extraCrumbs: string = '';

  public constructor() {}

  public ngOnInit(): void {}
}
