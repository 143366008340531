import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@environments/environment';
import { CACHE, CacheModule } from '@ngx-cache/core';
import { MemoryCacheService } from '@ngx-cache/platform-browser';
import { CacheService } from '@providers/cache-service.provider';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MetaService } from './shared/services/meta.service';
import { ProjectService } from './shared/services/project.service';
import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandler } from './shared/utils/globalErrorHandler';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    RouterModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      progressBar: false,
      closeButton: true,
      timeOut: 30000,
      enableHtml: true,
      messageClass: 'toast-message',
      tapToDismiss: false,
      extendedTimeOut: 5000
    }),
    SharedModule,
    NgxLinkifyjsModule.forRoot(),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      redirectUri: environment.auth0.redirectUrl,
      audience: environment.auth0.audience,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      useCookiesForTransactions: true,
      scope: 'access:api openid email profile',
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}/*`,
            tokenOptions: {
              // The attached token should target this audience
              redirect_uri: environment.auth0.redirectUrl,
              audience: environment.auth0.audience,
              scope: 'access:api openid email profile',
              detailedResponse: environment.environment === 'dev'
            }
          }
        ]
      }
    }),
    CacheModule.forRoot()
  ],
  providers: [
    ProjectService,
    CacheService,
    MetaService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: CACHE, useClass: MemoryCacheService, deps: [PLATFORM_ID] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
