import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {
  @Input() public text: string;
  @Input() public hideIcon: boolean = false;
  @Input() public loading: boolean = false;

  @Output() public readonly delete: EventEmitter<string> = new EventEmitter();

  public constructor() {}

  public ngOnInit(): void {}

  public onDelete(): void {
    this.delete.emit(this.text);
  }
}
