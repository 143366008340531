/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { isSameDay } from 'date-fns';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnChanges {
  @ViewChild('time') public time: ElementRef<HTMLInputElement>;
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';
  @Input() public maxHours: number = 24;
  @Input() public minDateTime: DateTime = DateTime.now().startOf('day');
  @Input() public basicTimes: boolean = true;

  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public originalValue: string;
  public inputControl: AbstractControl;
  public times: { label: string; value: string }[] = [];
  public filteredTimes: Observable<{ label: string; value: string }[]>;
  public searchValue: string = '';
  public optionSelected: boolean = false;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.resetTime();
    this.inputControl = this.parent.get(this.control) as AbstractControl;
    this.originalValue = this.inputControl.value;

    this.inputControl.valueChanges.subscribe((value) => {
      this.searchValue = value;
      this.times = [];
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.minDateTime && !changes.minDateTime.firstChange) {
      this.resetTime();
    }
  }

  public onClick(event: MouseEvent, auto: MatAutocomplete): void {
    if (auto) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      auto._setVisibility;
      (auto.panel.nativeElement as HTMLElement).parentElement?.classList.add('time-picker');
    }

    (event.target as HTMLInputElement).select();
  }

  public onSelect(select: MatAutocompleteSelectedEvent): void {
    this.inputControl.setValue(select.option.value);
    this.optionSelected = true;
    this.searchValue = '';
    this.resetTime();
    this.getErrorMessage();
    this.time.nativeElement.blur();
  }

  public emitTimeSelection(): void {
    this.formGroupChange.emit(this.inputControl.value);
  }

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return (
        (this.inputControl.touched || this.inputControl.dirty) &&
        (this.inputControl.errors || (this.inputControl.hasError('required') && this.inputControl.value === ''))
      );
    }

    return false;
  }

  public onBlur(): void {
    setTimeout(() => {
      const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(amAM|pmPM)$/i;
      const originalTime = this.inputControl.value.trim().toLowerCase();
      let value = this.inputControl.value.trim().toLowerCase();
      const amPmRegex = new RegExp(/(am|pm)/i);
      let amPmMatch = amPmRegex.test(value) ? value.match(amPmRegex)[0] : 'pm';

      // TODO: Implement if needed for allowing extra characters
      // const suffix = /^(?:0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:am|pm)(\s-\s)?(([1-9]?[0-2]\/[1-3]?[0-9])?\s?\([1-9][0]?\s(hr|mins)\))/i;

      value = value.replace(/\D+/g, '').match(/^\d{4}$/)
        ? value.replace(/\D+/g, '')
        : originalTime[1] === ':'
        ? value.replace(/\D+/g, '').substring(0, 3)
        : value.replace(/\D+/g, '').substring(0, 4);
      const draftValue = value;

      if (value.substring(0, 1) === '0') {
        amPmMatch = amPmMatch ? amPmMatch : 'am';
        this.inputControl.setValue(value.substring(1));
        value = this.inputControl.value.trim();
      }

      if (parseInt(value.substring(0, 2), 10) === 24 && (value.length === 2 || value.length === 4)) {
        amPmMatch = 'am';
      }

      if (!timeRegex.test(value)) {
        if ((value.trim().length === 2 || value.trim().length === 4) && parseInt(value.substring(0, 2), 10) > 12 && parseInt(value.substring(0, 2), 10) <= 24) {
          this.inputControl.setValue(`${parseInt(value.substring(0, 2), 10) - 12}${parseInt(value.substring(0, 2), 10) === 24 ? 'am' : 'pm'}`);
          value = this.inputControl.value.trim();
        } else {
          this.errorMessage = 'Invalid time';
          this.inputControl.setErrors({ invalidTime: true });
        }

        value = value.replace(amPmRegex, '').trim();

        if (value === '') {
          this.inputControl.setValue(this.originalValue);
          this.resetTime();
        }

        if (!value.includes(':')) {
          if (value.length <= 2 && parseInt(value.substring(0, 2), 10) <= 12) {
            this.inputControl.setValue(`${value}:${draftValue.substring(2, 5) || '00'} ${amPmMatch}`);
          } else if (value.length === 3) {
            this.inputControl.setValue(`${value.substring(0, 1)}:${value.substring(1, 3)} ${amPmMatch}`);
          } else if (value.length === 4 && parseInt(value.substring(0, 2), 10) <= 12) {
            this.inputControl.setValue(`${value.substring(0, 2)}:${value.substring(2, 4)} ${amPmMatch}`);
          } else if (value.length === 5) {
            this.inputControl.setValue(`${value.substring(0, 2)}:${value.substring(2, 5)} ${amPmMatch}`);
          } else if (value.length === 6) {
            this.inputControl.setValue(`${value.substring(0, 1)}:${value.substring(1, value.length)} ${amPmMatch}`);
          }
          this.resetTime();
        } else {
          this.inputControl.setValue(`${value}00 ${amPmMatch}`);
          this.resetTime();
        }
      }
      this.getErrorMessage();
    }, 200);
  }

  public getErrorMessage(): string {
    this.errorMessage = '';

    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(am|pm)$/;
    // TODO: Implement if needed for allowing extra characters
    // const suffix = /^(?:0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:am|pm)(\s-\s)?(([1-9]?[0-2]\/[1-3]?[0-9])?\s?\([1-9][0]?\s(hr|mins)\))/i;

    const value = this.inputControl.value.trim();
    if (value && !timeRegex.test(value)) {
      this.errorMessage = 'Invalid time';
      this.inputControl.setErrors({ invalidTime: true });
    } else {
      this.inputControl.setErrors(null);
    }

    return this.errorMessage;
  }

  private normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  private generateBasicTimes(): { label: string; value: string }[] {
    const times: { label: string; value: string }[] = [];
    let tempDateTime = this.minDateTime;

    for (let i = 0; i < this.maxHours * 60; i += 15) {
      times.push({
        label: `${tempDateTime.toFormat('h:mm a').toLocaleLowerCase()}`,
        value: tempDateTime.toFormat('h:mm a').toLocaleLowerCase()
      });
      tempDateTime = tempDateTime.plus({ minutes: 15 });
    }

    return times;
  }

  private generateTimeDifference(minDateTime: DateTime): { label: string; value: string }[] {
    const times: any[] = [];

    if (!minDateTime.isValid) {
      return times;
    }

    let tempDateTime = minDateTime;
    let increment: 15 | 30 | 60 = 15;

    for (let i = 0; i < this.maxHours * 60; i += increment) {
      increment = i <= 15 ? 15 : i < 120 ? 30 : 60;
      tempDateTime = tempDateTime.plus({ minutes: increment });
      const minutes = i <= 15 ? `${tempDateTime.diff(minDateTime, 'minutes').as('minutes')} mins` : `${tempDateTime.diff(minDateTime, 'hours').as('hours')} hr`;
      if (isSameDay(minDateTime.toJSDate(), tempDateTime.toJSDate())) {
        times.push({
          label: `${tempDateTime.toFormat('h:mm a').toLocaleLowerCase()} (${minutes})`,
          value: tempDateTime.toFormat('h:mm a').toLocaleLowerCase()
        });
      } else {
        times.push({
          label: `${minDateTime.plus({ minutes: i }).toFormat('h:mm a').toLocaleLowerCase()} - ${minDateTime.toFormat('M/d')} (${minutes})`,
          value: tempDateTime.toFormat('h:mm a').toLocaleLowerCase()
        });
      }
    }

    return times;
  }

  private resetTime(): void {
    this.times = this.basicTimes ? this.generateBasicTimes() : this.generateTimeDifference(this.minDateTime);
  }
}
