<div class="input-item" [class.full-width]="fullWidth" [formGroup]="parent">
  <mat-label>
    {{label}}<span *ngIf="required" class="required">*</span>
    <i *ngIf="tooltip" [ngClass]="tooltipIcon" [ngxTippy]="tooltip" [tippyProps]="tippyProps"
      tippyClassName="lfx-tooltip"></i>
  </mat-label>
  <input matInput [readonly]="readonly" [matDatepicker]="picker" [class.error]="parent.hasError(control)"
    placeholder="{{placeholder}}" [formControlName]="control" (click)="picker.open()">
  <span *ngIf="errorMessage || parent.hasError(control)">{{ errorMessage || getErrorMessage() }}</span>
  <mat-datepicker #picker></mat-datepicker>
</div>
