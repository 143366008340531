import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from 'chart.js';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-meeting-doughnut-chart',
  templateUrl: './meeting-doughnut-chart.component.html',
  styleUrls: ['./meeting-doughnut-chart.component.scss']
})
export class MeetingDoughnutChartComponent implements OnInit {
  @Input() public data: number[] = [];
  @Input() public tooltip: string;
  @Input() public participantsInvitedToMeetingsCount: number | null;

  public chartData: ChartData;

  public options: any = {
    responsive: true,
    cutout: '85%',
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    maintainAspectRatio: false,
    datasets: {
      doughnut: {
        circumference: 360
      }
    }
  };

  public tippyProps: NgxTippyProps = { placement: 'bottom', arrow: false, allowHTML: true };

  public constructor() {}

  public ngOnInit(): void {
    this.chartData = {
      datasets: [
        {
          data: this.data,
          fill: false,
          backgroundColor: ['#FC5C5C', '#FBBC6C', '#A4D483'],
          borderWidth: 0
        }
      ]
    };
  }

  // public get getTotal(): number {
  //   return this.data.reduce((a, b) => a + b, 0);
  // }

  public getTooltipInfo(): string {
    return this.tooltip;
  }
}
