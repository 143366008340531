import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';
import { TooltipPosition } from '@angular/material/tooltip';
import { tippyProperties } from '@app/shared/utils/constants';
import { UntilDestroy } from '@ngneat/until-destroy';
import _ from 'lodash';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnChanges {
  @Input() public parent!: FormGroup;
  @Input() public control!: string;
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public type: 'input' | 'textarea' | 'password' = 'input';
  @Input() public rows: number = 2;
  @Input() public tabIndex: number = 1;
  @Input() public errorMessage: string = '';
  @Input() public fullWidth: boolean = false;
  @Input() public tooltip: string = '';
  @Input() public tooltipIcon: string = 'fas fa-info-circle';
  @Input() public labelSubText: string = '';
  @Input() public toolTipPosition: TooltipPosition = 'right';
  @Input() public validMessage: string = '';
  @Input() public showClear: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public showValidationIcon: boolean = true;
  @Input() public showSearchIcon: boolean = false;
  @Input() public autocompleteRef: MatAutocomplete;
  @Input() public autocompleteDisabled: boolean = false;
  @Input() public ngxTippy: string = '';
  @Input() public tippyProps: NgxTippyProps = { ...tippyProperties, placement: 'right' };
  @Input() public icon: string;
  @Input() public datepickerRef: MatDatepicker<any>;
  @Input() public autofocus: boolean = false;
  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() public readonly inputFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() public readonly focusOut: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  @ViewChild('name', { static: false }) public inputName: ElementRef<HTMLElement>;
  @ViewChild('input') public input: ElementRef;

  public inputControl: AbstractControl;

  public constructor(private renderer: Renderer2) {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }
    this.inputControl = this.parent.get(this.control) as AbstractControl;
  }

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return (
        (this.inputControl.touched || this.inputControl.dirty) &&
        (this.inputControl.errors || (this.inputControl.hasError('required') && this.inputControl.value === ''))
      );
    }

    return false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Set focus to input element
    if (changes.autofocus.currentValue) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 10);
    } else {
      setTimeout(() => {
        this.input.nativeElement.blur();
      }, 10);
    }
  }

  public showClearButton(): boolean {
    return this.showClear && !_.isEmpty(this.inputControl.value);
  }

  public clear(): void {
    this.inputControl.markAsDirty();
    this.inputControl.setValue('');
    this.inputControl.updateValueAndValidity();
    this.formGroupChange.emit(this.parent);
  }

  public getErrorMessage(control: AbstractControl): string {
    return control.dirty && control.hasError('required')
      ? 'This field is required.'
      : control.hasError('maxlength')
      ? 'Max character length exceeded'
      : control.hasError('minlength')
      ? 'Enter more characters to fit the limit'
      : control.hasError('file')
      ? 'Please upload a valid file'
      : control.hasError('url')
      ? 'Please enter a valid URL including http/https protocol'
      : control.hasError('email')
      ? 'Please enter a valid email'
      : control.hasError('exists')
      ? 'Value already exists'
      : control.hasError('numeric')
      ? 'Value needs to be numeric'
      : control.hasError('fullName')
      ? 'Please enter a valid first and last name'
      : control.hasError(this.control)
      ? control.getError(this.control)
      : 'Please enter a valid input';
  }

  public onFocus(event: Event): void {
    if (event) {
      const eventTarget = event.target as HTMLInputElement;
      this.inputFocus.emit(Boolean(eventTarget));
    } else {
      this.inputFocus.emit(false);
    }
  }

  public onClick(event: MouseEvent): void {
    this.clicked.emit(event);
  }

  public inputValueChange(): void {
    this.formGroupChange.emit(this.parent);
  }

  public focusInput(value: string): void {
    const element = this.renderer.selectRootElement(`#${value}`);
    _.delay(() => {
      element.focus();
    }, 0);
  }

  public onFocusout(focusEvent: FocusEvent): void {
    this.focusOut.emit(focusEvent);
  }
}
