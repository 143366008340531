<ngx-datatable class="cascading-table" [loadingIndicator]="dataLoading" rowHeight="auto" columnMode="flex"
  [treeFromRelation]="'ProjectParent'" [treeToRelation]="'Name'" [rows]="data" (treeAction)="onTreeAction($event)"
  [columns]="columns" [rowHeight]="'auto'" [rowClass]="getRowClass" [reorderable]="false"
  [messages]="{ emptyMessage: emptyMessage || 'No data to display' }" #table>
</ngx-datatable>

<app-loading *ngIf="dataLoading"></app-loading>

<!-- Default column configuration -->
<ng-template #nameDefaultColumn let-value="value" let-row="row">
  <a routerLink="/project/{{ row.ID }}">
    <ng-container>
      <div class="logo-name-container" fxLayout="row" fxLayoutAlign="start center">
        <div class="logo-container">
          <img src="{{ row.ProjectLogo }}" *ngIf="row.ProjectLogo" [ngxTippy]="row.Name" [tippyProps]="{arrow: false}">
          <span *ngIf="!row.ProjectLogo">{{ row.Name }}</span>
        </div>
      </div>
    </ng-container>
  </a>
</ng-template>
<ng-template #totalProjDefaultColumn let-value="value" let-row="row">
  {{ value ? value : "-" }}
</ng-template>
<ng-template #statusDefaultColumn let-value="value" prop="status">
  {{ value }}
</ng-template>
