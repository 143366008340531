import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() public searchEnabled: boolean = true;
  @Input() public searchPlaceholder: string = 'Search';
  @Input() public iconPlacement: string = 'start'; // or end
  @Input() public lightTheme: boolean = false;
  @Output() public readonly searched: EventEmitter<string> = new EventEmitter<string>();

  private subject: Subject<string> = new Subject();

  public constructor() {}

  public ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      this.searched.emit(searchTextValue);
    });
  }

  public onKeyUp(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.subject.next(target.value);
  }
}
