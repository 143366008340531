import { Component, Input, OnInit } from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-rectangle-count',
  templateUrl: './rectangle-count.component.html',
  styleUrls: ['./rectangle-count.component.scss']
})
export class RectangleCountComponent implements OnInit {
  @Input() public amount: string | null;
  @Input() public label: string;
  @Input() public tooltip: string;
  @Input() public icon: string;

  public tippyProps: NgxTippyProps = { placement: 'bottom', arrow: false, allowHTML: true };

  public constructor() {}

  public ngOnInit(): void {}
}
