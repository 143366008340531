<div class="organizations-list">
  <ngx-datatable #organizationTable rowHeight="auto" columnMode="flex" [rows]="rows" #orgTable
    [selectionType]="SelectionType.single" class="org-table" [headerHeight]="0" (activate)="onActivate($event)"
    [messages]="{ emptyMessage: emptyMessage}" [reorderable]="false" [selected]="getCurrentSelected()">
    <ngx-datatable-column [flexGrow]="1" [resizeable]="false" name="Organizations" [prop]="prop" cellClass="clickable"
      [sortable]="true">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
          <div ellipsis class="{{ row.connection_status }}">
            <i class="fas fa-circle {{ row.connection_status }}" [ngxTippy]="getOrgStatusTooltip(row.connection_status)"
              [tippyProps]="tippyProps" [attr.data-tooltip]="getOrgStatusTooltip(row.connection_status)"
              [tippyClassName]="row.connection_status"></i>
            <a ellipsis class="bold">{{ getName(value) }}</a>
          </div>
          <div>{{row.repositories && row.repositories.length || 0 }}</div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
