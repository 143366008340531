import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { UserPreference } from 'lfx-pcc';

@Component({
  selector: 'app-change-log-dialog',
  templateUrl: './change-log-dialog.component.html',
  styleUrls: ['./change-log-dialog.component.scss']
})
export class ChangeLogDialogComponent implements OnInit {
  public changeLogDate: string = environment.changeLogDate;
  public doNotShow: boolean = true;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userID: string; userPreference: UserPreference },
    private dialogRef: MatDialogRef<ChangeLogDialogComponent>
  ) {}

  public ngOnInit(): void {}

  public onClose(): void {
    this.dialogRef.close(this.doNotShow);
  }
}
