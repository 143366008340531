import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'lfx-pcc';
import prependHttp from 'prepend-http';

@Component({
  selector: 'app-detailed-header',
  templateUrl: './detailed-header.component.html',
  styleUrls: ['./detailed-header.component.scss']
})
export class DetailedHeaderComponent implements OnInit {
  @Input() public project: Project;

  public constructor() {}

  public ngOnInit(): void {}

  public get projectHaveWebsite(): boolean {
    return !!this.project.Website && this.project.Website !== 'nil';
  }

  public get websiteURL(): string {
    return this.project.Website ? prependHttp(this.project.Website) : '';
  }
}
