import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-toast-component',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent extends Toast {
  private currentURL: string;
  public constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private dialog: MatDialog) {
    super(toastrService, toastPackage);
    this.currentURL = window.location.href;
  }

  public openSupportDialog(): void {
    const currentURL = this.currentURL;
    this.dialog.open(ErrorDialogComponent, {
      data: { stackTrace: this.title as string, currentURL }
    });

    this.toastrService.clear();
  }

  public closeToast(): void {
    this.toastPackage.toastRef.close();
  }
}
