import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DELETE_CONFIRMATION_MSG } from '@app/shared/utils/constants';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      title: string;
      record: string;
      secondRecord?: string;
      buttonLabel: string;
      buttonLabelColor?: string;
      secondaryButtonLabel?: string;
      secondaryButtonColor?: string;
      tertiaryButtonLabel?: string;
      tertiaryButtonColor?: string;
      hideCloseButton?: boolean;
      buttonAlignment?: string;
      template?: TemplateRef<any>;
      customActions?: TemplateRef<any>;
      hideSecondaryButton?: boolean;
    },
    public dialogRef: MatDialogRef<DeleteDialogComponent>
  ) {
    if (!this.data || !this.data.message) {
      this.data.message = DELETE_CONFIRMATION_MSG.DEFAULT;
    }

    if (!this.data || !this.data.buttonAlignment) {
      this.data.buttonAlignment = 'end center';
    }
  }

  public ngOnInit(): void {}

  public get buttonCustomColor(): string {
    return this.data.buttonLabelColor ? `lfx-button ${this.data.buttonLabelColor}` : 'lfx-button blue';
  }

  public get secondaryButtonLabel(): string {
    return this.data.secondaryButtonLabel ? this.data.secondaryButtonLabel : 'No';
  }

  public get secondaryButtonColor(): string {
    return this.data.secondaryButtonColor ? this.data.secondaryButtonColor : 'white';
  }

  public get tertiaryButtonColor(): string {
    return this.data.tertiaryButtonColor ? this.data.tertiaryButtonColor : 'grey';
  }

  public confirmDelete(): void {
    this.dialogRef.close('confirm action');
  }

  public cancel(): void {
    this.dialogRef.close('cancel action');
  }

  public exit(): void {
    this.dialogRef.close('exit action');
  }
}
