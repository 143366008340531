import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { Subject } from 'rxjs';
import validator from 'validator';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { tippyProperties } from '@utils/constants';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public value: string = '';
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';
  @Input() public validMessage: string = '';
  @Input() public fullWidth: boolean = false;
  @Input() public acceptableTypes: string[] = [];
  @Input() public showDelete: boolean = false;
  @Input() public hideView: boolean = false;
  @Output() public readonly delete: any = new EventEmitter<any>();
  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() public readonly fileChange: EventEmitter<File> = new EventEmitter<File>();

  public inputControl: AbstractControl;
  public fileName: string;
  public tippyProps: NgxTippyProps = {
    ...tippyProperties,
    onTrigger: (instance) => {
      instance.setContent(instance.reference.attributes.getNamedItem('ng-reflect-ngx-tippy')?.value as string);
    }
  };
  private subject: Subject<File> = new Subject();

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;

    this.subject.pipe(untilDestroyed(this)).subscribe((fileValue) => {
      this.setControlValue(fileValue);
    });

    this.getFileNameFromUrl();
  }

  public fileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files) {
      this.fileChange.emit(inputElement.files[0] as File);
      this.subject.next(inputElement.files[0] as File);
    }
  }

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return this.inputControl.errors || (this.inputControl.touched && this.inputControl.hasError('required') && this.inputControl.value === '');
    }

    return false;
  }

  public inputDisabled(): boolean {
    if (this.inputControl) {
      return this.inputControl.disabled;
    }

    return false;
  }

  public selectFile(control: string): void {
    const input = document.querySelector('input.' + control) as HTMLInputElement;
    input.value = '';
    input.click();
  }

  public viewFile(): void {
    if (this.value !== '') {
      window.open(this.value, '_blank');
    }
  }

  public get canView(): boolean {
    return !_.isEmpty(this.value) && typeof this.value === 'string' && validator.isURL(this.value);
  }

  public get acceptTypes(): string {
    return this.acceptableTypes.join(',.');
  }

  public getErrorMessage(control: AbstractControl): string {
    return control.hasError('required')
      ? 'This field is required.'
      : control.hasError('maxlength')
      ? 'Value cannot exceed 150 characters'
      : control.hasError('file')
      ? 'Please upload a valid file'
      : control.hasError('url')
      ? 'Please enter a valid URL including https/http protocol'
      : control.hasError('email')
      ? 'Please enter a valid email'
      : control.hasError('exists')
      ? 'Value already exists'
      : control.hasError('numeric')
      ? 'Value needs to be numeric'
      : control.hasError('fileSize')
      ? control.errors?.fileSize
      : control.hasError(this.control)
      ? control.getError(this.control)
      : 'Please select a valid file';
  }

  public removeFile(control: string): void {
    const currentControl = this.parent.get(control);
    if (currentControl) {
      this.delete.emit(this.fileName);
      this.fileName = '';
      this.inputControl = currentControl;
      this.inputControl.setValue('');
      this.inputControl.updateValueAndValidity();
    }
  }

  public getFileNameFromUrl(): void {
    if (this.value) {
      this.fileName = this.value.split('/').pop() || '';
    }
  }

  public get getTooltipText(): string {
    return !_.isEmpty(this.fileName) ? 'Replace File' : 'Upload File';
  }

  private setControlValue(value: File): void {
    this.fileName = value.name;
    const currentControl = this.parent.get(this.control);
    if (currentControl) {
      this.inputControl = currentControl;
      this.inputControl.setValue(value);
      this.inputControl.updateValueAndValidity();
    }
  }
}
