import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CacheService } from '@providers/cache-service.provider';
import {
  CLACompany,
  CLACorpProjectContributorsResponse,
  CLACreateGitLabOrganization,
  CLACreateGroup,
  CLADownloadURL,
  CLAEventsResponse,
  CLAGroup,
  CLAGroupDetails,
  CLAGroupUpdate,
  CLANewProjectGithubOrg,
  CLAProjectGerritInstancePayload,
  CLAProjectGithubOrgPayload,
  CLAProjectGithubOrgResponse,
  CLAProjectGithubRepoConfigPayload,
  CLAProjectGithubRepoPayload,
  CLAProjectGithubRepositoriesResponse,
  CLAProjectGitLabGroupResponse,
  CLAProjectGroupGerrit,
  CLAProjectGroupGerritResponse,
  CLAProjectSignaturesResponse,
  CLASignedDocument,
  CLATemplate,
  CLATemplateFields,
  CLAUpdateGitLabOrganization,
  CLAUpdateGitLabRepositories,
  ICLASignaturesResponse,
  Permission,
  PermissionsCheck,
  ValidateCLAGroup
} from 'lfx-pcc';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { UserService } from './user.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class ClaService {
  public projectPermissions: Permission;

  private claServicesAPI = environment.apiUrl + '/cla-services';
  private urls = {
    claServices: {
      groups: `${this.claServicesAPI}/cla-groups`,
      group: `${this.claServicesAPI}/cla-group`,
      gerrit: `${this.claServicesAPI}/gerrit`,
      groupTemplate: `${this.claServicesAPI}/clagroup`,
      events: `${this.claServicesAPI}/events`,
      signatures: `${this.claServicesAPI}/signatures`,
      company: `${this.claServicesAPI}/company`,
      validateCla: `${this.claServicesAPI}/validate-cla-group`,
      template: `${this.claServicesAPI}/template`,
      previewTemplate: `${this.claServicesAPI}/preview-template`,
      createClaGroup: `${this.claServicesAPI}/create-group`,
      project: `${this.claServicesAPI}/project`
    }
  };

  public constructor(private http: HttpClient, private readonly userService: UserService, private cacheService: CacheService) {}

  public getClaGroups(projectId: string): Observable<CLAGroup[]> {
    return this.http.get<CLAGroup[]>(`${this.urls.claServices.groups}/${projectId}`);
  }

  public putClaGroup(groupId: string, updateData: CLAGroupUpdate): Observable<CLAGroup> {
    return this.http.put<CLAGroup>(`${this.urls.claServices.group}/${groupId}`, updateData);
  }

  public putClaGroupProject(groupId: string, projectIds: string[], action: 'enroll-projects' | 'unenroll-projects'): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.claServices.group}/${groupId}/${action}`, projectIds, { observe: 'response' });
  }

  public getClaPermissions(projectId: string): Observable<Permission[]> {
    return this.userService.getProjectPermissions(this.getPermissions(), projectId);
  }

  public getClaEvents(projectId: string, eventType: string, params?: HttpParams): Observable<CLAEventsResponse> {
    return this.http.get<CLAEventsResponse>(`${this.urls.claServices.events}/${eventType}/${projectId}`, { params });
  }

  public getClaEventsCSV(projectId: string, eventType: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.urls.claServices.events}/${eventType}/${projectId}/csv`);
  }

  public getIClaSignatures(claGroupId: string, params?: HttpParams): Observable<ICLASignaturesResponse> {
    return this.http.get<ICLASignaturesResponse>(`${this.urls.claServices.group}/${claGroupId}/icla/signatures`, { params });
  }

  public putIClaSignatureValidate(claGroupId: string, userID: string): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.claServices.group}/${claGroupId}/user/${userID}/icla`, {}, { observe: 'response' });
  }

  public getCClaSignatures(claGroupId: string, params?: HttpParams): Observable<CLAProjectSignaturesResponse> {
    return this.http.get<CLAProjectSignaturesResponse>(`${this.urls.claServices.signatures}/project/${claGroupId}`, { params });
  }

  public getCClaSignedDocument(signatureId: string): Observable<CLASignedDocument> {
    return this.http.get<CLASignedDocument>(`${this.urls.claServices.signatures}/${signatureId}/signed-document`);
  }

  public getCorporateClaPDFs(claGroupId: string, claType: 'ccla' | 'icla'): Observable<CLADownloadURL> {
    return this.http.get<CLADownloadURL>(`${this.urls.claServices.signatures}/project/${claGroupId}/${claType}/pdfs`);
  }

  public getClaCorporateInfoCSV(claGroupId: string, claType: 'ccla' | 'icla'): Observable<string> {
    // 'text' as 'json' was added because the endpoint returns plain text and not json. Type casting was need because
    // responseType complains that 'text' is not allowed
    return this.http.get<string>(`${this.urls.claServices.signatures}/project/${claGroupId}/${claType}/csv`, { responseType: 'text' as 'json' });
  }

  public getCompanyByName(companyName: string, params?: HttpParams): Observable<CLACompany> {
    return this.http.get<CLACompany>(`${this.urls.claServices.company}/name/${companyName}`, { params });
  }

  public getCorporateProjectContributors(claGroupId: string, params?: HttpParams): Observable<CLACorpProjectContributorsResponse> {
    return this.http.get<CLACorpProjectContributorsResponse>(`${this.urls.claServices.group}/${claGroupId}/contributors`, { params });
  }

  public postValidateClaGroup(claData: CLAGroupDetails, params?: HttpParams): Observable<ValidateCLAGroup> {
    return this.http.post<ValidateCLAGroup>(`${this.urls.claServices.validateCla}`, claData, { params });
  }

  public getTemplates(params?: HttpParams): Observable<CLATemplate[]> {
    return this.http.get<CLATemplate[]>(`${this.urls.claServices.template}`, { params });
  }

  public postPreviewTemplate(templateData: CLATemplateFields, params?: HttpParams): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(`${this.urls.claServices.previewTemplate}`, templateData, {
      params,
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  public postNewClaTemplate(templateData: CLATemplateFields, claGroupId: string): Observable<{ [key: string]: string }> {
    return this.http.post<{ [key: string]: string }>(`${this.urls.claServices.groupTemplate}/${claGroupId}/template`, templateData);
  }

  public postCreateClaGroup(groupData: CLACreateGroup, params?: HttpParams): Observable<any> {
    return this.http.post<CLAGroup>(`${this.urls.claServices.createClaGroup}`, groupData, { params });
  }

  public getProjectGithubOrganizations(projectId: string, params?: HttpParams): Observable<CLAProjectGithubOrgResponse> {
    const githubOrgs = this.http.get<CLAProjectGithubOrgResponse>(`${this.urls.claServices.project}/${projectId}/github/organizations`, { params });
    return this.response(`cla-services-github-org-${projectId}`, githubOrgs);
  }

  public postProjectGithubOrganization(projectId: string, orgData: CLAProjectGithubOrgPayload): Observable<CLANewProjectGithubOrg> {
    return this.http.post<CLANewProjectGithubOrg>(`${this.urls.claServices.project}/${projectId}/github/organizations`, orgData);
  }

  public postProjectGithubRepositories(projectId: string, orgData: CLAProjectGithubRepoPayload): Observable<CLAProjectGithubRepositoriesResponse> {
    return this.http.post<CLAProjectGithubRepositoriesResponse>(`${this.urls.claServices.project}/${projectId}/github/repositories`, orgData);
  }

  public deleteProjectGithubOrganization(projectId: string, orgName: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.claServices.project}/${projectId}/github/organizations/${orgName}`, { observe: 'response' });
  }

  public deleteProjectGithubRepositories(projectId: string, repoId: string | number, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.claServices.project}/${projectId}/github/repositories/${repoId}`, { params, observe: 'response' });
  }

  public deleteProjectGerritInstance(claGroupId: string, projectId: string, gerritId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.claServices.group}/${claGroupId}/project/${projectId}/gerrit/${gerritId}`, { observe: 'response' });
  }

  public putProjectGithubOrganizationConfig(projectId: string, orgName: string, orgData: CLAProjectGithubRepoConfigPayload): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.claServices.project}/${projectId}/github/organizations/${orgName}/config`, orgData, { observe: 'response' });
  }

  public postProjectGerritInstance(claGroupId: string, projectId: string, groupData: CLAProjectGerritInstancePayload): Observable<CLAProjectGroupGerrit> {
    return this.http.post<CLAProjectGroupGerrit>(`${this.urls.claServices.group}/${claGroupId}/project/${projectId}/gerrit`, groupData);
  }

  public getProjectClaGroupGerrit(claGroupId: string, projectId: string, params?: HttpParams): Observable<CLAProjectGroupGerritResponse> {
    const gerritGroups = this.http.get<CLAProjectGroupGerritResponse>(`${this.urls.claServices.groups}/${claGroupId}/project/${projectId}/gerrit`, {
      params
    });
    return this.response(`cla-services-gerrit-groups-${projectId}`, gerritGroups);
  }

  public getProjectClaGroupGerritRepos(projectId: string, params?: HttpParams): Observable<any> {
    const claGerritRepos = this.http.get<any>(`${this.urls.claServices.gerrit}/repos`, { params });
    return this.response(`cla-services-gerrit-repos-${projectId}`, claGerritRepos);
  }

  public getProjectCLAGitlabGroupsRepos(projectId: string, params?: HttpParams): Observable<any> {
    const claGitlabRepos = this.http.get<any>(`${this.urls.claServices.project}/${projectId}/gitlab/repositories`, { params });
    return this.response(`cla-services-gitlab-repos-${projectId}`, claGitlabRepos);
  }

  public getProjectCLAGitlabGroups(projectID: string, params?: HttpParams): Observable<CLAProjectGitLabGroupResponse> {
    const claGitlabGroups = this.http.get<CLAProjectGitLabGroupResponse>(`${this.urls.claServices.project}/${projectID}/gitlab/organizations`, { params });
    return this.response(`cla-services-gitlab-groups-${projectID}`, claGitlabGroups);
  }

  public postProjectCLAGitlabGroups(projectID: string, groupData: CLACreateGitLabOrganization): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.claServices.project}/${projectID}/gitlab/organizations`, groupData, { observe: 'response' });
  }

  public putProjectCLAGitlabGroups(
    projectID: string,
    groupID: number,
    groupData: CLAUpdateGitLabOrganization,
    params?: HttpParams
  ): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.claServices.project}/${projectID}/gitlab/group/${groupID}/config`, groupData, { params, observe: 'response' });
  }

  public deleteProjectCLAGitlabGroups(projectID: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.claServices.project}/${projectID}/gitlab/organization`, { params, observe: 'response' });
  }

  public putProjectCLAGitlabRepositories(projectID: string, repoData: CLAUpdateGitLabRepositories): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.claServices.project}/${projectID}/gitlab/repositories`, repoData, { observe: 'response' });
  }

  private response(cacheKey: string, observable: Observable<any>) {
    if (this.cacheService.has(cacheKey)) {
      return this.cacheService.get(cacheKey);
    }

    const res = observable.pipe(shareReplay(1));
    this.cacheService.set(cacheKey, res);
    return res;
  }

  private getPermissions(): PermissionsCheck[] {
    const permissions: PermissionsCheck[] = [
      { resource: 'cla_group', action: 'view', objectType: 'project' },
      { resource: 'cla_group_create', action: 'create', objectType: 'project' },
      { resource: 'cla_groups_in_foundation', action: 'view_all', objectType: 'project' },
      { resource: 'foundation_events', action: 'view_all', objectType: 'project' },
      { resource: 'foundation_events_csv', action: 'view_all', objectType: 'project' },
      { resource: 'delete_cla_group', action: 'remove', objectType: 'project' },
      { resource: 'enroll_projects', action: 'update', objectType: 'project' },
      { resource: 'project_signatures', action: 'view_all', objectType: 'project' },
      { resource: 'signed_signature_document', action: 'view_all', objectType: 'project' }
    ];
    return permissions;
  }
}
