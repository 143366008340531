import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asterisk'
})
export class AsteriskPipe implements PipeTransform {
  public transform(value: string, transform: boolean): string {
    return (typeof value === 'string' && transform && value.replace(/./g, '*')) || value;
  }
}
