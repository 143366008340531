import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule, RippleGlobalOptions } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { InputSelectMultipleComponent } from '@shared/components/input-select-multiple/input-select-multiple.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ArcElement, CategoryScale, Chart, DoughnutController, Legend, Title, Tooltip } from 'chart.js';
import { ColorPickerModule } from 'ngx-color-picker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastrService } from 'ngx-toastr';

import { EasyClaGuard } from '@shared/guards/easy-cla.guard';
import { AddProjectComponent } from './components/add-project/add-project.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonToggleGroupComponent } from './components/button-toggle-group/button-toggle-group.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CascadingTableComponent } from './components/cascading-table/cascading-table.component';
import { ChangeLogDialogComponent } from './components/change-log-dialog/change-log-dialog.component';
import { CircleCountComponent } from './components/circle-count/circle-count.component';
import { ConfigurationStatusComponent } from './components/configuration-status/configuration-status.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { DetailedHeaderComponent } from './components/detailed-header/detailed-header.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { InputColorPickerComponent } from './components/input-color-picker/input-color-picker.component';
import { InputDatePickerComponent } from './components/input-date-picker/input-date-picker.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { InputSlideToggleComponent } from './components/input-slide-toggle/input-slide-toggle.component';
import { InputComponent } from './components/input/input.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MeetingDoughnutChartV2Component } from './components/meeting-doughnut-chart-v2/meeting-doughnut-chart-v2.component';
import { MeetingDoughnutChartComponent } from './components/meeting-doughnut-chart/meeting-doughnut-chart.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { NgxDatatableComponent } from './components/ngx-datatable/ngx-datatable.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PillComponent } from './components/pill/pill.component';
import { RectangleCountComponent } from './components/rectangle-count/rectangle-count.component';
import { SectionHeaderLightComponent } from './components/section-header-light/section-header-light.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableFooterComponent } from './components/table-footer/table-footer.component';
import { TagComponent } from './components/tag/tag.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ToastComponent } from './components/toast-component/toast.component';
import { TreeViewTableComponent } from './components/tree-view-table/tree-view-table.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { ProjectOnboardedGuard } from './guards/project-onboarded.guard';
import { AsteriskPipe } from './pipes/asterisk.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DatePipe } from './pipes/date.pipe';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { UserService } from './services/user.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchUserComponent } from './components/search-user/search-user.component';
import { GerritInstancesComponent } from './components/gerrit-instances/gerrit-instances.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};

const maskConfig: Partial<IConfig> = {
  validation: false
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatAutocompleteModule,
    NgxDatatableModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatCardModule,
    MatIconModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    ColorPickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatMenuModule,
    NgxTippyModule,
    MatCheckboxModule,
    ClipboardModule,
    MatChipsModule,
    NgxSkeletonLoaderModule,
    NgxChartsModule,
    NgbModalModule,
    ChartjsModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  declarations: [
    SectionHeaderComponent,
    LoadingComponent,
    DetailedHeaderComponent,
    SidebarComponent,
    CascadingTableComponent,
    BreadcrumbComponent,
    PageHeaderComponent,
    ConfigurationStatusComponent,
    DatePipe,
    CapitalizePipe,
    AsteriskPipe,
    InputComponent,
    AddProjectComponent,
    DeleteDialogComponent,
    TitleCasePipe,
    InputSlideToggleComponent,
    MessageDialogComponent,
    InputFileComponent,
    InputSelectComponent,
    InputSelectMultipleComponent,
    InputColorPickerComponent,
    InputSearchComponent,
    ButtonToggleGroupComponent,
    InputDatePickerComponent,
    ErrorMessageComponent,
    PageNotFoundComponent,
    TreeViewTableComponent,
    ToastComponent,
    ErrorDialogComponent,
    EllipsisDirective,
    CalendarComponent,
    CircleCountComponent,
    SectionHeaderLightComponent,
    InputSelectMultipleComponent,
    InputSelectComponent,
    OrganizationsListComponent,
    AlertMessageComponent,
    TableFooterComponent,
    PillComponent,
    TimePickerComponent,
    NgxDatatableComponent,
    AutoFocusDirective,
    TagComponent,
    MeetingDoughnutChartComponent,
    ChangeLogDialogComponent,
    RectangleCountComponent,
    MeetingDoughnutChartV2Component,
    PaginationComponent,
    SearchUserComponent,
    GerritInstancesComponent
  ],
  exports: [
    SectionHeaderComponent,
    LoadingComponent,
    DetailedHeaderComponent,
    SidebarComponent,
    CascadingTableComponent,
    TreeViewTableComponent,
    InputSlideToggleComponent,
    BreadcrumbComponent,
    PageHeaderComponent,
    InputComponent,
    InputFileComponent,
    InputSelectComponent,
    InputColorPickerComponent,
    InputSearchComponent,
    ToastComponent,
    FlexLayoutModule,
    DatePipe,
    CapitalizePipe,
    AsteriskPipe,
    TitleCasePipe,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    NgxSpinnerModule,
    NgxTippyModule,
    NgxDatatableModule,
    NgxChartsModule,
    NgxSkeletonLoaderModule,
    ButtonToggleGroupComponent,
    InputDatePickerComponent,
    ErrorMessageComponent,
    EllipsisDirective,
    InputSelectComponent,
    InputSelectMultipleComponent,
    CalendarComponent,
    AlertMessageComponent,
    CircleCountComponent,
    RectangleCountComponent,
    SectionHeaderLightComponent,
    TableFooterComponent,
    PillComponent,
    EllipsisDirective,
    TimePickerComponent,
    AutoFocusDirective,
    OrganizationsListComponent,
    NgxDatatableComponent,
    TagComponent,
    MeetingDoughnutChartComponent,
    MeetingDoughnutChartV2Component,
    ReactiveFormsModule,
    PaginationComponent,
    SearchUserComponent,
    GerritInstancesComponent
  ],
  providers: [
    UserService,
    MatDatepickerModule,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    ProjectOnboardedGuard,
    EasyClaGuard,
    ToastrService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  public constructor() {
    Chart.register(Title, Tooltip, Legend, CategoryScale, DoughnutController, ArcElement);
  }
}
