import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lfx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() public page = 1;
  @Input() public disabled = false;
  @Input() public disableNext = false;
  @Input() public disablePrev = false;
  @Input() public noNamedButtonsPagination = true;
  @Input() public hide = false;
  @Input() public showPageSize = false;
  @Input() public pageSize: number = 10;
  @Input() public pageSizeOptions: number[] = [0, 10, 20, 30, 50, 100];
  @Output() public readonly pageChange = new EventEmitter<number>(true);
  @Output() public readonly pageSizeChange = new EventEmitter<number>(true);

  public constructor() {}

  public ngOnInit(): void {}

  public sizeDisplayValue(size: number): string | number {
    return size === 0 ? 'All' : size;
  }

  public onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  public onPrevious(): void {
    if (!this.disablePrev) {
      this.pageChange.emit(0);
    }
  }

  public onNext(): void {
    if (!this.disableNext) {
      this.pageChange.emit(1);
    }
  }

  public onPageSizeChange(event: Event): void {
    this.pageSize = parseInt((event.target as HTMLInputElement).value, 10);
    this.pageSizeChange.emit(this.pageSize);
  }
}
