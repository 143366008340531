import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  public constructor(private router: Router, private toaster: ToastrService) {}

  public ngOnInit(): void {
    this.toaster.error('Redirecting to Dashboard', 'Page Not Found');
    this.router.navigate(['']);
  }
}
