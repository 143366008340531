<div class="section-header-light" fxLayout="column" fxLayoutGap="10px">
  <div *ngIf="infoTopRight" class="info-top-right" fxLayout="row nowrap" fxLayoutAlign="end start">
    <span>{{ infoTopRight }}</span>
  </div>
  <div [class]="infoTopRight ? '' : 'section-margin-top'" fxLayout="row nowrap" fxLayoutAlign="space-between center">
    <div class="section-details" fxLayout="column" fxLayoutGap="5px">
      <h2 *ngIf="title">{{ title }}</h2>
      <span *ngIf="description" [innerHTML]="description"></span>
    </div>
    <div class="call-to-actions" fxLayout="row nowrap" fxLayoutGap="10px" fxLayoutAlign="end center">
      <button mat-button *ngIf="buttonEnabled" class="lfx-button blue" (click)="buttonClicked()"
        [disabled]="!buttonEnabled">
        <i *ngIf="ctaIcon !== ''" [class]="ctaIcon" class="cta-icon"></i>
        {{ buttonTitle }}
      </button>
      <div class="search-wrapper" *ngIf="searchEnabled" [formGroup]="form" fxLayoutAlign="end end">
        <input type="text" class="section-header-search" [placeholder]="searchPlaceholder"
          [disabled]="disableSearchInput" formControlName="search">
        <i class="icon" class="fas fa-search"></i>
      </div>
      <div class="input-select-wrapper" *ngIf="selectEnabled">
        <app-input-select placeholder="Select Meeting" [options]="options" [hideDefaultOpt]="false"></app-input-select>
        <i class="icon" class="fas fa-search"></i>
      </div>
            <div *ngIf="customContainer">
              <ng-container *ngTemplateOutlet="ngTemplate"></ng-container>
            </div>
    </div>
  </div>
</div>
