<div class="inner-circle-text">
  <h4 data-cy="participants-invited-upcoming-meetings">{{ participantsInvitedToMeetingsCount || '-' }}</h4>
  <p>PARTICIPANTS INVITED TO UPCOMING MEETINGS</p>
  <i class="fas fa-info-circle" [ngxTippy]="getTooltipInfo()" [tippyProps]="tippyProps"
    tippyClassName="meeting-info-tooltip"></i>
</div>
<div fxLayout="row nowrap" fxLayoutGap="25px" fxLayoutAlign="start center">
  <ngx-chartjs type="doughnut" [data]="chartData" [options]="options" [height]="170" [width]="170" [redraw]="true">
  </ngx-chartjs>
  <div class="legend" fxLayout="column" fxLayoutGap="5px">
    <div class="legend-item" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="legend-value">
        <i class="fas fa-circle success"></i>
        <span>{{ data[2] }}</span>
      </div>
      <span>Attend over 75% of meetings</span>
    </div>
    <div class="legend-item" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="legend-value">
        <i class="fas fa-circle warning"></i>
        <span>{{ data[1] }}</span>
      </div>
      <span>Attend 50% to 75% of meetings</span>
    </div>
    <div class="legend-item" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="legend-value">
        <i class="fas fa-circle red"></i>
        <span>{{ data[0] }}</span>
      </div>
      <span>Attend under 50% of meetings</span>
    </div>
  </div>
</div>
