import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DistinctPermission,
  PaginatedResponseUser,
  Permission,
  PermissionsCheck,
  User,
  UserPermission,
  UserPreference,
  UserPreferencesPaginated,
  UserRoleResponse
} from 'lfx-pcc';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userServicesAPI = environment.apiUrl + '/user-services';
  private urls = {
    permissionsCheck: `${this.userServicesAPI}/me/permissions/checks`,
    permissions: `${this.userServicesAPI}/me/permissions`,
    users: `${this.userServicesAPI}/users`
  };
  private projectID: string;
  private permissions: Observable<Permission[]>;

  public constructor(private http: HttpClient, private authService: AuthService) {}

  public getProjectPermissions(permissions: PermissionsCheck[], id: string): Observable<Permission[]> {
    const body: string[] = [];

    if (id === this.projectID) {
      return this.permissions;
    }

    this.projectID = id;

    permissions.forEach((perm) => {
      body.push(`${perm.resource}:${perm.action}:${perm.objectType}:${perm.objectID || id}`);
    });

    this.permissions = this.http.post<Permission[]>(`${this.urls.permissionsCheck}`, body).pipe(shareReplay());
    return this.permissions;
  }

  public getUserPermissions(params?: HttpParams): Observable<UserPermission> {
    return this.http.get<UserPermission>(`${this.urls.permissions}`, { params });
  }

  public getPermissions(permissions: PermissionsCheck[]): Observable<Permission[]> {
    const body: string[] = [];

    permissions.forEach((perm) => {
      let str = `${perm.resource}:${perm.action}`;
      if (perm.objectType) {
        str = str.concat(`:${perm.objectType}`);
      }
      body.push(str);
    });

    this.permissions = this.http.post<Permission[]>(`${this.urls.permissionsCheck}`, body).pipe(
      shareReplay(),
      catchError((err) => {
        if (err.error === 'login_required' || err.error === 'consent_required') {
          this.authService.loginWithRedirect();
        }

        return of(err);
      })
    );

    return this.permissions;
  }

  public getUserPreferences(userId: string, params?: HttpParams): Observable<UserPreferencesPaginated> {
    const userPreferences = this.http.get<UserPreferencesPaginated>(`${this.urls.users}/${userId}/preferences`, { params });
    return userPreferences;
  }

  public createUserPreference(userId: string, data: Partial<UserPreference>): Observable<UserPreference> {
    return this.http.post<UserPreference>(`${this.urls.users}/${userId}/preference`, data);
  }

  public setUserPreference(userId: string, data: Partial<UserPreference>): Observable<UserPreference> {
    return this.http.patch<UserPreference>(`${this.urls.users}/${userId}/preference/${data.ID}`, data);
  }

  public convertPermissionsToObj(permissions: Permission[]): Permission {
    let permsObj: Permission = {};
    for (const [key, value] of Object.entries(permissions)) {
      const keyArray = key.split(':');
      permsObj = _.merge(permsObj, {
        [`${keyArray[0]}`]: {
          [`${keyArray[1]}`]: value
        }
      });
    }

    return permsObj;
  }

  public convertDistinctPermissionsToObj(permissions: Permission[]): DistinctPermission {
    let permsObj: DistinctPermission = {};
    for (const [key, value] of Object.entries(permissions)) {
      const keyArray = key.split(':');
      permsObj = _.merge(permsObj, {
        [`${keyArray[0]}`]: {
          [`${keyArray[3]}`]: {
            [`${keyArray[1]}`]: value
          }
        }
      });
    }

    return permsObj;
  }

  public searchUser(params?: HttpParams): Observable<PaginatedResponseUser> {
    return this.http.get<PaginatedResponseUser>(`${this.urls.users}/search`, { params });
  }

  public searchAuth0User(params?: HttpParams): Observable<User[]> {
    return this.http.get<User[]>(`${this.urls.users}/auth0`, { params });
  }

  public me(params?: HttpParams): Observable<User> {
    return this.http.get<User>(`${this.urls.users}/me`, { params });
  }

  public getUserRoles(userId: string, params?: HttpParams): Observable<UserRoleResponse[]> {
    return this.http.get<UserRoleResponse[]>(`${this.urls.users}/${userId}/roles`, { params });
  }

  public getCurrentUserPCCPreferences(name?: string): Observable<UserPreferencesPaginated> {
    const params = new HttpParams({ fromObject: { basic: 'true' } });
    return this.me(params).pipe(
      untilDestroyed(this),
      switchMap((user) => {
        const filter = name ? `AppName eq PCC and Name eq ${name}` : `AppName eq PCC`;
        const queryParams = new HttpParams({
          fromObject: { userId: user.ID, ['$filter']: filter }
        });

        return this.getUserPreferences(user.ID, queryParams).pipe(map((res) => ({ ...res, UserID: user.ID })));
      })
    );
  }
}
