<div class="tree-view-row-container" *ngFor="let parentProject of treeViewData; last as isLast">
  <div class="horizontal-line"></div>
  <div class="project-group-container" [class.archived]='parentProject.Status === "Archived"'
    [class.disabledState]="parentProject.activeRow" [class.expanded]="parentExpanded[parentProject.Name + '-expanded']">
    <div class="project-group">
      <div class="cell-body">
        <i class="fal fa-plus-square fa-lg icon-custom-style" [class.disabled]="level >= 6"
          *ngIf="(!parentExpanded[parentProject.Name + '-expanded'] && (parentProject.Projects && parentProject.Projects.length > 0)) || level === 6"
          (click)="toggleExpanded(parentProject.Name, level)"></i>
        <i class="fal fa-minus-square fa-lg icon-custom-style"
          *ngIf="parentExpanded[parentProject.Name + '-expanded'] && (parentProject.Projects && parentProject.Projects.length > 0) && level !== 6"
          (click)="toggleExpanded(parentProject.Name, level)"></i>
        <a routerLink="/project/{{ parentProject.ID }}">
          <img *ngIf="parentProject.ProjectLogo && !parentProject.ProjectLogo.includes('logo.gif')"
            [src]="parentProject.ProjectLogo" class="project-logo" [ngxTippy]="parentProject.Name"
            [tippyProps]="{ arrow: false }" />
          <p ellipsis
            *ngIf="!parentProject.ProjectLogo || (parentProject.ProjectLogo && parentProject.ProjectLogo.includes('logo.gif'))"
            class="project-title">{{parentProject.Name}} </p>
        </a>
      </div>
      <div class="cell-body">
        <button routerLink="/project/{{ projectID }}/tools/easycla/repositories/{{ parentProject.ID }}" mat-button
          class="lfx-button white" [fragment]="groupId" [disabled]="isManageDisabled(parentProject)"
          data-cy="tree-view-table-manage-button">
          Manage
        </button>
      </div>
      <div class="cell-body">
        <span>{{parentProject.TotalProjects}}</span>
      </div>
      <div class="cell-body">
        <div class="checkbox-col">
          <mat-checkbox [checked]="parentProject.checkedState" [disabled]="parentProject.disabledState"
            (change)="addRemoveChanges(parentProject, $event, true)"></mat-checkbox>
          <i *ngIf="hasShowInfoIcon(parentProject)" [tippyProps]="tippyProps"
            class="fas fas fa-exclamation-circle info-icon active" [ngxTippy]="getTooltip(parentProject)"></i>
          <i *ngIf="!hasShowInfoIcon(parentProject)" class="info-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="sub-projects-container-lv{{level}}"
    *ngIf="parentExpanded[parentProject.Name + '-expanded'] && parentProject && (parentProject.Projects && parentProject.Projects.length > 0) && level !== 6"
    [class.last]="isLast">
    <app-tree-view-table [level]="level + 1" [treeViewData]="parentProject.Projects" [parentExpanded]="parentExpanded"
      [treeViewRawData]="treeViewRawData" [projectID]="projectID" [groupId]="groupId" [(checkUpdates)]="checkUpdates"
      (checkUpdatesChange)="updateSelectAllState()">
    </app-tree-view-table>
  </div>
</div>
