import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@environments/environment';
import { CacheService } from '@providers/cache-service.provider';
import { UserInvite, Role, SendRequestInvite } from 'lfx-pcc';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ACSService {
  private acsServicesAPI = environment.apiUrl + '/acs-services';
  private urls = {
    roles: `${this.acsServicesAPI}/roles`,
    userRoles: `${this.acsServicesAPI}/users/serviceroles`,
    invites: `${this.acsServicesAPI}/invites`
  };

  public constructor(private http: HttpClient, private cacheService: CacheService, private authService: AuthService) {}

  public getRoles(params?: HttpParams): Observable<Role[]> {
    const roles = this.http.get<Role[]>(`${this.urls.roles}`, { params });
    return this.response(`acs-service-roles`, roles);
  }

  public getUserRoles(roles: string): Observable<any> {
    const params = new HttpParams({ fromObject: { rolenames: roles } });
    const userRoles = this.http.get<any>(`${this.urls.userRoles}`, { params, observe: 'response' });
    return this.response(`acs-user-roles-${roles}`, userRoles);
  }

  public getInvites(scopeid: string, params?: HttpParams): Observable<UserInvite[]> {
    const invites = this.http.get<UserInvite[]>(`${this.urls.invites}`, { params });
    return this.response(`acs-pending-invites-list-${scopeid}`, invites);
  }

  public postInvite(data: SendRequestInvite, params?: HttpParams): Observable<UserInvite> {
    const inviteResponse = this.http.post<UserInvite>(`${this.urls.invites}/sendinvite/v2`, data, {
      params
    });

    return inviteResponse;
  }

  private response(cacheKey: string, observable: Observable<any>) {
    if (this.cacheService.has(cacheKey)) {
      return this.cacheService.get(cacheKey);
    }

    const res = observable.pipe(shareReplay(1));
    this.cacheService.set(cacheKey, res);
    return res;
  }
}
