import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autofocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() public autofocus: boolean = false;

  public constructor(private host: ElementRef) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.autofocus) {
        this.host.nativeElement.focus();
      }
    }, 100);
  }
}
