import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { tippyProperties } from '@app/shared/utils/constants';
import { CLAProjectGroupGerrit } from 'lfx-pcc';

@Component({
  selector: 'app-gerrit-instances',
  templateUrl: './gerrit-instances.component.html',
  styleUrls: ['./gerrit-instances.component.scss']
})
export class GerritInstancesComponent {
  @ViewChild('organizationTable', { static: true }) public organizationTable: DatatableComponent;

  @Input() public rows: any[] = [];
  @Input() public prefix: string = 'github.com/';
  @Input() public emptyMessage: string = 'No organizations have been added';
  @Input() public currentRow: any;

  @Output() public readonly activate: EventEmitter<{ type: string; row: any }> = new EventEmitter<{ type: string; row: any }>();

  public prop: string = 'gerritUrl';
  public SelectionType = SelectionType;
  public tippyProps: NgxTippyProps = {
    ...tippyProperties,
    placement: 'top',
    interactive: false,
    onTrigger: (instance) => {
      const val = instance.reference.attributes.getNamedItem('data-tooltip')?.value;
      return val ? instance.setProps({ content: val }) : instance.disable();
    }
  };

  public onSelect(): any {
    if (this.organizationTable.rows && this.organizationTable.rows.length > 0) {
      return [this.organizationTable.rows.find((row: any) => row.gerritId === this.currentRow.gerritId)];
    }
    return [this.organizationTable.rows[0]];
  }

  public onActivate(event: { type: string; row: any }): void {
    this.activate.emit(event);
  }

  public getGerritStatusTooltip(gerrit: CLAProjectGroupGerrit): string | undefined {
    const status = this.getGerritConnectionStatus(gerrit);
    switch (status) {
      case 'connected':
        return 'Connected';
      case 'partial_connection':
        return 'Partial Connection';
      case 'no_connection':
        return 'No Connection';
      case 'connection_failure':
        return 'Connection Failure';
      default:
        return undefined;
    }
  }

  public getGerritConnectionStatus(gerrit: CLAProjectGroupGerrit): string {
    const hasRepo: boolean = ((gerrit['gerrit-repo-list'] && gerrit['gerrit-repo-list'].repos) || []).length > 0;
    if (hasRepo) {
      return gerrit['gerrit-repo-list'] && gerrit['gerrit-repo-list'].repos?.every((g) => g.connected) ? 'connected' : 'connection_failure';
    }
    return 'no_connection';
  }
}
