<div class="organizations-list">
  <ngx-datatable
    #organizationTable
    rowHeight="auto"
    columnMode="flex"
    [rows]="rows"
    #orgTable
    [selectionType]="SelectionType.single"
    class="org-table"
    [headerHeight]="0"
    (activate)="onActivate($event)"
    [messages]="{ emptyMessage: emptyMessage }"
    [reorderable]="false"
    [selected]="onSelect()"
  >
    <ngx-datatable-column [flexGrow]="1" [resizeable]="false" name="Organizations" [prop]="prop" cellClass="clickable" [sortable]="true">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
          <div ellipsis class="{{ getGerritConnectionStatus(row) }}">
            <i
              class="fas fa-circle {{ getGerritConnectionStatus(row) }}"
              [ngxTippy]="getGerritStatusTooltip(row)"
              [tippyProps]="tippyProps"
              [attr.data-tooltip]="getGerritStatusTooltip(row)"
              [tippyClassName]="row.connection_status"
            ></i>
            <a ellipsis class="bold">{{ value }}</a>
          </div>
          <div>{{ (row['gerrit-repo-list'] && row['gerrit-repo-list'].repos.length) || 0 }}</div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
