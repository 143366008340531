import { Injectable } from '@angular/core';
import { CacheService as NgxCacheService } from '@ngx-cache/core';

@Injectable()
export class CacheService {
  public constructor(private cache: NgxCacheService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public set(key: string | number, value: any): boolean {
    return this.cache.set(key, value);
  }

  public has(key: string | number): boolean {
    return this.cache.has(key);
  }

  public get(key: string | number): any {
    return this.cache.get(key);
  }

  public remove(key: string | number, wild?: boolean): void {
    if (wild) {
      this.cache.remove(key, wild);
    } else {
      this.cache.remove(key);
    }
  }

  public clear(): void {
    this.cache.clear();
  }
}
