<div class="error-dialog">
  <div mat-dialog-title class="error-header">
    <h1>Contact Support</h1>
    <button class="lfx-button clear" [mat-dialog-close]><i class="fas fa-times"></i></button>
  </div>

  <div mat-dialog-content>
    <p>Please click on the following link to open a support ticket:</p>
    <a [href]="jsdUrl" target="blank">{{ jsdUrl }}</a>

    <div class="extra-info" *ngIf="data.stackTrace">
      <h3>Issue Details</h3>
      <p>Please add the following information in your ticket description to allow our support team to assist you better.
      </p>
      <div class="stack-trace-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="stack-trace" #stackTrace>
          <ul>
            <li><span class="title">URL</span>: {{ currentURL }}</li>
            <li><span class="title">Time</span>: {{ currentTimestamp | date:'long'}}</li>
            <li><span class="title">Error</span>: {{ data.stackTrace }}</li>
            <li><span class="title">Additional Information</span>: {{ navigator.userAgent }} </li>
          </ul>
        </div>
        <i class="fas fa-copy" [cdkCopyToClipboard]="getClipboardData" (click)="hideTooltip()" ngxTippy
          data-tippy-content="Copied!" [tippyProps]="tippyProps" tippyClassName="copied-tooltip"></i>
      </div>
    </div>
  </div>
</div>
