import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { tippyProperties } from '@utils/constants';

@Component({
  selector: 'app-input-select-multiple',
  templateUrl: './input-select-multiple.component.html',
  styleUrls: ['./input-select-multiple.component.scss']
})
export class InputSelectMultipleComponent implements OnInit {
  @Input() public options: { label: string; value: string; icon?: string }[];
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string = 'Select';
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';
  @Input() public fullWidth: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public labelSubtext: string = '';
  @Input() public infoIconText: string = '';
  @Input() public multipleDefaultSelection: string[] = [];
  @Input() public hideDefaultOpt: boolean = true;
  @Input() public useChips: boolean = true;
  @Input() public showLabel: boolean = true;
  @Input() public tooltipIcon: string = 'fas fa-exclamation-circle';

  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public inputControl: AbstractControl;
  public tippyProps = tippyProperties;

  public constructor() {}

  public ngOnInit(): void {}

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return this.inputControl.errors || (this.inputControl.touched && this.inputControl.hasError('required') && this.inputControl.value === '');
    }

    return false;
  }

  public setSelectionValue(event: MatSelectChange): void {
    this.multipleDefaultSelection = event.value;
    this.formGroupChange.emit(event.value);
  }
  public getErrorMessage(control: AbstractControl): string {
    return control.hasError('required')
      ? 'This field is required.'
      : control.hasError('maxlength')
      ? 'Value cannot exceed 150 characters'
      : control.hasError('file')
      ? 'Please upload a valid file'
      : control.hasError('url')
      ? 'Please enter a valid URL including https/http protocol'
      : control.hasError('email')
      ? 'Please enter a valid email'
      : control.hasError('exists')
      ? 'Value already exists'
      : control.hasError('numeric')
      ? 'Value needs to be numeric'
      : 'Please enter a valid input';
  }

  public removeChip(value: string): void {
    this.multipleDefaultSelection = this.multipleDefaultSelection.filter((option: string) => option !== value);
    this.parent.controls[this.control].setValue(this.multipleDefaultSelection);
    this.formGroupChange.emit(this.parent.controls[this.control].value);
  }
}
