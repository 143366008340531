import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectService } from '@app/shared/services/project.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tippyProperties } from '@utils/constants';
import { deriveParent, flattenData, keysToArr } from '@utils/helper';
import { Project, ProjectCreate } from 'lfx-pcc';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, startWith } from 'rxjs/operators';

import { InputComponent } from '../input/input.component';
import { ToastComponent } from '../toast-component/toast.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  @ViewChild(InputComponent) public child: InputComponent;
  public addProjectLoading: boolean = true;
  public addProjectProcessing: boolean = false;
  public addProjectForm: FormGroup;
  public addProjectFormLoading: boolean = false;
  public projectCategories: string[] = [];
  public projects: Project[] = [];
  public searchLoading: boolean = false;
  public projectExists: boolean;
  public searchParentLoading: boolean = false;
  public parentSet: boolean = false;
  public parentID: string;
  public filteredName: Observable<string[]>;
  public status = [
    { label: 'Active', value: 'Active' },
    { label: 'Archived', value: 'Archived' },
    { label: 'Formation - Disengaged', value: 'Formation - Disengaged' },
    { label: 'Formation - Engaged', value: 'Formation - Engaged' },
    { label: 'Formation - Exploratory', value: 'Formation - Exploratory' },
    { label: 'Formation - On Hold', value: 'Formation - On Hold' },
    { label: 'Prospect', value: 'Prospect' }
  ];
  public type = [
    { label: 'Unfunded', value: 'Unfunded' },
    { label: 'Funded by Membership', value: 'Membership' },
    { label: 'Funded by Crowdfunding', value: 'Crowdfunding' }
  ];
  public slugErrorMessage: string;
  public slugGenerated: string;
  public isSlugValueUpdating: boolean;
  public isSlugFocused: boolean;
  public invalidSlug: string;
  public tippyProps = tippyProperties;
  public disableCategory: boolean = true;
  private question: string = '+ Add: ';
  private subject: Subject<string> = new Subject();

  public constructor(
    private projectService: ProjectService,
    private fb: FormBuilder,
    private toasterService: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<AddProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Project
  ) {
    this.addProjectForm = this.fb.group({
      type: ['project', Validators.required],
      name: ['', Validators.required],
      parent: ['', Validators.minLength(3)],
      status: [''],
      funding: [''],
      slug: ['', Validators.required],
      category: [''],
      noParent: [false]
    });

    if (this.data) {
      this.parentID = this.data.ID;
      this.addProjectForm.controls.parent.setValue((this.data && this.data.Name) || '');
      this.parentSet = true;
      this.disableCategory = false;
      this.type.push({ label: 'Supported by Parent Project', value: 'Supported by Parent Project' });
    }

    this.addProjectForm.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      if (_.isEmpty(value.trim())) {
        this.apFormControl.name.setErrors({ name: true });
      }
      this.handleNameSearch(value.trim());
    });

    this.addProjectForm.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.generateSlug(value.toLowerCase());
    });

    this.addProjectForm.controls.parent.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      if (value) {
        this.apFormControl.noParent.setValue(false);
      } else {
        this.disableCategory = true;
        this.apFormControl.category.setValue('');
      }
    });

    this.addProjectForm.controls.noParent.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      if (value) {
        this.projects = [];
        this.parentSet = false;
        this.apFormControl.parent.setValue('');
        this.apFormControl.category.setValue('');
        this.disableCategory = true;
      }
    });

    this.addProjectForm.controls.slug.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.slugErrorMessage = '';
      this.isSlugValueUpdating = true;
      if (!value) {
        this.apFormControl.slug.setValue(this.slugGenerated, { emitEvent: false });
        this.invalidSlug = '';
      } else {
        const regex = /^[a-z][a-z0-9-]*[a-z0-9]$/;
        if (!regex.test(value.trimStart())) {
          this.invalidSlug = 'Use numbers, lowercase letters, and dashes. Must start with a letter and end with a number or letter.';
        } else {
          this.invalidSlug = '';
        }
      }
      if (this.slugGenerated === value) {
        this.isSlugValueUpdating = false;
      }
    });

    this.subject.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      if (searchTextValue.length > 2) {
        this.searchParentLoading = true;
        this.handleParentSearch(searchTextValue);
      }
    });

    this.projectService
      .getProjectCategories()
      .pipe(
        untilDestroyed(this),
        catchError(() => of([]))
      )
      .subscribe((results: string[]) => {
        this.projectCategories = _.sortBy(results.filter((category: string) => !!category));

        this.addProjectLoading = false;
      });

    this.filteredName = this.apFormControl.category.valueChanges.pipe(
      startWith(''),
      map((state) => (state ? this.filterName(state) : this.projectCategories.slice()))
    );
  }

  public ngOnInit(): void {}

  public get apFormControl(): { [key: string]: FormControl | AbstractControl } {
    return this.addProjectForm.controls;
  }

  public filterName(name: string): string[] {
    const results = this.projectCategories.filter((n: string) => n.toLowerCase().indexOf(name.toLowerCase()) === 0);

    if (results.length !== 1 && name !== ' ') {
      results.push(this.question + name + '"?');
    }

    return results;
  }

  public setCategoryName(selection: MatAutocompleteSelectedEvent): void {
    const value =
      selection.option.value.indexOf('+ Add:') >= 0 ? selection.option.value.substring(this.question.length).split('"?')[0] : selection.option.value;
    this.apFormControl.category.setValue(value);
  }

  public setParentName(selection: MatAutocompleteSelectedEvent): void {
    this.apFormControl.parent.setValue(selection.option.value.Name);
    this.parentID = selection.option.value.ID;
    this.parentSet = true;
    this.disableCategory = false;
    this.type.push({ label: 'Supported by Parent Project', value: 'Supported by Parent Project' });
  }

  public clearParent(): void {
    this.parentSet = false;
    this.parentID = '';
    this.projects = [];
    this.apFormControl.parent.reset();
    this.type = this.type.filter((option) => option.label !== 'Supported by Parent Project');
  }

  public onKeyUp(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.subject.next(target.value);
  }

  public addProjectDisabled(): boolean {
    return (
      this.addProjectForm.invalid ||
      _.isEmpty(this.apFormControl.name.value.trim()) ||
      (!this.parentID && !this.addProjectForm.controls.noParent.value) ||
      this.addProjectLoading ||
      this.projectExists ||
      this.addProjectProcessing ||
      !!this.invalidSlug
    );
  }

  public addProject(): void {
    if (!this.addProjectDisabled()) {
      this.addProjectProcessing = true;
      if (this.apFormControl.type.value === 'project') {
        const projectData: ProjectCreate = {
          Name: this.apFormControl.name.value,
          Parent: this.parentID,
          ProjectType: 'Project',
          Type: '',
          Slug: this.apFormControl.slug.value,
          EntityType: this.parentID ? 'Subproject' : 'Incorporated Entity',
          Status: 'Prospect',
          Category: this.apFormControl.category.value
        };

        this.projectService.postProject(projectData).subscribe(
          (project: Project) => {
            this.addProjectProcessing = false;
            this.router.navigate([`/project/${project.ID}`]);
            this.toasterService.success('Project has successfully been created!', 'Success');
            this.dialogRef.close('project created');
          },
          (error: HttpErrorResponse) => {
            console.error(error);
            this.addProjectProcessing = false;
            const e = JSON.stringify(error);
            if (error.error?.message === 'invalid project slug, a project with same slug exist') {
              this.slugErrorMessage = 'A project with that slug already exists';
            } else {
              this.toasterService.error(error.error?.message || 'Unable to create project', e, { toastComponent: ToastComponent });
            }
          }
        );
      }
    }
  }

  public getValidMessage(): string {
    return (
      (this.apFormControl.name.valid &&
        !_.isEmpty(this.apFormControl.name.value.trim()) &&
        !this.projectExists &&
        !this.searchLoading &&
        'Valid project common name') ||
      ''
    );
  }

  public getErrorMessage(): string {
    return (
      (this.apFormControl.name.valid && this.projectExists && !this.searchLoading && 'A project with that name already exists') ||
      (this.addProjectForm.dirty && _.isEmpty(this.apFormControl.name.value) && 'The field is required') ||
      ''
    );
  }

  public handleSlugReset(): void {
    if (this.projectExists) {
      this.child.focusInput('name');
    } else {
      this.apFormControl.slug.setValue(this.slugGenerated);
    }
  }

  public handleSlugFocus(value: boolean): void {
    this.isSlugFocused = value;
  }

  public onCategoryKey(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value.trimStart();
    this.apFormControl.category.setValue(value);
  }
  private handleParentSearch(searchValue: string): void {
    const params = new HttpParams({ fromObject: { name: searchValue, orderBy: 'name', sortDir: 'asc', noCache: 'true', parentHierarchy: 'true' } });
    this.projectService
      .getSearchProjects(params)
      .pipe(untilDestroyed(this))
      .subscribe((projects: Project[]) => {
        const sortedProjects = _.sortBy(keysToArr(deriveParent(projects)), ['Name']);
        this.projects = flattenData(sortedProjects);
        this.searchParentLoading = false;
      });
  }

  private handleNameSearch(searchValue: string) {
    this.searchLoading = true;
    if (this.apFormControl.type.value === 'project') {
      const params = new HttpParams({ fromObject: { name: searchValue, orderBy: 'name', sortDir: 'asc', noCache: 'true' } });
      this.projectService
        .getSearchProjects(params)
        .pipe(untilDestroyed(this))
        .subscribe((projects: Project[]) => {
          this.projectExists = !!projects.find((project: Project) => project.Name.toLowerCase() === searchValue.toLowerCase());
          this.searchLoading = false;
        });
    }
  }

  private generateSlug(value: string) {
    const regex = /^[A-Za-z0-9]+$/;
    const words = value.split(' ');
    let result = '';
    const data = words
      .map((word) =>
        word
          .split('')
          .map((letter) => {
            if (regex.test(letter)) {
              return letter;
            }
            return '-';
          })
          .join('')
      )
      .join('-')
      .replace(/(-)\1{1,}/g, '$1');
    result = data;
    if (data[0] === '-' && data[data.length - 1] === '-') {
      const temp = data.split('');
      temp.shift();
      temp.pop();
      result = temp.join('');
    } else if (data[0] === '-') {
      const temp = data.split('');
      temp.shift();
      result = temp.join('');
    } else if (data[data.length - 1] === '-') {
      const temp = data.split('');
      temp.pop();
      result = temp.join('');
    }
    this.slugGenerated = result;
    this.apFormControl.slug.setValue(result);
  }
}
