import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  public baseTitle: string = 'Project Control Center - LFX';
  public title: string = '';
  public project: string = '';

  public constructor(private titleService: Title) {}

  public setTitle(title: string): void {
    this.title = title;
    this.updateTitle();
  }

  public setProject(project: string): void {
    this.project = project;
    this.updateTitle();
  }

  private updateTitle(): void {
    if (!this.title && !this.project) {
      this.titleService.setTitle(this.baseTitle);
    } else if (!this.title && this.project) {
      this.titleService.setTitle(`${this.project} - ${this.baseTitle}`);
    } else if (this.title && !this.project) {
      this.titleService.setTitle(`${this.title} - ${this.baseTitle}`);
    } else {
      this.titleService.setTitle(`${this.title} - ${this.project} - ${this.baseTitle}`);
    }
  }
}
