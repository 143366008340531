import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  @Input() public text: string;
  @Input() public hideIcon: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public canManage: boolean = true;

  @Output() public readonly delete: EventEmitter<string> = new EventEmitter();

  public constructor() {}

  public ngOnInit(): void {}

  public onDelete(): void {
    this.delete.emit(this.text);
  }
}
