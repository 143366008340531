import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      title: string;
      record: string;
      buttonClass: string;
      buttonLabelColor?: string;
      secondaryButtonLabel?: string;
      secondaryButtonColor?: string;
      buttonLabel: string;
      hideCancel?: boolean;
      buttonAlignment: string;
      template?: TemplateRef<any>;
      customActions?: TemplateRef<any>;
    },
    public dialogRef: MatDialogRef<MessageDialogComponent>
  ) {
    if (!this.data || !this.data.message) {
      this.data.message = '';
    }

    if (!this.data || !this.data.hideCancel) {
      this.data.hideCancel = false;
    }

    if (!this.data || !this.data.buttonAlignment) {
      this.data.buttonAlignment = 'end center';
    }
  }

  public ngOnInit(): void {}

  public applyAction(): void {
    this.dialogRef.close('action confirm');
  }

  public cancel(): void {
    this.dialogRef.close('cancel action');
  }
}
