import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';
import { tippyProperties } from '@utils/constants';
import tippy from 'tippy.js';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ellipsis]'
})
export class EllipsisDirective implements AfterViewInit, OnChanges {
  @Input() public ellipsis: string;
  public el: ElementRef<HTMLElement>;

  public constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.el = this.elementRef;
    const elipsifyme: any = {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      'white-space': 'nowrap'
    };

    Object.keys(elipsifyme).forEach((element) => {
      this.renderer.setStyle(this.el.nativeElement, `${element}`, elipsifyme[element]);
    });

    this.el.nativeElement.onmouseenter = () => {
      this.setToolTip();
    };
  }

  @HostListener('window:resize', ['$event.target']) public setToolTip(): void {
    this.renderer.setAttribute(this.el.nativeElement, `data-tooltip`, this.el.nativeElement.textContent as string);

    tippy(this.el.nativeElement, {
      ...tippyProperties,
      appendTo: document.body,
      onTrigger: (instance) => {
        this.renderer.setAttribute(this.el.nativeElement, `data-tooltip`, this.el.nativeElement.textContent as string);
        if (this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth) {
          instance.setContent(instance.reference.attributes.getNamedItem('data-tooltip')?.value as string);
          instance.enable();
        } else {
          instance.disable();
        }
      },
      onUntrigger: () => {
        this.renderer.removeAttribute(this.el.nativeElement, 'data-tooltip');
      }
    });
  }

  public ngAfterViewInit(): void {
    this.renderer.setProperty(this.el, 'scrollTop', 1);
    setTimeout(() => {
      this.setToolTip();
    }, 1000);
  }

  public ngOnChanges(): void {
    this.renderer.setProperty(this.el, 'scrollTop', 1);
    setTimeout(() => {
      this.setToolTip();
    }, 1000);
  }
}
