import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class DatePipe implements PipeTransform {
  public transform(value: string | Date, ...args: any[]): string {
    const [format] = args;
    return format ? moment(value).format(format) : moment(value).format('MMMM D, Y');
  }
}
