import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DeleteDialogComponent } from '../components/delete-dialog/delete-dialog.component';

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Observable<boolean>;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false
};

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  public constructor(private readonly matDialog: MatDialog) {}

  public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return this.matDialog
        .open(DeleteDialogComponent, {
          disableClose: true,
          data: {
            message: 'You have unsaved changes. Are you sure you would like to navigate away?',
            buttonLabel: 'Yes',
            secondaryButtonLabel: 'No',
            title: 'Unsaved Changes',
            hideCloseButton: true
          }
        })
        .afterClosed()
        .pipe(
          map((res) => res === 'confirm action'),
          tap((confirmed) => {
            if (!confirmed && CanDeactivateState.defendAgainstBrowserBackButton) {
              history.pushState(null, '', '');
            }
          })
        );
    }
    return true;
  }
}
