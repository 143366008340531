import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { tippyProperties } from '@utils/constants';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'l',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'l',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class InputDatePickerComponent implements OnInit {
  @Input() public fullWidth: boolean = false;
  @Input() public required: boolean = false;
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public errorMessage: string = '';
  @Input() public tooltipIcon: string = 'fas fa-exclamation-circle';
  @Input() public toolTipPosition: Partial<'right' | 'left' | 'top' | 'bottom'> = 'right';
  @Input() public tooltip: string = '';
  @Input() public hideIcon: boolean = false;
  @Input() public readonly: boolean = true;

  public inputControl: AbstractControl;
  public tippyProps: NgxTippyProps = {
    ...tippyProperties,
    placement: this.toolTipPosition
  };

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;
  }

  public inputHasErrors(): boolean | ValidationErrors {
    if (this.inputControl) {
      return (
        (this.inputControl.touched || this.inputControl.dirty) &&
        (this.inputControl.errors || (this.inputControl.hasError('required') && this.inputControl.value === '') || !!this.errorMessage)
      );
    }

    return false;
  }

  public getErrorMessage(): string {
    return this.parent.get(this.control)?.hasError('required') ? 'This field is required.' : 'Please enter a valid input';
  }

  public handleCalendarIcon(): any {
    if (this.hideIcon) {
      return 'icon-class';
    }
  }
}
