<div class="calendar-header" *ngIf="!deleteLoading">
  <button class="previous" mat-icon-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
    (viewDateChange)="closeOpenMonthViewDay()" (click)="onPrevious()" ngxTippy="Previous" [tippyProps]="tippyProps" tippyClassName="lfx-tooltip" [disabled]="loading">
    <i class="fas fa-arrow-left"></i>
  </button>
  <button class="next" mat-icon-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
    (viewDateChange)="closeOpenMonthViewDay()" (click)="onNext()" ngxTippy="Next" [tippyProps]="tippyProps" tippyClassName="lfx-tooltip" [disabled]="loading">
    <i class="fas fa-arrow-right"></i>
  </button>
  <h3>{{ getMonthYearOfCalendarSelectedDay }}</h3>
</div>
<ng-container *ngIf="!deleteLoading; else loadingCalendarEvents">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="calendarEvents"
      [refresh]="refresh" (beforeViewRender)="beforeMonthViewRender($event)" [ngClass]="{ 'loading' : loading}"
      (dayClicked)="dayClicked($event.day)">
    </mwl-calendar-month-view>
    <!--  <mwl-calendar-week-view-->
    <!--    *ngSwitchCase="CalendarView.Week"-->
    <!--    [viewDate]="viewDate"-->
    <!--    [refresh]="refresh"-->
    <!--    (eventClicked)="handleEvent('Clicked', $event.event)"-->
    <!--    (eventTimesChanged)="eventTimesChanged($event)"-->
    <!--  >-->
    <!--  </mwl-calendar-week-view>-->
    <!--  <mwl-calendar-day-view-->
    <!--    *ngSwitchCase="CalendarView.Day"-->
    <!--    [viewDate]="viewDate"-->
    <!--    [refresh]="refresh"-->
    <!--    (eventClicked)="handleEvent('Clicked', $event.event)"-->
    <!--    (eventTimesChanged)="eventTimesChanged($event)"-->
    <!--  >-->
    <!--  </mwl-calendar-day-view>-->
  </div>
</ng-container>

<ng-template #loadingCalendarEvents>
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutGap="32px">
      <ngx-skeleton-loader appearance="line" [theme]="{height: '20px', width: '500px'}"></ngx-skeleton-loader>
    </div>
    <div class="seven-in-row">
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
    </div>
    <div class="seven-in-row">
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" [theme]="{height: '38px', width: '67px'}"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
