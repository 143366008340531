import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ITXProgressAndStatus, ProgressAndStatus } from 'lfx-pcc';

@Component({
  selector: 'app-configuration-status',
  templateUrl: './configuration-status.component.html',
  styleUrls: ['./configuration-status.component.scss']
})
export class ConfigurationStatusComponent implements OnInit, OnChanges {
  @Input() public progressAndStatus: ProgressAndStatus;
  @Input() public itxProgressAndStatus?: ITXProgressAndStatus | null;
  @Input() public type: 'itx' | 'setup' = 'setup';

  public status?: string;
  public progress?: number;

  public constructor() {}

  public ngOnInit(): void {
    this.status = this.type === 'setup' ? this.progressAndStatus.Status : this.itxProgressAndStatus?.status;
    this.progress = this.type === 'setup' ? this.progressAndStatus.Progress : this.itxProgressAndStatus?.progress;
  }

  public ngOnChanges(): void {
    this.status = this.type === 'setup' ? this.progressAndStatus.Status : this.itxProgressAndStatus?.status;
    this.progress = this.type === 'setup' ? this.progressAndStatus.Progress : this.itxProgressAndStatus?.progress;
  }

  public get getStatus(): { label: string; icon: string } {
    switch (this.status) {
      case 'ok':
        return { label: 'OK', icon: 'fas fa-check-circle' };
      case 'warning':
        return { label: 'Warning', icon: 'fas fa-exclamation-circle' };
      default:
        return { label: 'Not set up', icon: 'far fa-circle' };
    }
  }
}
