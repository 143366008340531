import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CustomDateFormatter } from '@shared/providers/custom-date-formatter.provider';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Subject } from 'rxjs';
import { tippyProperties } from '@utils/constants';

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarComponent implements OnChanges {
  @ViewChild('modalContent', { static: true }) public modalContent: TemplateRef<any>;
  @Input() public calendarEvents: CalendarEvent[] = [];
  @Input() public currentDate: Date | string = DateTime.now().toJSDate();
  @Input() public dayOfTheMonthDate: Date | string = DateTime.now().toJSDate();
  @Input() public loading: boolean = false;
  @Input() public deleteLoading: boolean = false;
  @Output() public readonly calendarDayChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() public readonly resetFirstDayOfTheMonth: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() public selectedDay: CalendarMonthViewDay;

  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = DateTime.now().toJSDate();
  public refresh: Subject<any> = new Subject();
  public activeDayIsOpen: boolean = true;
  public tippyProps = tippyProperties;
  public modalData: {
    action: string;
    event: CalendarEvent;
  };

  public constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentDate && changes.currentDate.firstChange) {
      if (typeof changes.currentDate.currentValue === 'string') {
        this.viewDate = moment(this.currentDate).toDate();
      } else {
        this.viewDate = DateTime.fromFormat(changes.currentDate.currentValue, 'yyyy-MM-dd').toJSDate();
      }
      this.dayClicked({ date: this.viewDate } as any);
    }
    if (changes.dayOfTheMonthDate) {
      this.viewDate = moment(changes.dayOfTheMonthDate.currentValue).toDate();
    }
    if (changes.selectedDay) {
      this.selectedDay = changes.selectedDay.currentValue;
    }
  }

  public get getMonthYearOfCalendarSelectedDay(): string {
    return DateTime.fromJSDate(this.viewDate).toFormat('MMMM yyyy');
  }

  public dayClicked(day: CalendarMonthViewDay): void {
    if (isSameMonth(day.date, this.viewDate)) {
      this.activeDayIsOpen = !(isSameDay(this.viewDate, day.date) && this.activeDayIsOpen);
      this.viewDate = day.date;
    }
    this.selectedDay = day;
    this.calendarDayChange.emit(day.date);
  }

  public beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      if (this.selectedDay && isSameDay(this.selectedDay.date, day.date)) {
        day.cssClass = 'cal-day-selected';
      }
    });
  }

  public onNext(): void {
    this.currentDate = moment(this.viewDate).add(1, 'months').toString();
    // this.resetFirstDayOfTheMonth.emit(this.viewDate);
  }

  public onPrevious(): void {
    this.currentDate = moment(this.viewDate).subtract(1, 'months').toString();
    // this.resetFirstDayOfTheMonth.emit(this.viewDate);
  }

  public setView(view: CalendarView): void {
    this.view = view;
  }

  public closeOpenMonthViewDay(): void {
    this.activeDayIsOpen = false;
  }
}
