import { Location } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CacheService } from '@providers/cache-service.provider';
import { ClaService } from '@services/cla.service';
import { ProjectService } from '@services/project.service';
import { Breadcrumb, CLAGroup, Committee, ParentHierarchy, Project } from 'lfx-pcc';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastComponent } from '../toast-component/toast.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() public project!: Project;
  @Input() public childProject!: Project;
  @Input() public committee!: Committee;
  @Input() public easyCLA: boolean = false;
  @Input() public extraCrumbs: string = '';

  public hierarchy: Breadcrumb[] = [];
  public currentService: RegExpMatchArray | null;
  public currentProject: Project;
  public loading: boolean = true;
  public fragmentId: string;
  public group: CLAGroup;
  public currentGroup: CLAGroup;
  public domainName: string = '';

  protected activatedRoute: ActivatedRoute;
  protected projectService: ProjectService;
  protected claService: ClaService;
  protected cacheService: CacheService;
  protected toasterService: ToastrService;

  public constructor(private location: Location, public injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
    this.projectService = injector.get(ProjectService);
    this.claService = injector.get(ClaService);
    this.toasterService = injector.get(ToastrService);
    this.cacheService = injector.get(CacheService);

    this.activatedRoute.fragment.pipe(untilDestroyed(this)).subscribe((fragment) => {
      this.fragmentId = fragment;
    });
  }

  public ngOnInit(): void {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params: Params) => {
      const { id, groupId, projectId, domain } = params;
      const queries = [
        this.projectService.getProject(projectId ? projectId : id).pipe(
          untilDestroyed(this),
          catchError((e) => this.handleError({ e, callerMsg: 'Get Project' }))
        )
      ];
      if (this.easyCLA) {
        queries.push(
          this.claService.getClaGroups(id).pipe(
            untilDestroyed(this),
            catchError(() => of([]))
          )
        );
      }
      if (domain) {
        this.domainName = domain;
      }

      forkJoin(queries).subscribe((results) => {
        this.currentProject = results[0] as Project;
        this.hierarchy = [];
        if (this.currentProject.ParentHierarchy) {
          this.getParent(this.currentProject.ParentHierarchy);
        }

        this.hierarchy = this.hierarchy.reverse();

        // adding check so that the changes will only affect cla pages
        // matching that 3rd group adds the ID on the breadcrumb on other pages
        if (this.location.path().indexOf('tools/easycla') >= 0 || this.location.path().indexOf('tools/insights') >= 0) {
          this.currentService = this.location.path().match(/^\/project\/\w+\/(\w+)\/([a-zA-Z-]+)\/(\w+)/);
        } else if (this.location.path().indexOf('schedule-meeting') >= 0 || this.location.path().indexOf('manage-participants') >= 0) {
          this.currentService = this.location.path().match(/^\/project\/\w+\/(\w+)\/([a-zA-Z-]+)\/([a-zA-Z-]+)/);
        } else if (this.location.path().indexOf('manage-meeting') >= 0) {
          this.currentService = this.location.path().match(/^\/project\/\w+\/(\w+)\/([a-zA-Z-]+)\/([a-zA-Z-]+)/);
        } else if (this.location.path().indexOf('add-mailing-list') >= 0 || this.location.path().indexOf('manage-mailing-list') >= 0) {
          this.currentService = this.location.path().match(/^\/project\/\w+\/(\w+)\/([a-zA-Z-]+)\/([a-zA-Z-]+)/);
        } else {
          this.currentService = this.location.path().split('?').splice(0, 1).join('').split('/').splice(2) as RegExpMatchArray;
        }

        this.loading = false;

        if (results[1]) {
          this.group = results[1].filter((claGroup: CLAGroup) => claGroup.cla_group_id === this.fragmentId)[0];
        }
        if (groupId && results[1]) {
          this.currentGroup = results[1].filter((claGroup: CLAGroup) => claGroup.cla_group_id === groupId)[0];
        }
      });
    });
  }

  public get isNotCustomService(): boolean {
    return (
      !!this.currentService &&
      !this.domainName &&
      this.currentService[2] !== 'committees' &&
      this.currentService[2] !== 'easycla' &&
      this.currentService[2] !== 'insights' &&
      this.currentService[2] !== 'mailing-lists' &&
      this.currentService[2] !== 'meetings'
    );
  }

  public handleError({ e, callerMsg }: { e: any; callerMsg: string }): Observable<any> {
    const error = JSON.stringify(e);
    this.toasterService.error(e.message || e.data.message || `An error has occurred while fetching ${callerMsg}`, error, { toastComponent: ToastComponent });
    return of(e);
  }

  public cleanUpText(txt: string): string {
    return txt.replace(/-/g, ' ');
  }

  private getParent(p: ParentHierarchy) {
    this.hierarchy.push(p);

    if (_.has(p, 'ParentHierarchy')) {
      this.getParent(p.ParentHierarchy);
    }
  }
}
