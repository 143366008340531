import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { tippyProperties } from '@app/shared/utils/constants';
import { CLAProjectDisplay } from 'lfx-pcc';
import * as _ from 'lodash';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-tree-view-table',
  templateUrl: './tree-view-table.component.html',
  styleUrls: ['./tree-view-table.component.scss']
})
export class TreeViewTableComponent implements OnInit {
  @Input() public treeViewData: any = [];
  @Input() public treeViewRawData: { [key: string]: { checkedState: boolean } };
  @Input() public level: number = 2;
  @Input() public parentExpanded: { [key: string]: boolean } = {};
  @Input() public groupId: string = '';
  @Input() public projectID: string;
  @Input() public isAllProjectsTaken: boolean;
  @Input() public takenProjects: any = {}; // will hold projectID and groupID key pairs
  @Input() public checkUpdates: { checked: string[]; unchecked: string[] } = { checked: [], unchecked: [] };
  @Output() public readonly handleToaster: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly isAllProjectsTakenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly checkUpdatesChange: EventEmitter<{ checked: string[]; unchecked: string[] }> = new EventEmitter<{
    checked: string[];
    unchecked: string[];
  }>();
  public tippyProps: NgxTippyProps = {
    ...tippyProperties,
    maxWidth: '200px'
  };

  public constructor() {}

  public ngOnInit(): void {
    this.expandEnabledProjects();
  }

  public expandEnabledProjects(): void {
    const projects = this.getAllObjects(this.treeViewData);
    const selectedProjects = projects && projects.filter((project: CLAProjectDisplay) => project.checkedState);
    const parentProjects = _.uniqBy(_.flatten(selectedProjects.map((project) => projects.filter((item) => item.Name === project.ProjectParent))), 'Name');
    const finalProjects = [...parentProjects, ...selectedProjects];
    const expandedProjects =
      finalProjects &&
      finalProjects.map((project: CLAProjectDisplay) => {
        const name: { [key: string]: boolean } = {};
        name[project.Name + '-expanded'] = true;
        return { ...name };
      });

    if (expandedProjects) {
      expandedProjects.map((project: any) => (this.parentExpanded = { ...this.parentExpanded, ...project }));
    }
  }

  public hasShowInfoIcon(parentProject: CLAProjectDisplay): boolean {
    return parentProject.disabledState && !parentProject.activeRow;
  }

  public getTooltip(parentProject: CLAProjectDisplay): string {
    return parentProject?.tooltipInfo || '';
  }

  public getAllObjects(arr: CLAProjectDisplay[]): CLAProjectDisplay[] {
    let result: CLAProjectDisplay[] = [];
    if (arr) {
      arr.forEach((a: CLAProjectDisplay) => {
        result.push(a);
        if (a.Projects) {
          result = result.concat(this.getAllObjects(a.Projects));
        }
      });
    }
    return result;
  }

  public toggleExpanded(Name: string, level?: number): void {
    this.parentExpanded[Name + '-expanded'] = level && level < 6 ? !this.parentExpanded[Name + '-expanded'] : this.parentExpanded[Name + '-expanded'];
  }

  public isManageDisabled(row: CLAProjectDisplay): boolean {
    return !row.showManageBtn;
  }

  public checkEnabledProject(data: CLAProjectDisplay[], name?: string): void {
    _.compact(
      _.filter(data, (p: CLAProjectDisplay) => {
        if (!p.checkedState && !p.disabledState) {
          this.checkUpdatesChange.emit(this.checkUpdates);
        }
        if (p.Projects && this.isAllProjectsTaken) {
          this.checkEnabledProject(p.Projects as CLAProjectDisplay[], name);
        }
      })
    );
  }

  public updateSelectAllState(): void {
    this.checkUpdatesChange.emit(this.checkUpdates);
  }

  public addRemoveChanges(parentProject: CLAProjectDisplay, state: MatCheckboxChange, skipCheck: boolean = false): void {
    if (state.checked) {
      if (this.checkUpdates.unchecked.indexOf(parentProject.ID) >= 0) {
        _.remove(this.checkUpdates.unchecked, (unchkId: string) => unchkId === parentProject.ID);
      }
      this.checkUpdates.checked.push(parentProject.ID);
      this.updateSelectAllState();
      if (this.treeViewRawData[parentProject.ID].checkedState) {
        _.remove(this.checkUpdates.checked, (unchkId: string) => unchkId === parentProject.ID);
      }
    } else {
      if (this.checkUpdates.checked.indexOf(parentProject.ID) >= 0) {
        _.remove(this.checkUpdates.checked, (unchkId: string) => unchkId === parentProject.ID);
      }
      this.checkUpdates.unchecked.push(parentProject.ID);
      this.updateSelectAllState();
      if (!this.treeViewRawData[parentProject.ID].checkedState) {
        _.remove(this.checkUpdates.unchecked, (unchkId: string) => unchkId === parentProject.ID);
      }
    }

    parentProject.checkedState = state.checked;
    if (!skipCheck && this.checkUpdates.checked.length > 0 && this.checkUpdates.unchecked.length > 0) {
      this.handleToaster.emit();
    }
  }
}
