import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public monthViewColumnHeader({ date }: DateFormatterParams): string {
    return formatDate(date, 'EEE', 'en');
  }

  public monthViewTitle({ date }: DateFormatterParams): string {
    return formatDate(date, 'MMM y', 'en');
  }

  public weekViewColumnHeader({ date }: DateFormatterParams): string {
    return formatDate(date, 'EEE', 'en');
  }

  public dayViewHour({ date }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', 'en');
  }
}
