export const environment = {
  production: false,
  environment: 'dev',
  datadogEnv: 'development',
  originalSiteURL: 'https://v1.pcc.dev.platform.linuxfoundation.org',
  siteURL: 'https://pcc.dev.platform.linuxfoundation.org',
  lfxHeader: 'https://cdn.dev.platform.linuxfoundation.org',
  lfxACSScript: 'https://lfx-acs-ui.dev.platform.linuxfoundation.org/wc/lfx-easycli-form.js',
  myProfileURL: 'https://myprofile.dev.platform.linuxfoundation.org/',
  zapierDataFeedURL: 'https://api.connector.dev.lfx.linuxfoundation.org/v1/zapier/feed',
  linuxFoundationSFID: 'a0941000002wBz9AAE',
  hideParticipantHostsControls: true,
  auth0: {
    domain: 'linuxfoundation-dev.auth0.com',
    clientId: 'jStGXyf3nwTswv8goh6FcbU4EaWUZBNP',
    redirectUrl: `${window.location.origin}/auth`,
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/'
  },
  traceOrigins: ['https://pcc-bff.dev.platform.linuxfoundation.org'],
  apiUrl: 'https://pcc-bff.dev.platform.linuxfoundation.org/development/api',
  artifactsURL: 'https://lfx-cdn-dev.s3.us-east-1.amazonaws.com/project-artifacts',
  changeLogDate: '2023-02-01',
  launchDarklyClientId: '642888a88aee2013198c432a',
  features: {
    setup: {
      manage: true,
      create: true
    },
    itx: {
      sourceControl: {
        gerrit: true
      },
      ci: {
        view: false
      }
    },
    tools: {
      insights: {
        wiki: true
      }
    }
  },
  maintenance: {
    collaboration: {
      committees: false,
      wiki: false,
      issueTracking: false,
      mailingList: false,
      meetings: false
    },
    development: {
      sourceControl: false,
      ci: false,
      distribution: false
    },
    operations: {
      basic: false,
      membership: false,
      legal: false,
      domains: false,
      emailForwarding: false,
      cloudProviders: false
    },
    tools: {
      insights: false,
      security: false,
      mentorship: false,
      cla: false,
      crowdfunding: false
    }
  }
};
