import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Project } from 'lfx-pcc';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectService } from '../services/project.service';

@Injectable()
export class EasyClaGuard implements CanActivate {
  public constructor(private toasterService: ToastrService, private projectService: ProjectService, private router: Router) {}
  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const projectID = route.params.id;
    let activeOrFormationProject: boolean = false;
    return this.projectService.getProject(projectID).pipe(
      map((project: Project) => {
        if (project) {
          activeOrFormationProject = project.Status?.toLowerCase() === 'active' || project.Status?.toLowerCase() === 'formation - engaged';
          if (!activeOrFormationProject) {
            this.toasterService.error('EasyCLA are only available for active and formation projects.', 'Error');
            this.router.navigate([`/project/${projectID}`]);
          }
          return activeOrFormationProject;
        }
        return activeOrFormationProject;
      })
    );
  }
}
