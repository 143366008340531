import { Injectable, OnDestroy } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { environment } from '@environments/environment';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
  public client: LaunchDarkly.LDClient;
  public flags$: Observable<{ [key: string]: LaunchDarkly.LDFlagValue }>;

  public constructor() {}

  public async ngOnDestroy(): Promise<void> {
    await this.client.close();
  }

  public initialize(u: User): void {
    const user = {
      kind: 'user',
      name: u.name,
      key: u['https://sso.linuxfoundation.org/claims/username']
    } as LaunchDarkly.LDSingleKindContext;

    this.client = LaunchDarkly.initialize(environment.launchDarklyClientId, user);
  }

  public getFlag(flagKey: string, defaultValue: LaunchDarkly.LDFlagValue = false): Observable<LaunchDarkly.LDFlagValue> {
    const fetchFlag = new BehaviorSubject<LaunchDarkly.LDFlagValue>({});

    this.client.on(`change:${flagKey}`, () => {
      fetchFlag.next({});
    });

    this.client.waitUntilReady().then(() => {
      fetchFlag.next({});
    });

    return fetchFlag.pipe(map(() => this.client.variation(flagKey, defaultValue)));
  }
}
