import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tippyProperties } from '@app/shared/utils/constants';
import _ from 'lodash';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {
  @Input() public text!: string;
  @Input() public buttonEnabled: boolean | null = false;
  @Input() public buttonTitle: string = '';
  @Input() public buttonId: string = '';
  @Input() public searchEnabled: boolean = false;
  @Input() public searchPlaceholder: string = 'Search';
  @Input() public toggleEnabled: boolean = false;
  @Input() public ctaIcon: string = '';
  @Input() public sectionBrief: string = '';
  @Input() public localTime: string = '';
  @Input() public toggleOpenState: boolean = true;
  @Input() public disableSearchInput: boolean = false;
  @Input() public infoTooltip: { icon?: string; text: string };
  @Input() public upDownToggleArrow: boolean = false;
  @Input() public changeToggleDisabledBackgroundColor: boolean = false;

  @Output() public readonly clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly searched: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public sidebarSearch: FormControl = new FormControl();
  public tippyProps: NgxTippyProps = { ...tippyProperties, placement: 'right' };

  private subject: Subject<string> = new Subject();

  public constructor() {}

  public ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      this.searched.emit(searchTextValue);
    });
  }

  public get hasTooltip(): boolean {
    return this.infoTooltip && _.size(this.infoTooltip.text) > 0;
  }

  public buttonClicked(): void {
    this.clicked.emit(true);
  }

  public onKeyUp(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.subject.next(target.value);
  }

  public onToggleOpenState(): void {
    this.toggleOpenState = !this.toggleOpenState;
    this.toggled.emit(this.toggleOpenState);
  }
}
