<app-input
  [parent]="form"
  control="name"
  label="Search by Name or Email"
  [showValidationIcon]="true"
  [showSearchIcon]="true"
  [loading]="memberSearchLoading"
  [fullWidth]="true"
  [required]="false"
  data-cy="add-committee-member-component-name-input-component"
  [autocompleteRef]="nameAuto"
  [autofocus]="true"
>
</app-input>

<mat-autocomplete #nameAuto="matAutocomplete" [autoActiveFirstOption]="false">
  <mat-option *ngIf="memberSearchLoading">
    <div class="searching">Searching...</div>
  </mat-option>

  <div class="user-options">
    <mat-optgroup *ngFor="let user of users" [label]="getFullName(user)">
      <mat-option
        *ngFor="let userEmail of user.Emails"
        [value]="userEmail.EmailAddress"
        (click)="onSelectUser(user, userEmail.EmailAddress)"
        data-cy="select-search-user-add-member"
      >
        <img *ngIf="userEmail.IsPrimary" class="icon primary-icon" title="Primary Email" src="assets/icons/apple-touch-icon.png" />
        <i *ngIf="hasMeetingPreference(user, userEmail.EmailAddress)" title="Meeting Email" class="icon far fa-calendar-day"></i>
        <span ellipsis>{{ userEmail.EmailAddress }}</span>
      </mat-option>
    </mat-optgroup>
  </div>

  <mat-option *ngIf="noResultError" class="wrap top-border" (click)="onAddNewUser()" data-cy="no-result-add-new-committee-member-btn" [value]="memberName">
    <div>No results. <a>Fill out profile</a> for {{ memberName }}.</div>
  </mat-option>

  <mat-option
    *ngIf="resultWithAddNewUser && !validateEmail(memberName)"
    data-cy="add-new-committee-member-btn"
    [value]="memberName"
    class="wrap top-border"
    (click)="onAddNewUser()"
  >
    <div [title]="memberName">Not sure if any of these match? <a>Fill out profile</a> for {{ memberName }}.</div>
  </mat-option>
</mat-autocomplete>
