import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss']
})
export class TableFooterComponent implements OnInit {
  @ViewChild('table', { static: false }) public table: DatatableComponent;
  @ViewChild('ngxDatatablePager', { static: false }) public ngxDatatablePager: DatatableComponent;

  @Input() public curPage: number;
  @Input() public pageSize: number;
  @Input() public rowCount: number;
  @Input() public offset: number;

  public constructor() {}

  public ngOnInit(): void {}

  public goToPage(page: string): void {
    if (page) {
      this.table.offset = parseInt(page, 10) - 1;
    }
  }

  public onNextHandle(): void {
    this.table.offset++;
  }

  public onPreviousHandle(): void {
    this.table.offset--;
  }
}
