import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CacheService } from '@providers/cache-service.provider';
import { ACSService } from '@services/acs.service';
import {
  AddPersonnel,
  AggregateProgress,
  Artifact,
  Committee,
  CommitteeCreate,
  CommitteeMemberCreate,
  CommitteeMemberResponse,
  CommitteeMemberUpdate,
  CommitteeUpdate,
  Navigation,
  Permission,
  PermissionsCheck,
  Personnel,
  Product,
  ProductSave,
  Project,
  ProjectCreate,
  ProjectProduct,
  ProjectProductSave,
  ProjectProgress,
  ProjectSummary,
  ProjectUpdate,
  UserRole
} from 'lfx-pcc';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { ToastComponent } from '../components/toast-component/toast.component';
import { DEFAULT_ERROR_MSG } from '../utils/constants';
import { ProjectInfrastructureService } from './project-infrastructure.service';
import { UserService } from './user.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  public projectID: string;
  public projectPagesID: string;
  public projectPermissions: Permission;
  public projectProgress: ProjectProgress;
  public projectITXProgress: AggregateProgress;
  public projectPages: Navigation[] = [];
  public project: Observable<Project>;
  public projectSummary: Observable<ProjectSummary[]>;
  public isUpdated: boolean;
  private projectServicesAPI = environment.apiUrl + '/project-services';
  private urls = {
    projectServices: {
      projects: `${this.projectServicesAPI}/projects`,
      summary: `${this.projectServicesAPI}/summary`,
      dashboardSummary: `${this.projectServicesAPI}/dashboard-summary`,
      artifacts: `${this.projectServicesAPI}/artifacts`,
      products: `${this.projectServicesAPI}/products`
    }
  };

  public constructor(
    private http: HttpClient,
    private readonly userService: UserService,
    private cacheService: CacheService,
    private pisService: ProjectInfrastructureService,
    private acsService: ACSService,
    private toasterService: ToastrService
  ) {}

  public getProjectCategories(params?: HttpParams): Observable<string[]> {
    const categories = this.http.get<string[]>(`${this.urls.projectServices.projects}/categories`, { params });
    return this.response(`project-categories`, categories);
  }

  public getProjectProgress(id: string): Observable<ProjectProgress> {
    const progress = this.http.get<ProjectProgress>(`${this.urls.projectServices.projects}/${id}/progress`).pipe(
      catchError(() =>
        of({
          Basic: { Progress: 0, Status: 'none' },
          Committees: { Progress: 0, Status: 'none' },
          Legal: { Progress: 0, Status: 'none' },
          Membership: { Progress: 0, Status: 'none' },
          MeetingManagement: { Progress: 0, Status: 'none' }
        })
      ),
      map((_progress) => {
        const updatedProgress: Partial<ProjectProgress> = {};
        for (const [key, value] of Object.entries(_progress)) {
          Object.assign(updatedProgress, { [key]: { Progress: value.Progress, Status: value.Status === 'none' ? 'none' : 'ok' } });
        }

        return updatedProgress;
      })
    );
    return this.response(`project-progress-${id}`, progress);
  }

  public getProject(id: string, params?: HttpParams): Observable<Project> {
    if (!params) {
      params = new HttpParams({ fromObject: { view: 'pcc' } });
    }

    const project = this.http.get<Project>(`${this.urls.projectServices.projects}/${id}`, { params });
    return this.response(`project-${id}`, project);
  }

  public patchProject(id: string, projectData: ProjectUpdate, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.patch(`${this.urls.projectServices.projects}/${id}`, projectData, { params, observe: 'response' });
  }

  public getProjectPersonnel(id: string): Observable<Personnel[]> {
    const personnel = this.http.get<Personnel[]>(`${this.urls.projectServices.projects}/${id}/personnel`);
    return this.response(`project-personnel-${id}`, personnel);
  }

  public postProjectPersonnel(id: string, personnelData: AddPersonnel, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.projectServices.projects}/${id}/personnel`, personnelData, { params, observe: 'response' });
  }

  public deleteProjectPersonnel(id: string, userId: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.projectServices.projects}/${id}/personnel/${userId}`, { params, observe: 'response' });
  }

  public getProjectArtifacts(id: string): Observable<Artifact[]> {
    const artifacts = this.http.get<Artifact[]>(`${this.urls.projectServices.projects}/${id}/artifacts`);
    return this.response(`project-artifacts-${id}`, artifacts);
  }

  public postProjectArtifacts(id: string, artifactData: Artifact, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.projectServices.projects}/${id}/artifacts`, artifactData, { params, observe: 'response' });
  }

  public patchProjectArtifacts(id: string, artifactId: string, artifactData: Artifact): Observable<HttpResponse<any>> {
    return this.http.patch(`${this.urls.projectServices.projects}/${id}/artifacts/${artifactId}`, artifactData, { observe: 'response' });
  }

  public deleteProjectArtifacts(id: string, artifactId: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.projectServices.projects}/${id}/artifacts/${artifactId}`, { params, observe: 'response' });
  }

  public getProjectProducts(id: string, params?: HttpParams): Observable<ProjectProduct[]> {
    const products = this.http.get<ProjectProduct[]>(`${this.urls.projectServices.projects}/${id}/products`, { params });
    return this.response(`project-products-${id}`, products);
  }

  public postProjectProducts(id: string, productData: ProjectProductSave, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.projectServices.projects}/${id}/products`, productData, { params, observe: 'response' });
  }

  public putProjectProducts(id: string, projectProductId: string, productData: ProjectProductSave, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.projectServices.projects}/${id}/products/${projectProductId}`, productData, { params, observe: 'response' });
  }

  public putBulkProjectProducts(id: string, productsData: ProjectProductSave[], params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.projectServices.projects}/${id}/products/bulkUpdate`, { productsData }, { params, observe: 'response' });
  }

  public deleteProjectProducts(id: string, projectProductId: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.projectServices.projects}/${id}/products/${projectProductId}`, { params, observe: 'response' });
  }

  public getProducts(params?: HttpParams): Observable<Product[]> {
    const products = this.http.get<Product[]>(this.urls.projectServices.products, { params });
    return this.response(`products`, products);
  }

  public postProducts(productData: ProductSave, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(this.urls.projectServices.products, productData, { params, observe: 'response' });
  }

  public postProjectCommittee(id: string, committeeData: CommitteeCreate, params?: HttpParams): Observable<HttpResponse<any>> {
    this.cacheService.remove(`project-committees-${id}`);
    return this.http.post(`${this.urls.projectServices.projects}/${id}/committees`, committeeData, { params, observe: 'response' });
  }

  public patchProjectCommittee(id: string, committeeData: CommitteeUpdate, params?: HttpParams): Observable<HttpResponse<any>> {
    this.cacheService.remove(`project-infrastructure-committee-members-${id}-${committeeData.ID}`);
    return this.http.patch(`${this.urls.projectServices.projects}/${id}/committees/${committeeData.ID}`, committeeData, { params, observe: 'response' });
  }

  public deleteProjectCommittee(id: string, committeeId?: string, params?: HttpParams): Observable<HttpResponse<any>> {
    this.cacheService.remove(`project-committees-${id}`);
    this.cacheService.remove(`project-infrastructure-committee-members-${id}-${committeeId}`);
    return this.http.delete(`${this.urls.projectServices.projects}/${id}/committees/${committeeId}`, { params, observe: 'response' });
  }

  public getProjectCommittees(id: string, params?: HttpParams): Observable<Committee[]> {
    const committees = this.http.get<Committee[]>(`${this.urls.projectServices.projects}/${id}/committees`, { params });
    return this.response(`project-committees-${id}`, committees);
  }

  public putUploadArtifact(id: string, uploadData: File, params?: HttpParams): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('file', uploadData);
    return this.http.put(`${this.urls.projectServices.artifacts}/${id}/upload/init`, formData, { params, observe: 'response' });
  }

  public getProjectPages(id: string, project: Project, status: boolean = true): Promise<Navigation[]> {
    return new Promise((resolve: (value: Navigation[]) => void, reject: (reason: string) => void): void => {
      const projectParams = new HttpParams({ fromObject: { project: project.Slug } });

      forkJoin([
        this.getProjectProgress(id).pipe(
          untilDestroyed(this),
          catchError((e) => of(this.handleProgressError(e)))
        ),
        this.userService.getProjectPermissions(this.getPermissions(), id).pipe(
          untilDestroyed(this),
          catchError((e) => of(this.handleCheckPermissionsError(e)))
        ),
        this.acsService.getUserRoles('admin,controller,formation-team,viewer,lf-it-staff').pipe(
          untilDestroyed(this),
          catchError((e) => of(this.handleRolesError(e)))
        )
      ]).subscribe(
        (results: [ProjectProgress, Permission[], any]) => {
          this.projectID = id;
          this.projectPagesID = id;
          this.projectProgress = results[0];
          this.projectPermissions = this.userService.convertPermissionsToObj(results[1]);
          if (project.Slug && this.projectPermissions.aggregate_rollup.view && status) {
            this.pisService
              .getAggregateProgress(project.ID, projectParams)
              .pipe(
                shareReplay(1),
                catchError((e: HttpErrorResponse) => {
                  this.errorHandler(e, 'Error getting some progress statuses.');
                  return of({
                    awsaccount: { progress: 0, status: 'disabled' },
                    confluence: { progress: 0, status: 'disabled' },
                    distribution: { progress: 0, status: 'disabled', statuses: { dockerhub: 'disabled' } },
                    domain: { progress: 0, status: 'disabled' },
                    forward: { progress: 0, status: 'disabled' },
                    group: { progress: 0, status: 'disabled' },
                    jira: { progress: 0, status: 'disabled' },
                    sourcecontrol: { progress: 0, status: 'disabled', statuses: { github: 'disabled' } },
                    zoom: { progress: 0, status: 'disabled' }
                  });
                })
              )
              .subscribe((aggregateProgress: AggregateProgress) => {
                this.projectITXProgress = aggregateProgress || this.handleITXProgressError();
                this.projectPages = this.getProjectPageList(this.projectID, project, status);
                this.isUpdated = false;
                resolve(this.projectPages);
              });
          } else {
            this.projectITXProgress = this.handleITXProgressError();
            this.projectPages = this.getProjectPageList(this.projectID, project, status);
            this.isUpdated = false;
            resolve(this.projectPages);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
    });
  }

  public getProjectSummary(params?: HttpParams, cache: boolean = false, cacheId: string = ''): Observable<ProjectSummary[]> {
    if (cache) {
      const summary = this.http.get<ProjectSummary[]>(`${this.urls.projectServices.summary}`, { params });
      return this.response(cacheId, summary);
    }

    return this.http.get<ProjectSummary[]>(`${this.urls.projectServices.summary}`, { params });
  }

  public getDashboardProjectSummary(params?: HttpParams, cache: boolean = false, cacheId: string = ''): Observable<ProjectSummary[]> {
    if (cache) {
      const summary = this.http.get<ProjectSummary[]>(`${this.urls.projectServices.dashboardSummary}`, { params });
      return this.response(cacheId, summary);
    }

    return this.http.get<ProjectSummary[]>(`${this.urls.projectServices.dashboardSummary}`, { params });
  }

  public getProjects(params?: HttpParams): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.urls.projectServices.projects}`, { params });
  }

  public postProject(projectData: ProjectCreate, params?: HttpParams): Observable<Project> {
    return this.http.post<Project>(`${this.urls.projectServices.projects}`, projectData, { params });
  }

  public getSearchProjects(params?: HttpParams): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.urls.projectServices.projects}/search`, { params });
  }

  public getCommitteeById(id: string, committeeId: string): Observable<Committee> {
    const committee = this.http.get<Committee>(`${this.urls.projectServices.projects}/${id}/committees/${committeeId}`);
    return this.response(`project-infrastructure-committee-${id}-${committeeId}`, committee);
  }

  public postProjectCommitteeMember(id: string, committeeId: string, memberData: CommitteeMemberCreate[], params?: HttpParams): Observable<HttpResponse<any>> {
    this.cacheService.remove(`project-infrastructure-committee-members-${id}-${committeeId}`);
    return this.http.post(`${this.urls.projectServices.projects}/${id}/committees/${committeeId}/members`, memberData, { params, observe: 'response' });
  }

  public patchProjectCommitteeMember(
    id: string,
    committeeId: string,
    memberID: string,
    memberData: CommitteeMemberUpdate,
    params?: HttpParams
  ): Observable<HttpResponse<any>> {
    this.cacheService.remove(`project-infrastructure-committee-members-${id}-${committeeId}`);
    return this.http.patch(`${this.urls.projectServices.projects}/${id}/committees/${committeeId}/members/${memberID}`, memberData, {
      params,
      observe: 'response'
    });
  }

  public getCommitteeMembers(id: string, committeeId: string, params?: HttpParams): Observable<CommitteeMemberResponse> {
    const committeeMembers = this.http.get<CommitteeMemberResponse>(`${this.urls.projectServices.projects}/${id}/committees/${committeeId}/members`, {
      params
    });

    return committeeMembers;
  }

  public deleteCommitteeMember(id: string, committeeId: string, memberId: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.projectServices.projects}/${id}/committees/${committeeId}/members/${memberId}`, { params, observe: 'response' });
  }

  private handleProgressError(e: Error): ProjectProgress {
    console.error(e);
    return {
      Basic: {
        Status: 'disabled',
        Progress: 0
      },
      Committees: {
        Status: 'disabled',
        Progress: 0
      },
      MeetingManagement: {
        Status: 'none',
        Progress: 0
      },
      Legal: {
        Status: 'disabled',
        Progress: 0
      },
      Membership: {
        Status: 'disabled',
        Progress: 0
      }
    };
  }

  private handleITXProgressError(): AggregateProgress {
    return {
      awsaccount: {
        status: 'disabled',
        progress: 0
      },
      domain: {
        status: 'disabled',
        progress: 0
      },
      confluence: {
        status: 'disabled',
        progress: 0
      },
      jira: {
        status: 'disabled',
        progress: 0
      },
      group: {
        status: 'disabled',
        progress: 0
      },
      forward: {
        status: 'disabled',
        progress: 0
      },
      zoom: {
        status: 'disabled',
        progress: 0
      },
      sourcecontrol: {
        status: 'disabled',
        progress: 0
      }
    };
  }

  private handleCheckPermissionsError(e: Error): Permission[] {
    console.error(e);
    return [
      {
        permissions: {
          permission: false
        }
      }
    ];
  }

  private handleRolesError(e: Error): UserRole[] {
    console.error(e);
    return [];
  }

  private getPermissions(): PermissionsCheck[] {
    const permissions: PermissionsCheck[] = [
      { resource: 'project', action: 'view', objectType: 'project' },
      { resource: 'projects', action: 'create', objectType: 'project' },
      { resource: 'project_progress', action: 'view', objectType: 'project' },
      { resource: 'role_users', action: 'view_all', objectType: `project|organization` },
      { resource: 'awsaccount_rollup', action: 'view', objectType: 'project' },
      { resource: 'distribution_rollup', action: 'view', objectType: 'project' },
      { resource: 'domain_rollup', action: 'view', objectType: 'project' },
      { resource: 'v2_groupsio_services', action: 'view', objectType: 'project' },
      { resource: 'v2_groupsio_rollup', action: 'view', objectType: 'project' },
      { resource: 'forwards_rollup', action: 'view', objectType: 'project' },
      { resource: 'github_rollup', action: 'view', objectType: 'project' },
      { resource: 'jira_rollup', action: 'view', objectType: 'project' },
      { resource: 'confluence_rollup', action: 'view', objectType: 'project' },
      { resource: 'zoom_rollup', action: 'view', objectType: 'project' },
      { resource: 'cla_groups_in_foundation', action: 'view_all', objectType: 'project' },
      { resource: 'foundation_events', action: 'view_all', objectType: 'project' },
      { resource: 'aggregate_rollup', action: 'view', objectType: 'project' },
      { resource: 'view_onboard_status', action: 'view', objectType: 'project' },
      { resource: 'project_onboarding', action: 'view', objectType: 'project' },
      { resource: 'dockerhub', action: 'view', objectType: 'project' },
      { resource: 'ci', action: 'view', objectType: 'project' },
      { resource: 'groups', action: 'view_all', objectType: 'project' },
      { resource: 'acs_user_grants', action: 'view_all', objectType: 'project' }
    ];
    return permissions;
  }

  private getServiceEnabled(project: Project, service: string): boolean {
    return (project.EnabledServices && project.EnabledServices.find((s: string) => s === service) !== undefined) as boolean;
  }

  private getProjectPageList(id: string, project: Project, status: boolean = true): Navigation[] {
    const pages = [
      {
        name: 'Operations',
        active: false,
        items: [
          {
            name: 'Project Definition',
            slug: `/project/${id}/operations/project-definition`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.project_progress.view, project, this.projectProgress.Basic.Status === 'none' ? 'none' : 'ok')
              : 'none',
            disabled: !this.projectPermissions.project_progress.view,
            icon: 'far fa-tasks'
          },
          {
            name: 'Domains',
            slug: `/project/${id}/operations/domains`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.domain_rollup.view, project, this.projectITXProgress.domain.status)
              : this.getServiceEnabled(project, 'Domains')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.domain_rollup.view,
            icon: 'far fa-globe'
          },
          {
            name: 'Membership',
            slug: `/project/${id}/operations/membership`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.project_progress.view, project, this.projectProgress.Membership.Status === 'none' ? 'none' : 'ok')
              : 'none',
            disabled: !this.projectPermissions.project_progress.view,
            icon: 'far fa-analytics'
          },
          {
            name: 'Legal',
            slug: `/project/${id}/operations/legal`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.project_progress.view, project, this.projectProgress.Legal.Status === 'none' ? 'none' : 'ok')
              : 'none',
            disabled: !this.projectPermissions.project_progress.view,
            icon: 'far fa-balance-scale'
          },

          {
            name: 'Cloud Providers',
            slug: `/project/${id}/operations/cloud-providers`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.awsaccount_rollup.view, project, this.projectITXProgress.awsaccount.status)
              : this.getServiceEnabled(project, 'Cloud Providers')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.awsaccount_rollup.view,
            icon: 'far fa-cloud'
          }
        ]
      },
      {
        name: 'Collaboration',
        active: false,
        items: [
          {
            name: 'Committees',
            slug: `/project/${id}/collaboration/committees`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.project_progress.view, project, this.getServiceEnabled(project, 'Committees') ? 'ok' : 'none')
              : this.getServiceEnabled(project, 'Committees')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.project_progress.view,
            icon: 'far fa-users-class'
          },
          {
            name: 'Wiki',
            slug: `/project/${id}/collaboration/wiki`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.confluence_rollup.view, project, this.projectITXProgress.confluence.status)
              : this.getServiceEnabled(project, 'Wiki')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.confluence_rollup.view,
            icon: 'far fa-file-alt'
          },
          {
            name: 'Meetings',
            slug: `/project/${id}/collaboration/meetings`,
            active: false,
            status: status
              ? this.getProjectStatus(
                  this.projectPermissions.zoom_rollup.view,
                  project,
                  this.projectITXProgress.zoom.status === 'warning'
                    ? this.projectITXProgress.zoom.status
                    : this.getServiceEnabled(project, 'Meetings')
                    ? 'ok'
                    : 'none'
                )
              : this.getServiceEnabled(project, 'Meetings')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.zoom_rollup.view,
            icon: 'far fa-handshake-alt'
          },
          {
            name: 'Issue Tracker',
            slug: `/project/${id}/collaboration/issue-tracking`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.jira_rollup.view, project, this.projectITXProgress.jira.status)
              : this.getServiceEnabled(project, 'Issue Tracking')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.jira_rollup.view,
            icon: 'far fa-ballot-check'
          },
          {
            name: 'Mailing Lists',
            slug: `/project/${id}/collaboration/mailing-lists`,
            active: false,
            status: status
              ? this.getProjectStatus(
                  this.projectPermissions.v2_groupsio_rollup.view,
                  project,
                  this.projectITXProgress.group.status === 'warning'
                    ? this.projectITXProgress.group.status
                    : this.getServiceEnabled(project, 'Mailing Lists')
                    ? 'ok'
                    : 'none'
                )
              : this.getServiceEnabled(project, 'Mailing Lists')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.v2_groupsio_services.view,
            icon: 'far fa-envelope-open'
          }
        ]
      },
      {
        name: 'Development',
        active: false,
        items: [
          {
            name: 'Source Control',
            slug: `/project/${id}/development/source-control`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.github_rollup.view, project, this.projectITXProgress.sourcecontrol.status)
              : this.getServiceEnabled(project, 'Source Control')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.github_rollup.view,
            icon: 'far fa-code'
          },
          {
            name: 'Distribution',
            slug: `/project/${id}/development/distribution`,
            active: false,
            status: status
              ? this.getProjectStatus(this.projectPermissions.distribution_rollup.view, project, this.projectITXProgress.distribution.status)
              : this.getServiceEnabled(project, 'Distribution')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.distribution_rollup.view,
            icon: 'far fa-box-open'
          },
          {
            name: 'Continuous Integration',
            slug: `/project/${id}/development/ci`,
            active: false,
            hidden: environment.features.itx.ci.view,
            status: status
              ? this.getProjectStatus(this.projectPermissions.ci.view, project, this.projectITXProgress.confluence.status)
              : this.getServiceEnabled(project, 'Wiki')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.ci.view,
            icon: 'far fa-infinity'
          }
        ]
      },
      {
        name: 'LFX Tools',
        active: false,
        items: [
          {
            name: 'Mentorship',
            slug: `/project/${id}/tools/mentorship`,
            active: false,
            progress: 0,
            status: 'future',
            disabled: true,
            icon: 'far fa-users'
          },
          {
            name: 'Security',
            slug: `/project/${id}/tools/security`,
            active: false,
            progress: 0,
            status: status
              ? this.getProjectStatus(this.projectPermissions.view_onboard_status.view, project, this.getServiceEnabled(project, 'Security') ? 'ok' : 'none')
              : this.getServiceEnabled(project, 'Security')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.view_onboard_status.view || project.Status !== 'Active',
            icon: 'far fa-bug'
          },
          {
            name: 'Crowdfunding',
            slug: `/project/${id}/tools/crowdfunding`,
            active: false,
            progress: 0,
            status: 'future',
            disabled: true,
            icon: 'far fa-chart-pie'
          },
          {
            name: 'EasyCLA',
            slug: `/project/${id}/tools/easycla`,
            active: false,
            status: status
              ? this.getProjectStatus(
                  this.projectPermissions.cla_groups_in_foundation.view_all && this.projectPermissions.foundation_events.view_all,
                  project,
                  this.getServiceEnabled(project, 'CLA') ? 'ok' : 'none'
                )
              : this.getServiceEnabled(project, 'CLA')
              ? 'enabled'
              : 'none',
            disabled: !this.projectPermissions.cla_groups_in_foundation.view_all || !this.projectPermissions.foundation_events.view_all,
            icon: 'far fa-file-contract'
          }
        ]
      }
    ];

    if (!status && environment.environment !== 'prod') {
      pages.push({
        name: 'Manage Teams',
        active: false,
        items: [
          {
            name: 'Teams',
            slug: `/project/${id}/manage-teams/teams`,
            active: false,
            status: 'none',
            disabled: !this.projectPermissions.groups?.view_all,
            icon: 'far fa-user-friends'
          },
          {
            name: 'Users',
            slug: `/project/${id}/manage-teams/users`,
            active: false,
            status: 'none',
            disabled: !this.projectPermissions.acs_user_grants?.view_all,
            icon: 'far fa-user-cog'
          }
        ]
      });
    }
    // remove hidden page
    let allPages = _.map(pages, (page) => ({ ...page, items: _.filter(page.items, (item: { hidden: boolean }) => !item.hidden) }));
    // Hide email forwarding options from menu.
    allPages = allPages.map((page) => {
      if (page.name === 'Operations') {
        page.items = page.items.filter((item: any) => item.name !== 'Email Forwarding');
      }
      return page;
    });
    return allPages as Navigation[];
  }

  private getProjectStatus(permissions: boolean, project: ProjectCreate, status?: string) {
    let newStatus = 'Not set up';
    if (!permissions && status === 'ok') {
      newStatus = 'enabled';
    } else {
      newStatus = status as string;
    }

    return newStatus;
  }

  private response(cacheKey: string, observable: Observable<any>) {
    if (this.cacheService.has(cacheKey)) {
      return this.cacheService.get(cacheKey);
    }

    const res = observable.pipe(shareReplay(1));
    this.cacheService.set(cacheKey, res);
    return res;
  }

  private errorHandler(error: HttpErrorResponse, customMessage: string = '', hideSupport: boolean = false) {
    let cleanMsg = customMessage || error.error?.message || error.message || DEFAULT_ERROR_MSG;

    if (!hideSupport) {
      if (cleanMsg.slice(-1) !== '.') {
        cleanMsg += '. ';
      }
      const e = JSON.stringify(error);
      this.toasterService.error(cleanMsg, e, { toastComponent: ToastComponent });
    } else {
      this.toasterService.error(cleanMsg);
    }
  }
}
