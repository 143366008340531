<div class="message-dialog" *ngIf="data" data-cy="message-dialog">
  <div class="dialog-title" *ngIf="data.title">
    <div>
      <h1>{{ data.title }}</h1>
    </div>
    <div>
      <button class="lfx-button clear" [mat-dialog-close]><i class="fas fa-times"></i></button>
    </div>
  </div>
  <div mat-dialog-content>
    <div *ngIf="data.message" data-cy="message-dialog" [innerHTML]="data.message"></div>
    <ng-container *ngIf="data.template" [ngTemplateOutlet]="data.template"></ng-container>
  </div>
  <div class="actions">
    <div *ngIf="!data.customActions" fxLayout="row nowrap" fxLayoutGap="15px" [fxLayoutAlign]="data.buttonAlignment">
      <button mat-button class="lfx-button {{ data.secondaryButtonColor || 'white' }}" [mat-dialog-close]="data.record"
        (click)="cancel()" *ngIf="!data.hideCancel" data-cy="message-dialog-cancel-button">Cancel</button>
      <button mat-button class="lfx-button {{ data.buttonLabelColor || 'blue' }}" [mat-dialog-close]="data.record"
        (click)="applyAction()" data-cy="message-dialog-ok-button">{{
        data.buttonLabel || 'OK'}}</button>
    </div>
    <ng-container *ngIf="data.customActions" [ngTemplateOutlet]="data.customActions"></ng-container>
  </div>
</div>
