import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() public status: 'success' | 'error' | 'warning' | 'info' = 'success';
  @Input() public theme: 'default' | 'light' = 'default';
  @Input() public icon: string;
  @Input() public iconColor: string = '';
  @Input() public hide: boolean = false;
  @Input() public showClose: boolean = false;
  @Input() public htmlText: string = '';
  @Input() public backgroundColor: string = '';
  @Input() public borderColor: string = '';
  @Input() public width: string = '';
  @Input() public textColor: string = '';

  public constructor() {}

  public ngOnInit(): void {}

  public get customStyles(): { [key: string]: string | undefined } {
    return {
      'background-color': this.backgroundColor || undefined,
      'border-color': this.borderColor || undefined,
      width: this.width || undefined,
      color: this.textColor || undefined
    };
  }

  public get getIconClass(): string {
    if (_.isEmpty(this.icon)) {
      switch (this.status) {
        case 'success':
          return 'fas fa-check-circle';
        case 'error':
          return 'fas fa-exclamation-circle';
        case 'warning':
          return 'fas fa-exclamation-triangle';
        case 'info':
          return 'fa fa-info-circle';
        default:
          return '';
      }
    }

    return this.icon;
  }
}
