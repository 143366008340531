<div class="date-picker-container" [formGroup]="parent" fxLayout="column" [class.error]="errorMessage">
  <div>
    <input #time type="text" [formControlName]="control" [matAutocomplete]="auto" (click)="onClick($event, auto)"
      (blur)="onBlur()" (ngModelChange)="emitTimeSelection()" [placeholder]="placeholder">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" classList="time-picker">
      <mat-option *ngFor="let time of times" [value]="time.value">
        {{time.label}}
      </mat-option>
    </mat-autocomplete>
  </div>
  <span class="error-message" *ngIf="errorMessage">{{ errorMessage }}</span>
</div>
