<div class="input-item" [class.full-width]="fullWidth" [formGroup]="parent">
  <label for="{{control}}" [class.error]="inputHasErrors() || errorMessage">{{ label }} <span *ngIf="required"
      class="required">*</span></label>
  <div class="file-input-wrapper" fxLayout="row" fxLayoutGap="5px">
    <div class="file-input" fxLayout="row" fxLayoutAlign="space-between" [class.disabled]="inputDisabled()"
      [class.error]="inputHasErrors() || errorMessage" [class.valid]="validMessage">
      <div fxFlex="70" class="overflow-ellipsis">
        <span>{{ fileName }}</span>
      </div>
      <div class="action-items" fxLayout="row" fxLayoutAlign="flex-end" fxLayoutGap="15px">
        <div *ngIf="!hideView && canView">
          <button (click)="viewFile()" class="lfx-button link-button"><i class="fa fa-eye" ngxTippy="View File"
              [tippyProps]="tippyProps"></i></button>
        </div>
        <div *ngIf="!canView">
          <button class="lfx-button link-button" (click)="selectFile(control)" [disabled]="inputDisabled()"><i
              class="fad fa-upload" [ngxTippy]="getTooltipText" [tippyProps]="tippyProps"></i></button>
        </div>
        <div *ngIf="fileName && showDelete">
          <button class="lfx-button link-button" (click)="removeFile(control)"><i class="fas fa-trash"
              ngxTippy="Delete File" [tippyProps]="tippyProps"></i></button>
        </div>
      </div>
    </div>
    <div class="postfix-icon">
      <i class="fa fa-exclamation-circle" *ngIf="inputHasErrors() || errorMessage"></i>
      <i class="fa fa-check-circle" aria-hidden="true" *ngIf="validMessage"></i>
    </div>
  </div>
  <input type="file" class="{{control}}" id="{{control}}" (change)="fileSelected($event)" [accept]="acceptTypes"
    data-cy="input-file-component-input-file">
  <span *ngIf="inputHasErrors()" class="error">{{ getErrorMessage(inputControl) }}</span>
  <span *ngIf="errorMessage" class="error">{{ errorMessage }}</span>
  <span *ngIf="validMessage" class="valid-message">{{ validMessage }}</span>
</div>
