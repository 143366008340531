<div class="detailed-header-container">
  <div class="detailed-header" fxLayout="row nowrap" fxLayoutAlign="space-between center"
    [class.no-logo]="!project.ProjectLogo">
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
      <div class="project-details" data-cy="project-details-section" fxFlexFill>
        <h2>{{ project.Name }}</h2>
        <p *ngIf="project.Description">{{ project.Description }} </p>
        <p *ngIf="!project.Description" class="italic">No Description Added</p>
      </div>
    </div>
    <div class="project-links" data-cy="project-links-section">
      <button mat-stroked-button disabled="disabled" [class.warning]="project.Status === 'Prospect'">{{ project.Status
        === 'Prospect' ? 'Draft' : project.Status }}</button>
      <div ellipsis>
        <p *ngIf="projectHaveWebsite" ellipsis><a [href]="websiteURL" target="_blank">{{ project.Website }}</a></p>
      </div>
      <p *ngIf="!projectHaveWebsite" class="italic">No Website Link Added</p>
    </div>
  </div>
</div>
