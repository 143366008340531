import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CacheService } from '@providers/cache-service.provider';
import { AWSAccount } from 'lfx-pcc/interfaces/aws';
import { CIConfig, CIConfigData, CILanguage, CIPlatform, CIService } from 'lfx-pcc/interfaces/ci';
import { Confluence, ConfluenceSpace, TrackConfluence } from 'lfx-pcc/interfaces/confluence';
import { DockerhubOrganization, DockerhubOrganizationCreate, DockerhubOrganizationCredentials, DockerhubRepository } from 'lfx-pcc/interfaces/distribution';
import {
  CreateDomain,
  Domain,
  DomainAvailability,
  DomainDelegation,
  DomainOwnership,
  DomainRecord,
  DomainRenew,
  DomainTemplate,
  DomainTransfer,
  DomainTransferReady,
  RegisterDomain,
  ZoneFile
} from 'lfx-pcc/interfaces/domain';
import { EnableForward, Forward, ForwardAddress, ForwardStatus } from 'lfx-pcc/interfaces/forward';
import { GerritRepository, GerritServer } from 'lfx-pcc/interfaces/gerrit';
import { ITXGithubOrganization, ITXGithubRepository } from 'lfx-pcc/interfaces/github';
import { GitlabGroup, GitlabProject } from 'lfx-pcc/interfaces/gitlab';
import {
  GetGroupServiceResponse,
  GetSubGroupsResponse,
  Group,
  GroupMembersFullResponse,
  GroupServices,
  PostGroupList,
  PostGroupListResponse,
  PostGroupMembers,
  PostGroupServices,
  PostSubgroup,
  PutGroupMembers,
  PutSubgroup,
  Subgroup
} from 'lfx-pcc/interfaces/group';
import { ITXProgressAndStatus } from 'lfx-pcc/interfaces/itx';
import { Jira, JiraProject, TrackJira } from 'lfx-pcc/interfaces/jira';
import { AggregateProgress } from 'lfx-pcc/interfaces/project';
import {
  InvitedParticipant,
  PostZoomAccount,
  PostZoomMeetings,
  PutZoomMeetingOccurrence,
  PutZoomMeetings,
  ResponseZoomMeeting,
  ResponseZoomMeetingRegistrant,
  ZoomAccount,
  ZoomMeetingRegistrant
} from 'lfx-pcc/interfaces/zoom';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

import { ToastComponent } from '../components/toast-component/toast.component';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class ProjectInfrastructureService {
  public serviceProgress: ReplaySubject<ITXProgressAndStatus> = new ReplaySubject<ITXProgressAndStatus>();
  public newSubgroupId: number | undefined = undefined; // this is used by the mailing list to keep track of the newly create subgroup
  private projectServicesAPI = environment.apiUrl + '/project-infrastructure-services';

  private urls = {
    aggregate: `${this.projectServicesAPI}/aggregate/rollup`,
    domains: `${this.projectServicesAPI}/domains`,
    forwards: `${this.projectServicesAPI}/forwards`,
    jira: `${this.projectServicesAPI}/jira`,
    awsaccount: `${this.projectServicesAPI}/awsaccount`,
    zoom: `${this.projectServicesAPI}/zoom`,
    github: `${this.projectServicesAPI}/github`,
    gitlab: `${this.projectServicesAPI}/gitlab`,
    gerrit: `${this.projectServicesAPI}/gerrit`,
    groupsio: `${this.projectServicesAPI}/groupsio`,
    confluence: `${this.projectServicesAPI}/confluence`,
    ci: `${this.projectServicesAPI}/ci`,
    groupsioSubGroup: `${this.projectServicesAPI}/groupsio_subgroups`,
    groupsioServices: `${this.projectServicesAPI}/groupsio_services`,
    groupsioFindParent: `${this.projectServicesAPI}/groupsio_service_find_parent`,
    subGroup: `${this.projectServicesAPI}/groupsio_subgroup`,
    subGroups: `${this.projectServicesAPI}/groupsio_subgroups`,
    subGroupsFull: `${this.projectServicesAPI}/groupsio_subgroups/full`,
    dockerhub: `${this.projectServicesAPI}/dockerhub`
  };

  public constructor(private http: HttpClient, private cacheService: CacheService, private toasterService: ToastrService) {}

  public setProjectProgress(service: string, params: HttpParams, id: string): Observable<ITXProgressAndStatus> {
    if (this.cacheService.has(`project-${service}-progress-${id}`)) {
      return this.cacheService.get(`project-${service}-progress-${id}`);
    }

    const progress = new Observable((observer: Observer<ITXProgressAndStatus>) => {
      this.http
        .get<ITXProgressAndStatus>(`${this.projectServicesAPI}/${service}/rollup`, { params })
        .pipe(
          shareReplay(1),
          catchError((e) => this.handleGetITXProgressError(e))
        )
        .subscribe((res: ITXProgressAndStatus) => {
          this.serviceProgress.next(res);
          observer.next(res);
        });
    });

    return this.response(`project-${service}-progress-${id}`, progress);
  }

  public getProjectProgress(): Observable<ITXProgressAndStatus> {
    return this.serviceProgress;
  }

  public getAggregateProgress(project: string, params?: HttpParams): Observable<AggregateProgress> {
    const progress = this.http.get<AggregateProgress>(`${this.urls.aggregate}`, { params });
    return this.response(`project-infrastructure-aggregate-${project}`, progress);
  }

  public getProjectDomain(domainName: string, params?: HttpParams): Observable<Domain> {
    const domain = this.http.get<Domain>(`${this.urls.domains}/${domainName}`, { params });
    return this.response(`project-infrastructure-domain-${domainName}`, domain);
  }

  public getProjectDomains(project: string, params?: HttpParams): Observable<Domain[]> {
    const domains = this.http.get<Domain[]>(`${this.urls.domains}/list/${project}`, { params });
    return this.response(`project-infrastructure-domains-${project}`, domains);
  }

  public getDomainRecords(domain: string, params?: HttpParams): Observable<DomainRecord[]> {
    const records = this.http.get<DomainRecord[]>(`${this.urls.domains}/records`, { params });
    return this.response(`project-infrastructure-records-${domain}`, records);
  }

  public getDomainTemplates(domain: string, params?: HttpParams): Observable<DomainTemplate[]> {
    const templates = this.http.get<DomainTemplate[]>(`${this.urls.domains}/templates`, { params });
    return this.response(`project-infrastructure-templates-${domain}`, templates);
  }

  public getDomainTransferReady(params: HttpParams): Observable<DomainTransferReady> {
    return this.http.get<DomainTransferReady>(`${this.urls.domains}/transfer_ready`, { params });
  }

  public getDomainAvailability(params?: HttpParams): Observable<DomainAvailability> {
    return this.http.get<DomainAvailability>(`${this.urls.domains}/availability`, { params });
  }

  public getDomainOwnership(params?: HttpParams): Observable<DomainOwnership> {
    return this.http.get<DomainOwnership>(`${this.urls.domains}/ownership`, { params });
  }

  public postProjectDomain(domainData: CreateDomain, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.domains}`, domainData, { params, observe: 'response' });
  }

  public postRegisterDomain(registerData: RegisterDomain, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.domains}/register`, registerData, { params, observe: 'response' });
  }

  public postRegisterDomainAsync(registerData: RegisterDomain, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.domains}/register_async`, registerData, { params, observe: 'response' });
  }

  public postTransferDomain(transferData: DomainTransfer, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.domains}/transfer`, transferData, { params, observe: 'response' });
  }

  public postProjectDomainRecord(domainRecord: DomainRecord, params?: HttpParams): Observable<DomainRecord> {
    return this.http.post<DomainRecord>(`${this.urls.domains}/records`, domainRecord, { params });
  }

  public postProjectDomainTemplate(domainTemplate: DomainTemplate, params?: HttpParams): Observable<DomainTemplate> {
    return this.http.post<DomainTemplate>(`${this.urls.domains}/templates`, domainTemplate, { params });
  }

  public postValidateZoneFile(zoneData: File, params?: HttpParams): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('file', zoneData);
    return this.http.post(`${this.urls.domains}/validate_zonefile`, formData, { params, observe: 'response' });
  }

  public postProcessZoneFile(zoneData: ZoneFile, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.domains}/process_zonefile`, zoneData, { params, observe: 'response' });
  }

  public getProjectConfluence(project: string, params?: HttpParams): Observable<Confluence[]> {
    const confluence = this.http.get<Confluence[]>(`${this.urls.confluence}/${project}`, { params });
    return this.response(`project-infrastructure-confluence-${project}`, confluence);
  }

  public getHierarchyConfluence(projectID: string, params?: HttpParams): Observable<Confluence[]> {
    const confluence = this.http.get<Confluence[]>(`${this.urls.confluence}/hierarchy/${projectID}`, { params });
    return this.response(`project-infrastructure-confluence-hierarchy-${projectID}`, confluence);
  }

  public getHierarchyGerritServer(projectID: string, params?: HttpParams): Observable<GerritServer[]> {
    const gerritServer = this.http.get<GerritServer[]>(`${this.urls.gerrit}/hierarchy/${projectID}`, { params });
    return this.response(`project-infrastructure-gerrit-server-hierarchy-${projectID}`, gerritServer);
  }

  public getHierarchyDockerhubOrg(projectID: string, params?: HttpParams): Observable<DockerhubOrganization[]> {
    const gerritServer = this.http.get<DockerhubOrganization[]>(`${this.urls.dockerhub}/hierarchy/${projectID}`, { params });
    return this.response(`project-infrastructure-dockerhub-organization-hierarchy-${projectID}`, gerritServer);
  }

  public getHierarchyJira(projectID: string, params?: HttpParams): Observable<Jira[]> {
    const jira = this.http.get<Jira[]>(`${this.urls.jira}/hierarchy/${projectID}`, { params });
    return this.response(`project-infrastructure-jira-hierarchy-${projectID}`, jira);
  }

  public getConfluenceSpaces(site: number, params?: HttpParams): Observable<ConfluenceSpace[]> {
    const confluence = this.http.get<ConfluenceSpace[]>(`${this.urls.confluence}/${site}/spaces`, { params });
    return this.response(`project-infrastructure-confluence-spaces-${site}`, confluence);
  }

  public postProjectConfluence(confluence: TrackConfluence): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.confluence}`, confluence, { observe: 'response' });
  }

  public putProjectDomain(name: string, domain: Partial<Domain>, params?: HttpParams): Observable<Domain> {
    return this.http.put<Domain>(`${this.urls.domains}/${name}`, domain, { params });
  }

  public putProjectDomainRecord(domainRecord: DomainRecord, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.domains}/records`, domainRecord, { params, observe: 'response' });
  }

  public putProjectDomainTemplates(domainTemplate: DomainTemplate, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.domains}/templates`, domainTemplate, { params, observe: 'response' });
  }

  public putProjectDomainAutoRenew(domainRenew: DomainRenew, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.domains}/renew`, domainRenew, { params, observe: 'response' });
  }

  public putProjectDomainDelegation(domainDelegation: DomainDelegation, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.domains}/delegation`, domainDelegation, { params, observe: 'response' });
  }

  public deleteProjectDomain(name: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.domains}/${name}`, { params, observe: 'response' });
  }

  public deleteProjectDomainRecord(params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.domains}/records`, { params, observe: 'response' });
  }

  public deleteProjectDomainTemplate(params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.domains}/templates`, { params, observe: 'response' });
  }

  public getProjectForwardStatus(project: string, params?: HttpParams): Observable<ForwardStatus> {
    const forwardStatus = this.http.get<Forward[]>(`${this.urls.forwards}/${project}/status`, { params });
    return this.response(`project-infrastructure-forward-status-${project}`, forwardStatus);
  }

  public getProjectForwards(project: string, params?: HttpParams): Observable<Forward[]> {
    const forwards = this.http.get<Forward[]>(`${this.urls.forwards}/${project}/forwards`, { params });
    return this.response(`project-infrastructure-forwards-${project}`, forwards);
  }

  public postProjectEnableForwards(forwardsData: EnableForward, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.forwards}`, forwardsData, { params, observe: 'response' });
  }

  public postProjectForwards(project: string, forwardsData: ForwardAddress, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.forwards}/${project}/forwards`, forwardsData, { params, observe: 'response' });
  }

  public putProjectForwards(project: string, alias: string, forwardsData: ForwardAddress, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.forwards}/${project}/forwards/${alias}`, forwardsData, { params, observe: 'response' });
  }

  public deleteProjectForwards(project: string, alias: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.forwards}/${project}/forwards/${alias}`, { params, observe: 'response' });
  }

  public getJiraProjects(site: number, params?: HttpParams): Observable<JiraProject[]> {
    const jira = this.http.get<JiraProject[]>(`${this.urls.jira}/${site}/projects`, { params });
    return this.response(`project-infrastructure-jira-projects-${site}`, jira);
  }

  public getProjectJira(project: string, params?: HttpParams): Observable<Jira[]> {
    const jira = this.http.get<Jira[]>(`${this.urls.jira}`, { params });
    return this.response(`project-infrastructure-jira-${project}`, jira);
  }

  public postProjectJira(jira: TrackJira): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.jira}`, jira, { observe: 'response' });
  }

  public getProjectAWSAccounts(project: string, params?: HttpParams): Observable<AWSAccount[]> {
    const aws = this.http.get<AWSAccount[]>(`${this.urls.awsaccount}`, { params });
    return this.response(`project-infrastructure-aws-${project}`, aws);
  }

  public postProjectAWSAccount(awsAccount: AWSAccount): Observable<AWSAccount> {
    return this.http.post<AWSAccount>(`${this.urls.awsaccount}`, awsAccount);
  }

  public getProjectZoomAccounts(project: string, params?: HttpParams): Observable<ZoomAccount[]> {
    const zoomAccounts = this.http.get<ZoomAccount[]>(`${this.urls.zoom}/users/${project}`, { params });
    return this.response(`project-infrastructure-zoom-accounts-${project}`, zoomAccounts);
  }

  public postUserZoomAccount(zoomAccountPayload: PostZoomAccount): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.zoom}/users`, zoomAccountPayload, { observe: 'response' });
  }

  public putUserZoomAccount(id: string, zoomAccountPayload: PostZoomAccount): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.zoom}/users/${id}`, zoomAccountPayload, { observe: 'response' });
  }

  public deleteUserZoomAccount(id: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.zoom}/users/${id}`, { observe: 'response' });
  }

  public getZoomMeetings(project: string, params?: HttpParams): Observable<ResponseZoomMeeting[]> {
    const zoomMeetings = this.http.get<ResponseZoomMeeting[]>(`${this.urls.zoom}/meetings`, { params });
    return this.response(`project-infrastructure-zoom-meetings-${project}`, zoomMeetings);
  }

  public getMeetingsRegistrants(project: string, params?: HttpParams): Observable<{ [key: string]: ZoomMeetingRegistrant[] }> {
    const zoomMeetings = this.http.get<ResponseZoomMeeting[]>(`${this.urls.zoom}/meetings/registrants`, { params });
    return this.response(`project-infrastructure-zoom-meetings-registrants-${project}`, zoomMeetings);
  }

  public postZoomMeeting(zoomMeetingPayload: PostZoomMeetings, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.zoom}/meeting/participants`, zoomMeetingPayload, { params, observe: 'response' });
  }

  public getZoomMeeting(id: string, params?: HttpParams): Observable<ResponseZoomMeeting> {
    return this.http.get<ResponseZoomMeeting>(`${this.urls.zoom}/meetings/${id}`, { params });
  }

  public getZoomMeetingRegistrants(id: string, params?: HttpParams): Observable<ResponseZoomMeetingRegistrant> {
    const registrants = this.http.get<ResponseZoomMeetingRegistrant>(`${this.urls.zoom}/meetings/${id}/registrants`, { params });
    return this.response(`project-infrastructure-zoom-meeting-registrants-${id}`, registrants);
  }

  public postZoomMeetingRegistrants(id: string, registrantsPayload: InvitedParticipant[], params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.zoom}/meetings/${id}/registrants`, registrantsPayload, { params, observe: 'response' });
  }

  public putZoomMeetingRegistrants(
    id: string,
    meetingPayload: PutZoomMeetings,
    meetingOccurrencePayload: PutZoomMeetingOccurrence,
    registrantsPayload: InvitedParticipant[],
    params?: HttpParams
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      `${this.urls.zoom}/meetings/${id}/registrants`,
      { meeting: meetingPayload, meetingOccurrence: meetingOccurrencePayload, registrants: registrantsPayload },
      { params, observe: 'response' }
    );
  }

  public deleteBulkZoomMeetingRegistrants(id: string, registrantsPayload: any[]): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.zoom}/meetings/${id}/delete/registrants`, registrantsPayload, { observe: 'response' });
  }

  public postZoomMeetingResendInvitation(id: string, registrantID: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.zoom}/meetings/${id}/resend/${registrantID}`, {}, { params, observe: 'response' });
  }

  public putZoomMeeting(id: string, zoomMeetingPayload: PutZoomMeetings): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.zoom}/meetings/${id}`, zoomMeetingPayload, { observe: 'response' });
  }

  public putZoomMeetingOccurrence(
    id: string,
    occurrenceId: string,
    zoomMeetingOccurrencePayload: PutZoomMeetingOccurrence,
    params?: HttpParams
  ): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.zoom}/meetings/${id}/occurrences/${occurrenceId}`, zoomMeetingOccurrencePayload, { params, observe: 'response' });
  }

  public deleteZoomMeeting(id: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.zoom}/meetings/${id}`, { params, observe: 'response' });
  }

  public deleteZoomMeetingOccurrence(id: string, occurrence: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.zoom}/meetings/${id}/occurrences/${occurrence}`, { params, observe: 'response' });
  }

  public getGithubOrganizations(project: string, params?: HttpParams): Observable<ITXGithubOrganization[]> {
    const githubOrgs = this.http.get<ITXGithubOrganization[]>(`${this.urls.github}`, { params });
    return this.response(`project-infrastructure-github-org-${project}`, githubOrgs);
  }

  public getGithubOrganization(project: string, org: string, params?: HttpParams): Observable<ITXGithubOrganization> {
    return this.http.get<ITXGithubOrganization>(`${this.urls.github}/${org}`, { params });
  }

  public getGithubOrganizationRepositories(org: string, params?: HttpParams): Observable<ITXGithubRepository[]> {
    const orgRepos = this.http.get<ITXGithubRepository[]>(`${this.urls.github}/${org}/repos`, { params });
    return this.response(`project-infrastructure-github-repos-${org}`, orgRepos);
  }

  public getGithubOrganizationInsightsRepositories(org: string, params?: HttpParams): Observable<ITXGithubRepository[]> {
    const orgRepos = this.http.get<ITXGithubRepository[]>(`${this.urls.github}/${org}/insights-repos`, { params });
    return this.response(`project-infrastructure-github-repos-${org}`, orgRepos);
  }

  public postGithubOrganization(orgData: ITXGithubOrganization, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.github}`, orgData, { params, observe: 'response' });
  }

  public deleteGithubOrganization(org: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.github}/${org}`, { params, observe: 'response' });
  }

  public postGithubRepository(org: string, repoData: ITXGithubRepository, params?: HttpParams): Observable<ITXGithubRepository> {
    return this.http.post<ITXGithubRepository>(`${this.urls.github}/${org}/repos`, repoData, { params });
  }

  public putGithubRepository(org: string, repo: string, repoData: ITXGithubRepository, params?: HttpParams): Observable<ITXGithubRepository> {
    return this.http.put<ITXGithubRepository>(`${this.urls.github}/${org}/repos/${repo}`, repoData, { params });
  }

  public deleteGithubRepository(org: string, repo: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.github}/${org}/repos/${repo}`, { params, observe: 'response' });
  }

  public getGitlabGroups(project: string, params?: HttpParams): Observable<GitlabGroup[]> {
    const gitlabGroups = this.http.get<GitlabGroup[]>(`${this.urls.gitlab}`, { params });
    return this.response(`project-infrastructure-gitlab-groups-${project}`, gitlabGroups);
  }

  public postGitlabGroup(groupData: GitlabGroup, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.gitlab}`, groupData, { params, observe: 'response' });
  }

  public deleteGitlabGroup(group: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.gitlab}/${group}`, { params, observe: 'response' });
  }

  public getGitlabProjects(group: string, params?: HttpParams): Observable<GitlabProject[]> {
    const groupProjects = this.http.get<GitlabProject[]>(`${this.urls.gitlab}/${group}/projects`, { params });
    return this.response(`project-infrastructure-gitlab-projects-${group}`, groupProjects);
  }

  public postGitlabProject(group: string, projectData: GitlabProject, params?: HttpParams): Observable<GitlabProject> {
    return this.http.post<GitlabProject>(`${this.urls.gitlab}/${group}/projects`, projectData, { params });
  }

  public putGitlabProject(group: string, project: string, projectData: GitlabProject, params?: HttpParams): Observable<GitlabProject> {
    return this.http.put<GitlabProject>(`${this.urls.gitlab}/${group}/projects/${project}`, projectData, { params });
  }

  public deleteGitlabProject(group: string, project: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.gitlab}/${group}/projects/${project}`, { params, observe: 'response' });
  }

  public getGerritProjectServer(project: string, params: HttpParams): Observable<GerritServer[]> {
    const gerritService = this.http.get<GerritServer[]>(`${this.urls.gerrit}`, { params });
    return this.response(`project-infrastructure-gerrit-${project}`, gerritService);
  }

  public getListOfGerritRepos(project: string, params?: HttpParams): Observable<GerritRepository[]> {
    const gerritRepositories = this.http.get<GerritRepository[]>(`${this.urls.gerrit}/repositories`, { params });
    return this.response(`project-infrastructure-gerrit-repositories-${project}`, gerritRepositories);
  }

  public getListOfGerritITXRepos(project: string, params?: HttpParams): Observable<GerritRepository[]> {
    const gerritRepositories = this.http.get<GerritRepository[]>(`${this.urls.gerrit}/insights-repositories`, { params });
    return this.response(`project-infrastructure-gerrit-repositories-${project}`, gerritRepositories);
  }

  public postGerritRepo(project: string, data: GerritRepository, params?: HttpParams): Observable<GerritRepository> {
    return this.http.post<GerritRepository>(`${this.urls.gerrit}/repository`, data, { params });
  }

  public updateGerritRepo(project: string, data: GerritRepository, params?: HttpParams): Observable<GerritRepository> {
    return this.http.put<GerritRepository>(`${this.urls.gerrit}/repository`, data, { params });
  }

  public deleteGerritRepo(project: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.gerrit}/repository`, { params, observe: 'response' });
  }

  public getProjectGroupsIO(project: string, params?: HttpParams): Observable<Group> {
    const groups = this.http.get<Group>(`${this.urls.groupsio}/${project}`, { params });
    return this.response(`project-infrastructure-groupsio-${project}`, groups);
  }

  public getProjectGroupsIOSubGroups(projectSlug: string, params?: HttpParams): Observable<GetSubGroupsResponse> {
    const groups = this.http.get<Group>(`${this.urls.groupsioSubGroup}/full?project=${projectSlug}`, { params });
    return this.response(`project-infrastructure-groupsio-subGroup${projectSlug}`, groups);
  }

  public getHierarchyGroupsIO(projectID: string, params?: HttpParams): Observable<Group[]> {
    const groups = this.http.get<Group[]>(`${this.urls.jira}/hierarchy/${projectID}`, { params });
    return this.response(`project-infrastructure-groupsio-hierarchy-${projectID}`, groups);
  }

  public postProjectGroupsIO(groupData: Group, params?: HttpParams): Observable<Group> {
    return this.http.post<Group>(`${this.urls.groupsio}`, groupData, { params });
  }

  public postProjectGroupsIOList(project: string, groupData: PostGroupList, params?: HttpParams): Observable<PostGroupListResponse[]> {
    return this.http.post<PostGroupListResponse[]>(`${this.urls.groupsio}/${project}/lists`, groupData, { params });
  }

  public putProjectGroupsIO(project: string, groupData: Group, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.groupsio}/${project}`, groupData, { params, observe: 'response' });
  }

  public deleteProjectGroupsIOList(project: string, group: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.groupsio}/${project}/lists/${group}`, { params, observe: 'response' });
  }

  public getGithubOrgCIConfig(org: string, params?: HttpParams): Observable<CIConfig[]> {
    const orgRepos = this.http.get<ITXGithubRepository[]>(`${this.urls.ci}`, { params });
    return this.response(`project-infrastructure-ci-config-${org}`, orgRepos);
  }

  public getCIConfig(id: number, params?: HttpParams): Observable<CIConfig> {
    return this.http.get<CIConfig>(`${this.urls.ci}/${id}`, { params });
  }

  public getCIPlatforms(params?: HttpParams): Observable<CIPlatform[]> {
    const platforms = this.http.get<CIPlatform[]>(`${this.urls.ci}/platforms`, { params });
    return this.response(`project-infrastructure-ci-platforms`, platforms);
  }

  public getCILanguages(params?: HttpParams): Observable<CILanguage[]> {
    const languages = this.http.get<CILanguage[]>(`${this.urls.ci}/languages`, { params });
    return this.response(`project-infrastructure-ci-languages`, languages);
  }

  public getCIServices(params?: HttpParams): Observable<CIService[]> {
    const services = this.http.get<CIService[]>(`${this.urls.ci}/services`, { params });
    return this.response(`project-infrastructure-ci-services`, services);
  }

  public postCIConfig(data: CIConfigData, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.ci}`, data, { observe: 'response', params });
  }

  public putCIConfig(id: number, data: CIConfigData, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.ci}/${id}`, data, { observe: 'response', params });
  }

  public putCIValidate(id: number, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.ci}/${id}/validate`, undefined, { observe: 'response', params });
  }

  public putCISubmit(id: number, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.ci}/${id}/submit`, undefined, { observe: 'response', params });
  }

  public deleteCIConfig(id: number, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.ci}/${id}`, { observe: 'response', params });
  }

  public getDockerhubOrganizationRepositories(org: string, params?: HttpParams): Observable<DockerhubRepository[]> {
    const orgRepos = this.http.get<DockerhubRepository[]>(`${this.urls.dockerhub}/${org}/repos`, { params });
    return this.response(`project-infrastructure-dockerhub-repos-${org}`, orgRepos);
  }

  // GroupsIOService - V2
  // groupServices
  public getProjectGroupsIOServices(projectId: string, params?: HttpParams): Observable<GetGroupServiceResponse> {
    const groups = this.http.get<GetGroupServiceResponse>(`${this.urls.groupsioServices}`, { params });
    return this.response(`project-infrastructure-groupsio-${projectId}`, groups);
  }

  public getProjectGroupsIOParent(projectId: string, params?: HttpParams): Observable<GroupServices> {
    const groups = this.http.get<GroupServices>(`${this.urls.groupsioFindParent}?project_id=${projectId}`, { params });
    return this.response(`project-infrastructure-groupsio-getparent-${projectId}`, groups);
  }

  public postProjectGroupsIOServices(groupService: PostGroupServices, params: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.groupsioServices}`, groupService, { params, observe: 'response' });
  }

  // subgroups
  // GroupsIOService
  public getProjectSubGroups(project: string, params: HttpParams): Observable<GetSubGroupsResponse> {
    const groups = this.http.get<GetSubGroupsResponse>(`${this.urls.subGroups}`, { params });
    return this.response(`project-infrastructure-groupsio-subgroups-${project}`, groups);
  }

  public getProjectSubGroupsFull(project: string, params: HttpParams): Observable<GetSubGroupsResponse> {
    const groups = this.http.get<GetSubGroupsResponse>(`${this.urls.subGroupsFull}`, { params }).pipe(
      tap((res) => {
        if (res.errors && res.errors.length) {
          this.toasterService.error('One or more of your subgroups could not be retrieved.', res as any, {
            toastComponent: ToastComponent
          });
        }
      })
    );
    return this.response(`project-infrastructure-groupsio-subgroups-full-${project}`, groups);
  }

  // single subgroup
  public getProjectSubGroup(subgroupId: string, params?: HttpParams): Observable<Subgroup> {
    const groups = this.http.get<Subgroup>(`${this.urls.subGroup}/${subgroupId}`, { params });
    return this.response(`project-infrastructure-groupsio-subgroup-${subgroupId}`, groups);
  }

  public postProjectSubGroups(subGroup: PostSubgroup, params: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.subGroups}`, subGroup, { params, observe: 'response' });
  }

  public putProjectSubGroups(subGroupId: number, subGroup: PutSubgroup): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.subGroup}/${subGroupId}`, subGroup, { observe: 'response' });
  }

  public bulkPutProjectSubGroups(subGroup: PutSubgroup[]): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.subGroup}`, subGroup, { observe: 'response' });
  }

  public deleteProjectSubGroups(subGroupId: number, params: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.subGroup}/${subGroupId}`, { params, observe: 'response' });
  }

  // sub group members
  public getGroupsIOSubgroupMembersFull(subgroup_id: string, params?: HttpParams): Observable<GroupMembersFullResponse> {
    const groupMembers = this.http.get<GroupMembersFullResponse>(`${this.urls.subGroup}/${subgroup_id}/members_full`, { params });
    return this.response(`project-infrastructure-groupsio-member${subgroup_id}`, groupMembers);
  }

  public postGroupsIOSubgroupMember(subgroup_id: string, member: PostGroupMembers): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urls.subGroup}/${subgroup_id}/members`, member, { observe: 'response' });
  }

  public putGroupsIOSubgroupMember(subgroup_id: string, memberId: number, member: PutGroupMembers): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urls.subGroup}/${subgroup_id}/members/${memberId}`, member, { observe: 'response' });
  }

  public deleteGroupsIOSubgroupMember(subgroup_id: string, memberId: number, params: HttpParams): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urls.subGroup}/${subgroup_id}/members/${memberId}`, { params, observe: 'response' });
  }

  public getDockerhubOrganizations(project: string, params?: HttpParams): Observable<DockerhubOrganization[]> {
    const dockerhubOrgs = this.http.get<DockerhubOrganization[]>(`${this.urls.dockerhub}`, { params });
    return this.response(`project-infrastructure-dockerhub-orgs-${project}`, dockerhubOrgs);
  }

  public postDockerhubOrganization(organizationData: DockerhubOrganizationCreate, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.dockerhub}`, organizationData, { params, observe: 'response' });
  }

  public getDockerhubOrganizationCredentials(organization: string, params?: HttpParams): Observable<DockerhubOrganizationCredentials> {
    const dockerhubOrgs = this.http.get<DockerhubOrganizationCredentials>(`${this.urls.dockerhub}/${organization}/credentials`, { params });
    return this.response(`project-infrastructure-dockerhub-orgs-credentials-${organization}`, dockerhubOrgs);
  }

  public postDockerhubOrganizationCredentials(
    organization: string,
    credentialsData: DockerhubOrganizationCredentials,
    params?: HttpParams
  ): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.urls.dockerhub}/${organization}/credentials`, credentialsData, { params, observe: 'response' });
  }

  public putDockerhubOrganizationCredentials(
    organization: string,
    credentialsData: DockerhubOrganizationCredentials,
    params?: HttpParams
  ): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.urls.dockerhub}/${organization}/credentials`, credentialsData, { params, observe: 'response' });
  }

  public getZoomMeetingParticipants(id: string, params?: HttpParams): Observable<ZoomMeetingRegistrant[]> {
    const committeeMembers = this.http.get<any>(`${this.urls.zoom}/meetings/${id}/invited-registrants`, {
      params
    });

    return committeeMembers;
  }

  private handleGetITXProgressError(error: HttpErrorResponse): Observable<ITXProgressAndStatus> {
    console.error(error.error);
    return of({ progress: 0, status: 'error' });
  }

  private response(cacheKey: string, observable: Observable<any>) {
    if (this.cacheService.has(cacheKey)) {
      return this.cacheService.get(cacheKey);
    }

    const res = observable.pipe(shareReplay(1));
    this.cacheService.set(cacheKey, res);
    return res;
  }
}
