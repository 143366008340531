<div class="doughchart-container" fxLayout="row nowrap" fxLayoutGap="10px" fxLayoutAlign="start center">
  <div class="inner-circle-text">
    <h4 data-cy="participants-invited-upcoming-meetings">{{ participantsInvitedToMeetingsCount || '-' }}</h4>
    <p>PARTICIPANTS INVITED TO UPCOMING MEETINGS</p>
    <i class="far fa-info-circle" [ngxTippy]="getTooltipInfo()" [tippyProps]="tippyProps"
      tippyClassName="meeting-info-tooltip"></i>
  </div>
  <div class="legend" fxLayout="column" fxLayoutGap="5px">
    <div class="legend-item" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="legend-value">
        <i class="fas fa-circle success"></i>
        <span><strong>{{ data[2] }}</strong> Attend over 75% of meetings</span>
      </div>
      <!-- <span>Attend over 75% of meetings</span> -->
    </div>
    <div class="legend-item" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="legend-value">
        <i class="fas fa-circle warning"></i>
        <span><strong>{{ data[1] }}</strong> Attend 50% to 75% of meetings</span>
      </div>
      <!-- <span>Attend 50% to 75% of meetings</span> -->
    </div>
    <div class="legend-item" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px" class="legend-value">
        <i class="fas fa-circle red"></i>
        <span><strong>{{ data[0] }}</strong> Attend under 50% of meetings</span>
      </div>
      <!-- <span>Attend under 50% of meetings</span> -->
    </div>
  </div>
</div>
