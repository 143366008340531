import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-input-slide-toggle',
  templateUrl: './input-slide-toggle.component.html',
  styleUrls: ['./input-slide-toggle.component.scss']
})
export class InputSlideToggleComponent implements OnInit {
  @Input() public parent!: FormGroup;
  @Input() public control: string;
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public errorMessage: string = '';

  @Output() public readonly formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public inputControl: AbstractControl;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }
  }

  public setControlValue(value: MatSlideToggleChange): void {
    const currentControl = this.parent.get(this.control);
    if (currentControl) {
      this.inputControl = currentControl;
      this.inputControl.setValue(value.checked);
      this.inputControl.updateValueAndValidity();
    }
    this.formGroupChange.emit();
  }
}
