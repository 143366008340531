<div class="section-header" data-cy="section-header-component"
  [ngClass]="{ 'greyOut': !toggleOpenState && changeToggleDisabledBackgroundColor }">
  <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span class="section-title">{{ text }}</span>
    <i [class]="infoTooltip.icon" [ngxTippy]="infoTooltip.text" [tippyProps]="tippyProps" *ngIf="hasTooltip"></i>
  </div>
  <div class="right-content">
    <button *ngIf="buttonEnabled" [id]="buttonId" class="cta-button" (click)="buttonClicked()"
      [disabled]="!buttonEnabled" data-cy="section-header-component-button-button">
      <i *ngIf="ctaIcon !== ''" [class]="ctaIcon" class="cta-icon"></i>
      {{ buttonTitle }}
    </button>
    <div class="search-wrapper" *ngIf="searchEnabled">
      <i class="icon" class="fas fa-search"></i>
      <input type="text" class="section-header-search" [placeholder]="searchPlaceholder" (keyup)="onKeyUp($event)"
        [attr.disabled]="disableSearchInput ? '' : null" [formControl]="sidebarSearch"
        data-cy="section-header-component-search-text-input">
    </div>
    <div class="toggle-wrapper" *ngIf="toggleEnabled && !upDownToggleArrow">
      <i class="fas" [class.fa-angle-down]="toggleOpenState" [class.fa-angle-right]="!toggleOpenState"
        (click)="onToggleOpenState()"></i>
    </div>
    <div class="toggle-wrapper" *ngIf="toggleEnabled && upDownToggleArrow">
      <i class="fas" [class.fa-angle-up]="!toggleOpenState" [class.fa-angle-down]="toggleOpenState"
        (click)="onToggleOpenState()"></i>
    </div>
  </div>
</div>
