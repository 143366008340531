import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Project } from 'lfx-pcc';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectService } from '../services/project.service';

@Injectable()
export class ProjectOnboardedGuard implements CanActivate {
  public constructor(private toasterService: ToastrService, private projectService: ProjectService, private router: Router) {}
  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const projectID = route.params.id;
    let activeProject: boolean = false;
    return this.projectService.getProject(projectID).pipe(
      map((project: Project) => {
        if (project) {
          activeProject = project.Status?.toLowerCase() === 'active';
          if (!activeProject) {
            this.toasterService.error('Tools are not available for formation projects.', 'Error');
            this.router.navigate([`/project/${projectID}`]);
          }
          return activeProject;
        }
        return activeProject;
      })
    );
  }
}
