/* eslint-disable @angular-eslint/use-component-view-encapsulation */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-section-header-light',
  templateUrl: './section-header-light.component.html',
  styleUrls: ['./section-header-light.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionHeaderLightComponent implements OnInit {
  @Input() public ngTemplate: any;
  @Input() public title: string;
  @Input() public description: string;
  @Input() public buttonEnabled: boolean | null = false;
  @Input() public customContainer: boolean | null = false;
  @Input() public buttonTitle: string = '';
  @Input() public searchEnabled: boolean = false;
  @Input() public selectEnabled: boolean = false;
  @Input() public searchPlaceholder: string = 'Search';
  @Input() public ctaIcon: string = '';
  @Input() public disableSearchInput: boolean = false;
  @Input() public infoTopRight: string;
  @Input() public options: { label: string; value: string }[] = [
    {
      label: 'Recurring Meeting',
      value: 'Recurring Meeting'
    },
    {
      label: 'Non-Recurring Meeting',
      value: 'Non-Recurring Meeting'
    },
    {
      label: 'Past Meeting',
      value: 'Past Meeting'
    }
  ];

  @Output() public readonly clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly searched: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public form: FormGroup;
  public moment = moment;

  private subject: Subject<string> = new Subject();

  public constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      search: ''
    });
  }

  public ngOnInit(): void {
    this.form.controls.search.valueChanges.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      this.searched.emit(searchTextValue);
    });
  }

  public buttonClicked(): void {
    this.clicked.emit(true);
  }
}
