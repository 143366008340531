<div class="select-input-container" [class.full-width]="fullWidth" [formGroup]="parent" *ngIf="!useMaterial">
  <label for="{{ control }}" [class.error]="inputHasErrors() || errorMessage">{{ label }} <span *ngIf="required"
      class="required">*</span><span class="optional">{{ labelSubtext }}</span></label>
  <select id="{{ control }}" [formControlName]="control" [tabIndex]="tabIndex" [attr.disabled]="disabled ? '' : null"
    [attr.data-cy]="cyLabel">
    <option value="" disabled selected hidden>Select</option>
    <option *ngFor="let option of options" [value]="option.value" [attr.data-cy]="cyLabel + '-option'">{{ option.label
      }}</option>
  </select>
</div>

<div class="select-label-container" [class.full-width]="fullWidth" [class.hide-icon]="!showValidationIcon"
  [formGroup]="parent" *ngIf="useMaterial">
  <mat-label for="{{ control }}" [class.error]="(inputHasErrors() || errorMessage) && showValidationIcon">{{ label }}
    <span *ngIf="required" class="required">*</span><span class="optional">{{ labelSubtext }}</span><i
      *ngIf="infoIconText !== ''" class="info {{ infoIconClass }}" [ngxTippy]="infoIconText" [tippyProps]="tippyProps"
      tippyClassName="lfx-tooltip"></i></mat-label>
  <mat-select #matSelectRef id="{{ control }}" [formControlName]="control" [tabIndex]="tabIndex"
    matTooltip="{{ getMatSelectToolTipText(control) }}" [attr.disabled]="disabled ? '' : null"
    [disabled]="disabled || loading" disableOptionCentering panelClass="single-panel"
    (selectionChange)="setSelectionValue($event)" [placeholder]="placeholder"
    [ngClass]="{ error: (!loading && inputHasErrors()) || (!loading && errorMessage && showValidationIcon), valid: validMessage }"
    *ngIf="!componentLoading" [attr.data-cy]="cyLabel">
    <span *ngIf="groupOption">
      <mat-optgroup *ngFor="let option of groupOptions" [label]="option.title">
        <mat-option *ngFor="let item of option.list" [value]="item.value" [attr.data-cy]="cyLabel + '-option'">
          {{ item.label }}
        </mat-option>
      </mat-optgroup>
    </span>
    <span *ngIf="!groupOption">
      <mat-option *ngIf="hideDefaultOpt" value="" [disabled]="disableDefaultOpt" disabled selected
        [hidden]="hideDefaultOpt">
        {{ placeholder }}
      </mat-option>
      <mat-option *ngIf="showNoneOption" [attr.data-cy]="cyLabel + '-option'"> None </mat-option>
      <mat-divider *ngIf="showNoneOption"></mat-divider>
      <mat-option *ngFor="let option of options" [value]="option.value" [attr.data-cy]="cyLabel + '-option'"
        matTooltip="{{ getMatOptionToolTipText(option.label) }}">
        <i [class]="option.icon" [title]="option.iconTitle || ''" *ngIf="option.icon"></i>
        <img *ngIf="option.img" class="image-icon" [title]="option.imgTitle || ''"
          [src]="'../../../../assets/icons/' + option.img" />
        <span [innerHTML]="option.label"></span>
      </mat-option>
    </span>
  </mat-select>
  <ngx-skeleton-loader *ngIf="componentLoading" animation="progress" [theme]="skeletonTheme"></ngx-skeleton-loader>
  <i class="error fa fa-exclamation-circle" aria-hidden="true"
    *ngIf="(errorMessage || inputHasErrors()) && showValidationIcon"></i>
  <i class="valid-icon fa fa-check-circle" aria-hidden="true" *ngIf="!loading && validMessage"></i>
  <div *ngIf="loading"><i class="fas fa-circle-notch fa-spin"></i></div>
</div>
<div class="select-label-container" [class.full-width]="fullWidth" *ngIf="useMaterial">
  <mat-error *ngIf="(inputHasErrors() || errorMessage) && showValidationIcon">
    {{ errorMessage || getErrorMessage(inputControl) }}
  </mat-error>

  <mat-success *ngIf="!inputHasErrors() && validMessage">
    {{ validMessage }}
  </mat-success>
</div>
