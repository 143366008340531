<div class="alert-message" *ngIf="!hide" [class]="[status, theme]" fxLayout="row" fxLayoutAlign="space-between center"
  fxLayoutGap="40px" [ngStyle]="customStyles">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <i class="status-icon" [class]="getIconClass" [ngStyle]="{ color: iconColor }"></i>
    <div *ngIf="htmlText" [innerHTML]="htmlText"></div>
    <div *ngIf="!htmlText">
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="showClose">
    <i class="fas fa-times" (click)="hide = true;"></i>
  </div>
</div>
