<div class="input-item" [formGroup]="parent">
  <label for="{{control}}" [class.error]="inputHasErrors() || errorMessage">{{ label }} <span *ngIf="required"
      class="required">*</span></label>
  <div fxLayout="row">
    <input type="text" id="{{control}}" [required]="required" (keyup)="onInputCheck($event)"
      (blur)="onInputCheck($event)" [class.error]="inputHasErrors()" placeholder="{{placeholder}}"
      [formControlName]="control" autocomplete="off" data-lpignore="true">
    <div [style.background]="color" class="color-picker" [(colorPicker)]="color"
      (colorPickerChange)="changeColor($event)"></div>
  </div>
  <span class="error-msg-span" *ngIf="inputHasErrors()">{{ getErrorMessage(inputControl) }}</span>
  <span *ngIf="errorMessage">{{ errorMessage }}</span>
</div>
