<div class="input-wrapper">
  <div class="input-item" [class.full-width]="fullWidth" [formGroup]="parent">
    <div class="label-container" fxLayout="row nowrap" fxLayoutAlign="start start" fxLayoutGap="10px">
      <label for="{{control}}">{{ label }} <span *ngIf="required" class="required">*</span>
        <span *ngIf="labelSubText" class="optional">{{ labelSubText }}</span>
      </label>
      <i [ngClass]="tooltipIcon" [ngxTippy]="tooltip" [tippyProps]="tippyProps" *ngIf="tooltip"></i>
    </div>

    <span class="input-container">
      <textarea *ngIf="type === 'textarea'" [rows]="rows" id="{{control}}" [required]="required"
        [class.error]="inputHasErrors()" placeholder="{{placeholder}}" [formControlName]="control"
        [readOnly]="readonly"></textarea>
      <input #input [tabIndex]="tabIndex" *ngIf="type === 'input' || type === 'password'"
        [type]="type === 'input' ? 'text' : 'password'" id="{{control}}" [required]="required"
        (input)="inputValueChange()" [autofocus]="autofocus"
        [ngClass]="{'error': (!loading && inputHasErrors()) || (!loading && errorMessage), 'valid': validMessage }"
        placeholder="{{placeholder}}" [formControlName]="control" [readOnly]="readonly" (focus)="onFocus($event)"
        (click)="onClick($event)" [matAutocomplete]="autocompleteRef" (focusout)="onFocusout($event)"
        [matAutocompleteDisabled]="!autocompleteRef || autocompleteDisabled" data-cy="input-component-input-text">
      <i class="far {{icon}} icon" *ngIf="icon"></i>
      <div *ngIf="showSearchIcon">
        <i class="spin-icon" [class]="loading ? 'fas fa-circle-notch fa-spin' : 'fas fa-search'"
          [ngClass]="{'search-with-error':showValidationIcon && (!loading && !!errorMessage) || (!loading && inputHasErrors())}"></i>
        <div class="icon-space-saver">
          <i class="fa fa-exclamation-circle" aria-hidden="true"
            *ngIf="(!loading && errorMessage) || (!loading && inputHasErrors())"></i>
          <i class="fa fa-check-circle" aria-hidden="true" *ngIf="!loading && validMessage"></i>
        </div>
      </div>

      <div class="icon-space-saver" *ngIf="showValidationIcon && !showSearchIcon">
        <i class="fa fa-exclamation-circle" aria-hidden="true"
          *ngIf="(!loading && errorMessage) || (!loading && inputHasErrors())"></i>
        <i class="fa fa-check-circle" aria-hidden="true" *ngIf="!loading && validMessage"></i>
      </div>
    </span>
    <span [ngClass]="(validMessage)?'valid text-message':'text-message'"
      *ngIf="errorMessage || inputHasErrors() || validMessage">
      {{ validMessage ? validMessage : errorMessage || getErrorMessage(inputControl) }}</span>
  </div>
  <div class="input-side">
    <a *ngIf="showClearButton()" class="clear" (click)="clear()">Clear</a>
  </div>
</div>
